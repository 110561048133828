import React from "react";
import CommonFunctions from "../commonFunctions.js";
import { Form } from "react-bootstrap";
import {NotificationManager} from 'react-notifications';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
  } from 'chart.js';
  import { Bar, Pie, Line } from 'react-chartjs-2';
//   import faker from 'faker';
  ChartJS.register(
    ArcElement,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  
class Dashboard extends CommonFunctions {
  constructor(props) {
    super(props)
    this.getData  = this.getData.bind(this);    
    this.state = {
      response_data:[],
      axios_responded:0,
      breadcrum_params: [],
      duration_filter:'current_week',
      backgroundColors:[],
      years:[],
      year_val:new Date().getFullYear(),
      date_range:[],
      sdate:'',
      edate:'',
      is_date_wise:'',
      data:'',
      users:[],
      user_id:'',

  };
  this.errors = [];
  this.getRandomRgb = this.getRandomRgb.bind(this)
  this.getDashboardData = this.getDashboardData.bind(this)

}

 componentDidMount() {
    var newBreadcrum = this.state.breadcrum_params.slice();
    // newBreadcrum.push([1,'../brands', 'Brands']);
    newBreadcrum.push([0, 0, 'Sales']);
    this.setState({breadcrum_params: newBreadcrum});  

    let url = "user/get";
    
    this.apiCall(url, '', '', 'GET','').then(res => {
      // console.log(res.data.data)
      this.setState({users: res.data.data}); 
    })

    this.getDashboardData('', this.state.duration_filter, this.state.year_val)
  }

  getDashboardData(user_id, val, year_val){
    if(val != "month_wise")
    {
    year_val = new Date().getFullYear();
    }
    var sdate = this.state.sdate
    var edate = this.state.edate
    if(val == "date_wise")
    {
      this.setState({is_date_wise: 1}); 
      this.setState({years: []}); 
    }
    else
    {
      this.setState({is_date_wise: ''}); 
      this.setState({sdate: '', edate:''}); 
    }
    if(val == "date_wise" && !sdate && !edate)
    {
    return 0;
    }
    if(sdate>edate)
    {
      NotificationManager.error("Start date can not be greater than end date", "", 1500);
      return 0;
    }

    var condition = "duration="+ val+"&year_val="+year_val+"&sdate="+sdate+"&edate="+edate+"&user_id="+user_id;
    let url = "report/dashboard?"+condition;
    this.getRandomRgb()
    this.apiCall(url, '', '', 'GET','').then(res => {
        console.log( res.data.total_items )
        this.setState({
          response_data: res.data,
          date_range:res.data.date_range, 
          years: res.data.years
        });

          if(val != "month_wise")
          {
            this.setState({years: []}); 
          }
      })
      
  }
  getRandomRgb() {
      for (var i = 0; i<=15; i++)
      {
    var num = Math.round(0xffffff * Math.random());
    var r = num >> 16;
    var g = num >> 8 & 255;
    var b = num & 255;
    this.state.backgroundColors.push('rgb(' + r + ', ' + g + ', ' + b + ')')
      }
  }

  render() {
    var total_items = this.state.response_data.total_items
    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: '',
          },
        },
      };
      
      const labels = this.state.date_range;
      
      const data = {
        labels,
        datasets: [
          {
            label: 'Total Sold Items',
            data: this.state.response_data.total_items,
            // backgroundColor: 'rgba(255, 99, 132, 0.5)',
            backgroundColor: 'rgba(254, 147, 101, 1)',
          },
          {
            label: 'Revenue',
            data: this.state.response_data.total_amounts,
            // backgroundColor: 'rgba(53, 162, 235, 0.5)',
            backgroundColor: 'rgba(10, 194, 130, 1)',
          },
        ],
      };

      const line_data = {
        labels,
        datasets: [
          {
            label: "Total profits",
            data: this.state.response_data.profit_items,
            fill: true,
            backgroundColor: 'rgba(254, 93, 112, 1)',
            borderColor:'rgba(254, 93, 112, 1)'
          }
        ]
      };

const pie_data = {
  title:"=============",
    labels: this.state.date_range,
    datasets: [
      {
        label: 'Total Bills',
        data: this.state.response_data.total_bills,
        backgroundColor:  this.state.backgroundColors,
        borderWidth: 1,
      },
    ],
  };
    
    return (
        <div>
        <div className="row">
        <div className="col-md-2"> 
        <Form.Label>Billing Users</Form.Label>
        <select className = "form-select" name='user_id' onChange={e => { this.validateInput(e); this.getDashboardData(e.target.value, this.state.duration_filter, this.state.year_val) }}>
            <option value=''>All Users</option>
            {
              this.state.users.map((row, i) => (
                <option key={i} value={row.id}>{row.first_name + ' ' + row.last_name + '-'+ row.name}</option>
                ))
            }

        </select>
            </div>

            <div className="col-md-2">
            <Form.Label>Durations</Form.Label> 
        <select className = "form-select" name='duration_filter' onChange={e => { this.validateInput(e); this.getDashboardData(this.state.user_id, e.target.value, this.state.year_val) }}>
            <option value='current_week'>Current Week</option>
            <option value='last_week'>Last Week</option>
            <option value='month_wise'>Month Wise</option>
            <option value='year_wise'>Year Wise</option>
            <option value='date_wise'>Date Wise</option>
        </select>
            </div>
            {this.state.years.length>0 && 
            <div className="col-md-2">    
            <Form.Label>Year</Form.Label>     
            <select className="form-select"  name="year_val" value= {this.state.year_val} 
          
          onChange={e => { this.validateInput(e); this.getDashboardData(this.state.user_id, this.state.duration_filter, e.target.value) }}>
            {
              this.state.years.map((v, i) => (
                <option key={i} value={v}>{v}</option>
                ))
            }
          </select>          
            </div>}

     {this.state.is_date_wise && 
      <div className="col-md-2"> 
          <Form.Label>Start Date</Form.Label>
          <input type='date' className="form-control" value= {this.state.sdate}  name='sdate' onChange={e => { this.validateInput(e);}} />
      </div>
    }

    {this.state.is_date_wise && 
      <div className="col-md-2"> 
          <Form.Label>End Date</Form.Label>
          <input type='date' className="form-control" value= {this.state.edate} name='edate' onChange={e => { this.validateInput(e);}} />
      </div>
    }
    {this.state.is_date_wise && this.state.sdate && this.state.edate && 
      <div className="col-md-2"> 
          <div> &nbsp;</div>
          <input type='button' className="btn-icon" value="Submit" name='submit' onClick={e => { this.getDashboardData(this.state.user_id, this.state.duration_filter, 0);}} />
      </div>
    }
        </div>

{this.state.response_data.total_items_sum > 0 && 
    <div className="row mt-3">
    <div className="col-xl-3 col-md-6">
        <div className="card bg-primary text-white mb-4">
            <div className="card-body">Total Products Sold
            <i style={{color:'#fff', fontSize:'22px'}} className="float-right fa fa-cart-arrow-down"></i>
            </div>
            <div className="card-footer d-flex align-items-center justify-content-between text-center">
              {this.CurrencyFormatted(Math.round(this.state.response_data.total_items_sum))}
            </div>
        </div>
    </div>
    <div className="col-xl-3 col-md-6">
        <div className="card bg-warning text-white mb-4">
            <div className="card-body">Total Bills Created
            <i style={{color:'#fff', fontSize:'22px'}} className="float-right fa fa-quote-right"></i>
            </div>
            <div className="card-footer d-flex align-items-center justify-content-between">
              {Math.round(this.state.response_data.total_bills_sum)}
            </div>
        </div>
    </div>
    <div className="col-xl-3 col-md-6">
        <div className="card bg-success text-white mb-4">
            <div className="card-body">Total Sales Amount
            <i style={{color:'#fff', fontSize:'22px'}} className="float-right fa fa-money-bill"></i>
            </div>
            <div className="card-footer d-flex align-items-center justify-content-between">
            {this.CurrencyFormatted(Math.round(this.state.response_data.total_amounts_sum))}
            </div>
        </div>
    </div>
    <div className="col-xl-3 col-md-6">
        <div className="card bg-danger text-white mb-4">
            <div className="card-body">Total Profit
            <i style={{color:'#fff', fontSize:'22px'}} className="float-right fa fa-arrow-up"></i>
            </div>
            <div className="card-footer d-flex align-items-center justify-content-between">
            {this.CurrencyFormatted(Math.round(this.state.response_data.profit_items_sum))}
            </div>
        </div>
    </div>

    <div className="col-xl-3 col-md-6">
        <div className="card bg-primary2 text-white mb-4">
            <div className="card-body">New Customers
            <i style={{color:'#fff', fontSize:'22px'}} className="float-right fa fa-users"></i>
            </div>
            <div className="card-footer d-flex align-items-center justify-content-between text-center">
              {this.CurrencyFormatted(Math.round(this.state.response_data.total_new_customers))}
            </div>
        </div>
    </div>

    <div className="col-xl-3 col-md-6">
        <div className="card bg-success2 text-white mb-4">
            <div className="card-body">Total Payment Received
            <i style={{color:'#fff', fontSize:'22px'}} className="float-right fa fa-money-bill"></i>
            </div>
            <div className="card-footer d-flex align-items-center justify-content-between text-center">
              {this.CurrencyFormatted(Math.round(this.state.response_data.total_paid_amount))}
            </div>
        </div>
    </div>

    <div className="col-xl-3 col-md-6">
        <div className="card bg-success2 text-white mb-4">
            <div className="card-body">Total Outbalance
            <i style={{color:'#fff', fontSize:'22px'}} className="float-right fa fa-money-bill"></i>
            </div>
            <div className="card-footer d-flex align-items-center justify-content-between text-center">
              {this.CurrencyFormatted(Math.round(this.state.response_data.total_outbalance))}
            </div>
        </div>
    </div>

</div> 
  }

{this.state.response_data.total_items_sum > 0 && <div>
      <div className="row mb-3">
            <div className="col-md-8"> 
            <p className="text-center">Total Sold Items Vs Revenue</p>
            <Bar options={options} data={data} />
            </div>
            <div className="col-md-4"> 
            <p className="text-center">Total Bills Created</p>
            <Pie data={pie_data} />
            </div>
      </div>

      <div className="row">
      <div className="col-md-8"> 
            <div className="text-center">Profit Trends</div>
            <Line data={line_data} options={options} />
      </div>
      </div>
      </div>
  }

{this.state.response_data.total_items_sum == 0 && <div><div className="mt-4 text-center p-2 alert-warning">No Records Found !</div></div>}

      </div>
  );
}
}

export default Dashboard;