import React,{Component} from "react";
import { Link } from "react-router-dom";
import CommonFunctions from "./commonFunctions.js";

export default class Tablehead extends CommonFunctions{
constructor(props) {
    super(props)
    this.state = {
        sdate: '',
        edate: '',
        search_txt:'',
      };
      this.errors = [];
    }

    render() {
        return (
    <div className="row">
        <div className="col-3 block_for_mobile">
          <h3>{this.props.header1}</h3>
        </div>

        <div className="col-8 text-right block_for_mobile">
            <div className="input-group">
                <input autoComplete='off' type="text" id="search_txt" name="search_txt" className="search_box form-control" 
                onChange={e => { this.validateInput(e); this.handlePageSearch2(this.props.model_url, e)}}
                placeholder='Type anything to search here...' />
                <button type="button" className="active menu5 btn-icon search_btn" onClick={this.handlePageSearch.bind(this, this.props.model_url)} ><i className="fas fa-search"></i ></button>
            </div>
        </div>
        
        {
        this.props.link && <div className="col-1">
            <Link to={this.props.link} className="float-right breadcrum-link">
            <button type="button" className="btn-icon menu5"> <i className="fas fa-plus"></i ></button>
            {/* <span><i className="fas fa-plus"></i > New</span> */}
            </Link>
        </div>
        }
    </div>
  
    );
  }
}