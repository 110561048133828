import React from "react";
import CommonFunctions from "../commonFunctions.js";
import {
  Form,
  Select,
  Button,
  FormGroup,
  Col,
  Row,
  InputGroup,
  FormControl,
  ControlLabel,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Breadcrumbs from "../Breadcrumbs";
import CommonAttributes from "../CommonAttributes";
import ReactAutocomplete from "react-autocomplete";

class Product extends CommonFunctions {
  constructor(props) {
    super(props);
    this.state = {
      validated: 0,
      breadcrum_params: [],
      brands: [],
      sub_category: [],
      main_category1: [],
      main_category2: [],
      main_category_name1: "",
      main_category_name2: "",
      sub_category_name: "",
      name: "",
      bname: "",
      discounted_price: "0",
      price: "0",
      quantity: 0,
      min_quatity_alert: 25,
      hsn_code: "",
      master_id: 0,
    };
    this.errors = [];
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    this.setState({ validated: this.state.validated + 1 });

    if (form.checkValidity() === true) {
      const url = "product/" + this.props.match.params.slug;
      const params = {
        name: this.state.name,
        master_id: this.state.id,
        brand_name: this.state.bname,
        category_name: this.state.sub_category_name,
        main_category_name1: this.state.main_category_name1,
        main_category_name2: this.state.main_category_name2,
        price: this.state.price,
        discounted_price: this.state.discounted_price,
        quantity: this.state.quantity,
        hsn_code: this.state.hsn_code,
        min_quatity_alert: this.state.min_quatity_alert,
      };

      this.apiCall(url, params, "../products", "PUT", "Product updated").then(
        (res) => {
          // console.log(res);
          if (res.status != "error") {
            this.saveChildData(
              this.state.master_id,
              this.state.name,
              "products",
              "../products",
              "Product updated"
            );
          } //end if
        }
      );
    }
  };

  componentDidMount() {
    var newBreadcrum = this.state.breadcrum_params.slice();
    newBreadcrum.push([1, "../products", "Product"]);
    newBreadcrum.push([0, 0, "Add Product"]);
    this.setState({ breadcrum_params: newBreadcrum });

    let url = "product/" + this.props.match.params.slug;
    let params = {};
    var category_id = "0";
    this.apiCall(url, params, "", "GET", "").then((res) => {
      category_id = res.data[0].category;

      this.apiCall(
        "brands?create_for_dropdown_list=yes",
        "",
        "",
        "GET",
        ""
      ).then((res) => {
        this.setState({
          brands: res.data.brands,
          main_category1: res.data.mcategories1,
        });
      });
      this.setState({
        name: res.data[0].name,
        master_id: res.data[0].id,
        bname: res.data[0].brand,
        sub_category_name: res.data[0].category,
        main_category_name1: res.data[0].main_category_name1,
        main_category_name2: res.data[0].main_category_name2,
        price: res.data[0].price,
        discounted_price: res.data[0].discounted_price,
        quantity: res.data[0].quantity,
        hsn_code: res.data[0].hsn_code,
        min_quatity_alert: res.data[0].min_quatity_alert,
      });
    });
  }

  handleCategoryChange(type, value) {
    //e.target.value
    if (!value) {
      return;
    }
    var val = encodeURIComponent(value);
    if (type == "get_main_category_level2") {
      this.apiCall(
        "main_categories?create_for_dropdown_list=yes&level=2&parent_name=" +
          val,
        "",
        "",
        "GET",
        ""
      ).then((res) => {
        this.setState({ main_category2: res.data });
      });
    }
    if (type == "get_sub_category") {
      this.apiCall(
        "category?create_for_dropdown_list=yes&level=3&parent_name=" + val,
        "",
        "",
        "GET",
        ""
      ).then((res) => {
        this.setState({ sub_category: res.data });
      });

      this.apiCall(
        "brands?create_for_dropdown_list=yes&category_name=" + val,
        "",
        "",
        "GET",
        ""
      ).then((res) => {
        this.setState({ brands: res.data.brands, bname: "" });
      });
    }
    // if( type == "get_brands")
    // {
    //   this.apiCall('brands?create_for_dropdown_list=yes&category_id='+val, '', '', 'GET','').then(res => {
    //       this.setState({brands: res.data.brands, bname:''});
    //     })
    // }
  }

  render() {
    const { breadcrum_params } = this.state;
    const { brands } = this.state;
    const { category } = this.state;

    return (
      <div>
        <Breadcrumbs params={breadcrum_params} />

        <Form
          noValidate
          validated={this.state.validated}
          onSubmit={this.handleSubmit}
        >
          <h3>Add Product</h3>
          <Row className="mb-3">
            <Form.Group as={Col} md="3" controlId="id01">
              <Form.Label>
                Main Category L1<span className="text-danger"> *</span>
              </Form.Label>
              <ReactAutocomplete
                items={this.state.main_category1}
                shouldItemRender={(item, value) =>
                  item.name.indexOf(value) > -1
                }
                getItemValue={(item) => item.name}
                renderItem={(item, highlighted) => (
                  <div
                    key={item.id}
                    style={{
                      backgroundColor: highlighted ? "#deecea" : "#fff",
                      cursor: "pointer",
                      padding: "8px 10px",
                      borderBottom: "1px solid #eee",
                    }}
                  >
                    {item.name}
                  </div>
                )}
                name="main_category_name1"
                value={this.state.main_category_name1}
                onChange={(e) =>
                  this.setState({ main_category_name1: e.target.value })
                }
                onSelect={(value) => {
                  this.setState({ main_category_name1: value });
                  this.handleCategoryChange("get_main_category_level2", value);
                }}
                wrapperStyle={{ width: "100%" }}
              />
              <Form.Control.Feedback type="invalid">
                {this.errors.main_category_name1
                  ? this.errors.main_category_name1
                  : "Please select main category L1"}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="3" controlId="id02">
              <Form.Label>
                Main Category L2<span className="text-danger"> *</span>
              </Form.Label>

              <ReactAutocomplete
                items={this.state.main_category2}
                shouldItemRender={(item, value) =>
                  item.name.indexOf(value) > -1
                }
                getItemValue={(item) => item.name}
                renderItem={(item, highlighted) => (
                  <div
                    key={item.id}
                    style={{
                      backgroundColor: highlighted ? "#deecea" : "#fff",
                      cursor: "pointer",
                      padding: "8px 10px",
                      borderBottom: "1px solid #eee",
                    }}
                  >
                    {item.name}
                  </div>
                )}
                name="main_category_name2"
                value={this.state.main_category_name2}
                onChange={(e) =>
                  this.setState({ main_category_name2: e.target.value })
                }
                onSelect={(value) => {
                  this.setState({ main_category_name2: value });
                  this.handleCategoryChange("get_sub_category", value);
                }}
                wrapperStyle={{ width: "100%" }}
              />

              <Form.Control.Feedback type="invalid">
                {this.errors.main_category_name2
                  ? this.errors.main_category_name2
                  : "Please select main category L2"}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="3" controlId="id03">
              <Form.Label>
                Category Name<span className="text-danger"> *</span>
              </Form.Label>
              <ReactAutocomplete
                items={this.state.sub_category}
                shouldItemRender={(item, value) =>
                  item.name.indexOf(value) > -1
                }
                getItemValue={(item) => item.name}
                renderItem={(item, highlighted) => (
                  <div
                    key={item.id}
                    style={{
                      backgroundColor: highlighted ? "#deecea" : "#fff",
                      cursor: "pointer",
                      padding: "8px 10px",
                      borderBottom: "1px solid #eee",
                    }}
                  >
                    {item.name}
                  </div>
                )}
                name="sub_category_name"
                value={this.state.sub_category_name}
                onChange={(e) =>
                  this.setState({ sub_category_name: e.target.value })
                }
                onSelect={(value) => {
                  this.setState({ sub_category_name: value });
                }}
                wrapperStyle={{ width: "100%" }}
              />

              <Form.Control.Feedback type="invalid">
                {this.errors.sub_category_name
                  ? this.errors.sub_category_name
                  : "Please select sub category"}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="3" controlId="id04">
              <Form.Label>
                Brand Name<span className="text-danger"> *</span>
              </Form.Label>
              <ReactAutocomplete
                items={this.state.brands}
                shouldItemRender={(item, value) =>
                  item.name.indexOf(value) > -1
                }
                getItemValue={(item) => item.name}
                renderItem={(item, highlighted) => (
                  <div
                    key={item.id}
                    style={{
                      backgroundColor: highlighted ? "#deecea" : "#fff",
                      cursor: "pointer",
                      padding: "8px 10px",
                      borderBottom: "1px solid #eee",
                    }}
                  >
                    {item.name}
                  </div>
                )}
                name="bname"
                value={this.state.bname}
                onChange={(e) => this.setState({ bname: e.target.value })}
                onSelect={(value) => {
                  this.setState({ bname: value });
                }}
                wrapperStyle={{ width: "100%" }}
              />
              <Form.Control.Feedback type="invalid">
                {this.errors.bname
                  ? this.errors.bname
                  : "Please select brand name"}
              </Form.Control.Feedback>
            </Form.Group>

            {/* <Form.Group as={Col} md="6" controlId="id04">
          <Form.Label>Price<span className="text-danger"> *</span></Form.Label>
          <Form.Control 
            type="number" 
            name="price"
            placeholder="Enter price" 
            required
            value={this.state.price} 
            onChange={this.validateInput}
            isInvalid={this.errors.price}
            autoComplete='off'
            />
          <Form.Control.Feedback type="invalid">
            {this.errors.price ? this.errors.price : 'Please provide price'}
          </Form.Control.Feedback>
        </Form.Group> */}
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} md="3" controlId="id05">
              <Form.Label>
                Product Name<span className="text-danger"> *</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="name"
                // placeholder="Enter product name"
                required
                value={this.state.name}
                onChange={this.validateInput}
                isInvalid={this.errors.name}
                autoFocus
                autoComplete="off"
              />
              <Form.Control.Feedback type="invalid">
                {this.errors.name
                  ? this.errors.name
                  : "Please provide product name"}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="3" controlId="id06">
              <Form.Label>
                HSN Code<span className="text-danger"> *</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="hsn_code"
                // placeholder="Enter HSN Code"
                required
                value={this.state.hsn_code}
                onChange={this.validateInput}
                isInvalid={this.errors.hsn_code}
                autoComplete="off"
              />
              <Form.Control.Feedback type="invalid">
                {this.errors.hsn_code
                  ? this.errors.hsn_code
                  : "Please provide HSN Code"}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="3" controlId="id07">
              <Form.Label>
                Min.Quantity Alert<span className="text-danger"> *</span>
              </Form.Label>
              <Form.Control
                type="number"
                name="min_quatity_alert"
                placeholder="Min.Quantity"
                required
                value={this.state.min_quatity_alert}
                onChange={this.validateInput}
                isInvalid={this.errors.min_quatity_alert}
                autoComplete="off"
              />
              <Form.Control.Feedback type="invalid">
                {this.errors.min_quatity_alert
                  ? this.errors.min_quatity_alert
                  : "Please provide min quantity alert"}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          {this.state.master_id > 0 && (
            <Row className="mb-3">
              <CommonAttributes
                master_id={this.state.master_id}
                master_name="products"
                setClick={(click) => (this.saveChildData = click)}
                history={this.props.history}
              />
            </Row>
          )}
          <Button type="submit">Save</Button> &nbsp;
          <Button variant="secondary" onClick={this.goBack}>
            Cancel
          </Button>
        </Form>
      </div>
    );
  }
}

export default Product;
