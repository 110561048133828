import React from "react";
import { Form, Select, Button, FormGroup,Col,Row,InputGroup, FormControl, ControlLabel } from "react-bootstrap";
import CommonFunctions from "../commonFunctions.js";

class Print extends CommonFunctions {
  constructor(props) {
    super(props)
    this.state = {
      records: [],
      total_cash_in_hand:'',
      confirm_box:false,
      currency:'',
  };
  this.updateCheckBox = this.updateCheckBox.bind(this)
  this.logout  = this.logout.bind(this);    
  }
  updateCheckBox(e){
    this.setState({confirm_box: e.target.checked});
 }

 
 logout(){
  let url = "sales/close?store_notification=1";
  this.apiCall(url, '', '', 'GET','').then(res => {
  localStorage.removeItem("cid");
    window.location.href = "../login"; //window.location.href    
  })

}

 componentDidMount() {

    let url = "sales/close";
    let params = {};
    this.apiCall(url, '', '', 'GET','').then(res => {
        this.setState({records: res.data.records, currency:res.data.currency,total_cash_in_hand:res.data.total_cash_in_hand }); 
        if(!res.data.records.length)
          this.logout()
      })
  }

  render() {
    const { records } = this.state;
    
    return (
      <div>
        <div>
        <h3>Today's Transactions completed by me</h3>
        {
        (records.length>0) &&
        <div><table className='table styled-table w-50'>
        <thead>
        <tr>
       
        <th>Transaction Type</th><th>Payment Mode</th><th className="text-end">{this.state.currency} AMOUNT</th></tr>
        </thead>
        <tbody>

        {records.map((row,i) => (
        <tr key={i}>
          <td>{row.name}</td>
          <td>{row.pay_mode}</td>
          <td className="text-end">{row.trans_amount}</td>
        </tr>
         ))}

        </tbody>
      </table>
        <p>
            <input type='checkbox' name="confirm_box" className="form-check-input"
            required
            value={this.state.confirm_box} 
            checked={this.state.confirm_box} 
            onClick={this.updateCheckBox} /> &nbsp;
          I have handovered&nbsp;  
          {
          this.state.total_cash_in_hand!=0 && 
          <span> the Total Cash of <b>{this.state.currency}{this.state.total_cash_in_hand}</b> and </span>
          }
           all the payment items to Management. </p>

          <p>
          {
          this.state.confirm_box && 
          <Button type="submit" variant="primary" onClick={this.logout } className="btn-icon  btn"> <i class="fas fa-sign-out-alt"></i> Sign Out</Button>
          }
          </p>
      </div>
      }
      {
        (records.length<=0) &&
        <div  className="text-center text-danger"><br /><br />No Records Available for Today</div>
      }
      </div>
      </div>
  );
}
}

export default Print;