import React from "react";
import CommonFunctions from "../commonFunctions.js";
import {
  Form,
  Button,
  FormGroup,
  Col,
  Row,
  InputGroup,
  FormControl,
  ControlLabel,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Breadcrumbs from "../Breadcrumbs";
import CommonAttributes from "../CommonAttributes";

class Brand extends CommonFunctions {
  constructor(props) {
    super(props);
    this.state = {
      validated: 0,
      breadcrum_params: [],
      category: [],
      cname: "0",
      name: "",
      master_id: 0,
    };
    this.errors = [];
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    this.setState({ validated: this.state.validated + 1 });

    if (form.checkValidity() === true) {
      const url = "brands";
      const params = { name: this.state.name, category_id: this.state.cname };

      this.apiCall(url, params, "../brands", "POST", "Brand Name added").then(
        (res) => {
          // console.log(res);
          if (res.status != "error") {
            this.saveChildData(
              res.data.id,
              res.data.name,
              "brands",
              "../brands",
              "brand added"
            );
          } //end if
        }
      );
    }
  };

  componentDidMount() {
    var newBreadcrum = this.state.breadcrum_params.slice();
    newBreadcrum.push([1, "../brands", "Brands"]);
    newBreadcrum.push([0, 0, "Add Brand"]);
    this.setState({ breadcrum_params: newBreadcrum });

    this.apiCall(
      "main_categories?create_for_dropdown_list=yes&level=2",
      "",
      "",
      "GET",
      ""
    ).then((res) => {
      this.setState({ category: res.data });
    });
  }

  render() {
    const { breadcrum_params } = this.state;
    const { category } = this.state;
    return (
      <div>
        <Breadcrumbs params={breadcrum_params} />

        <Form
          noValidate
          validated={this.state.validated}
          onSubmit={this.handleSubmit}
        >
          <h3>Add Brand</h3>
          <Row className="mb-3">
            <Form.Group as={Col} md="6" controlId="id01">
              <Form.Label>
                Brand Name<span className="text-danger"> *</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="name"
                placeholder="Enter brand name"
                required
                value={this.state.name}
                onChange={this.validateInput}
                isInvalid={this.errors.name}
                autoFocus
                autoComplete="off"
              />
              <Form.Control.Feedback type="invalid">
                {this.errors.name
                  ? this.errors.name
                  : "Please provide brand name"}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} md="6" controlId="id02">
              <Form.Label>Main Category Level2</Form.Label>
              <select
                className="form-select"
                value={this.state.cname}
                name="cname"
                onChange={(e) => {
                  this.validateInput(e);
                }}
              >
                <option value="">Select Category Name</option>
                {category.length > 0 &&
                  category.map((row, i) => (
                    <option key={i} value={row.id}>
                      {row.name}
                    </option>
                  ))}
              </select>
              <Form.Control.Feedback type="invalid">
                {this.errors.cname
                  ? this.errors.cname
                  : "Please select main category name"}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <CommonAttributes
              master_id={this.state.master_id}
              master_name="brands"
              setClick={(click) => (this.saveChildData = click)}
              history={this.props.history}
            />
          </Row>
          <Button type="submit">Save</Button> &nbsp;
          <Button variant="secondary" onClick={this.goBack}>
            Cancel
          </Button>
        </Form>
      </div>
    );
  }
}

export default Brand;
