import React from "react";
import CommonFunctions from "../commonFunctions.js";
import {
  Form,
  Select,
  Button,
  FormGroup,
  Col,
  Row,
  InputGroup,
  FormControl,
  ControlLabel,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Breadcrumbs from "../Breadcrumbs";

class Category extends CommonFunctions {
  constructor(props) {
    super(props);
    this.state = {
      validated: 0,
      breadcrum_params: [],
      brands: [],
      mcategories: [],
      name: "",
      bname: "",
      mname: "",
    };
    this.errors = [];
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    this.setState({ validated: this.state.validated + 1 });

    if (form.checkValidity() === true) {
      const url = "category";
      const params = {
        name: this.state.name,
        brand_id: 0,
        main_category_id: this.state.mname,
      };
      this.apiCall(url, params, "../categories", "POST", "Category added");
    }
  };

  componentDidMount() {
    var newBreadcrum = this.state.breadcrum_params.slice();
    newBreadcrum.push([1, "../categories", "Category"]);
    newBreadcrum.push([0, 0, "Add Category"]);
    this.setState({ breadcrum_params: newBreadcrum });
    this.apiCall("brands?create_for_dropdown_list=yes", "", "", "GET", "").then(
      (res) => {
        this.setState({
          brands: res.data.brands,
          mcategories: res.data.mcategories2,
        });
        if (res.data.mcategories2.length == 1) {
          this.setState({ mname: this.state.mcategories[0].id });
        }
      }
    );
  }

  render() {
    const { breadcrum_params } = this.state;
    const { brands } = this.state;
    return (
      <div>
        <Breadcrumbs params={breadcrum_params} />

        <Form
          noValidate
          validated={this.state.validated}
          onSubmit={this.handleSubmit}
        >
          <h3>Add Category</h3>
          <Row className="mb-3">
            <Form.Group as={Col} md="6" controlId="id01">
              <Form.Label>
                Category Name<span className="text-danger"> *</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="name"
                placeholder="Enter brand name"
                required
                value={this.state.name}
                onChange={this.validateInput}
                isInvalid={this.errors.name}
                autoFocus
                autoComplete="off"
              />
              <Form.Control.Feedback type="invalid">
                {this.errors.name
                  ? this.errors.name
                  : "Please provide category name"}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} md="6" controlId="id02">
              <Form.Label>
                Main Category Level2<span className="text-danger"> *</span>
              </Form.Label>
              <select
                className="form-select"
                required
                value={this.state.mname}
                name="mname"
                onChange={this.validateInput}
              >
                <option value="">Select Main Category</option>
                {this.state.mcategories.length > 0 &&
                  this.state.mcategories.map((row, i) => (
                    <option key={i} value={row.id}>
                      {row.name}
                    </option>
                  ))}
              </select>
              <Form.Control.Feedback type="invalid">
                {this.errors.mname
                  ? this.errors.mname
                  : "Please select main category"}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          {/* <Row className="mb-3">
        <Form.Group as={Col} md="6" controlId="id03">
        <Form.Label>Brand Name<span className="text-danger"> *</span></Form.Label>
        <select className="form-select" required value={this.state.bname} name="bname" 
        
        onChange={this.validateInput}>
          <option value="">Select Brand Name</option>
          {
            brands.length>0 && brands.map((row,i) => (
              <option key={i} value={row.id}>{row.name}</option>
              ))
          }
        </select>
          <Form.Control.Feedback type="invalid">
                {this.errors.bname ? this.errors.bname : 'Please select brand name'}
              </Form.Control.Feedback>
            </Form.Group>
        </Row> */}
          <Button type="submit">Save</Button> &nbsp;
          <Button variant="secondary" onClick={this.goBack}>
            Cancel
          </Button>
        </Form>
      </div>
    );
  }
}

export default Category;
