import React from "react";
import CommonFunctions from "../commonFunctions.js";
import Barcode from 'react-barcode';

class Print extends CommonFunctions {
  constructor(props) {
    super(props)
    this.state = {
      records: [],
      products: [],
      currency: '',
      company_name:'',
      purchase_id:this.props.match.params.purchase_id,
      quantity:this.props.match.params.quantity
  };
  }

 componentDidMount() {

  var obj = this
  this.apiCall('barcode/'+this.state.purchase_id, '', '', 'GET','').then(res => {

    this.setState({products: res.data.product[0], currency : res.data.currency, company_name : res.data.company_name}); 

    const url = "barcode/1";
    const params = {purchase_id: obj.state.purchase_id, quantity : obj.state.quantity}
    obj.apiCall(url, params, "", "PUT", "").then(res => {
        var content = document.getElementById("card-body").innerHTML
        var print_data = content
        document.getElementById("root").innerHTML = print_data
        window.print();
        window.close();
    })

    })

  }

  render() {
    const { records } = this.state;
    
    var records1 = [...this.state.records]
    for(var i = 1; i<= this.state.quantity;i++)
    {
        var val = this.state.purchase_id+"P"+i
        //this.state.purchase_id+"P"+i+'\n'+this.state.products.name+"<br />"+this.state.products.selling_price
        records1.push([val, (this.state.products.name+"<br /><b>"+this.state.currency+" "+(this.state.products.selling_price)+"</b>")])
    }
    

    return (
      <div className="bar_code_container">   
        {
            records1.length>0 && records1.map((row,i) => (
                <div className="print_barcode_label" key={i}>
                  <div className="barcode_company_name">{this.state.company_name}</div>
                <Barcode 
                value={row[0]} 
                width='1' 
                format="CODE128"
                displayValue = {false}
                marginTop = '-2'
                height='25'
                />
                <div className="barcode_texts" dangerouslySetInnerHTML={{ __html: row[0] +"<br />"+ row[1]}} />
                </div>
            ))
        }
      </div>
  );
}
}

export default Print;