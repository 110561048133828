import { right } from "@popperjs/core";
import React,{Component} from "react";
import { Link } from "react-router-dom";

export default class Breadcrumbs extends Component{
    render() {
        return (

          <nav aria-label="breadcrumb">
          {(JSON.parse(localStorage.getItem("data")).role_id) !=3 &&               
            <ol className="breadcrumb">

            <li className="breadcrumb-item fa-green"><Link className="breadcrum-link" to={"/"}><i className="fas fa-home"></i > Home</Link></li>
              
              {this.props.params && this.props.params.map((param, i) => (
                <li key={i} className="breadcrumb-item">
                       {param[0]>0 && <Link className="breadcrum-link" to={param[1]}>{param[2]}</Link>}
                       {param[0] === 0 && param[2]}
                </li>
              ))}
            </ol>
          }
          </nav>  
    );
  }
}