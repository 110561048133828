import React from "react";
import CommonFunctions from "../commonFunctions.js";
import { Form, Select, Button, FormGroup,Col,Row,InputGroup, FormControl, ControlLabel } from "react-bootstrap";
import { Link } from "react-router-dom";
import Breadcrumbs from "../Breadcrumbs";

class Paymode extends CommonFunctions {
  constructor(props) {
    super(props)
    this.state = {
      validated: 0,
      breadcrum_params: [],
      name :'',
      account_no :'',
      ifsc:'',
      branch:''
    }
    this.errors = [];
    this.handleSubmit  = this.handleSubmit.bind(this);
  }
  
  handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();    
    this.setState({ validated: this.state.validated + 1 })
    
    if(form.checkValidity() === true)
    {
      const url = "paymentmode/"+this.props.match.params.slug;
      const params = {name: this.state.name, account_no : this.state.account_no}
      this.apiCall(url, params, "../paymentmodes", "PUT", "Payment mode updated")
    }
  }
  
  componentDidMount() {
    var newBreadcrum = this.state.breadcrum_params.slice();
    newBreadcrum.push([1,'../paymentmodes', 'Payment Mode']);
    newBreadcrum.push([0, 0, 'Update Payment Mode']);
    this.setState({breadcrum_params: newBreadcrum});  
  
    let url = "paymentmode/"+this.props.match.params.slug;
    let params = {};
    this.apiCall(url, params, '', 'GET','').then(res => {
      this.setState({name: res.data[0].name, account_no: res.data[0].account_no,slug: res.data[0].slug}); 
    })

  }

  render() {
    const {breadcrum_params} = this.state;
    const {taxes} = this.state;
    return (
      <div>
      <Breadcrumbs params = {breadcrum_params}/> 

    <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
      <h3>Update Payment Mode</h3>
      <Row className="mb-3">
        <Form.Group as={Col} md="6" controlId="id01">
          <Form.Label>Payment Mode Name<span className="text-danger"> *</span></Form.Label>
          <Form.Control 
            type="text" 
            name="name"
            placeholder="Enter payment mode name" 
            required
            value={this.state.name} 
            onChange={this.validateInput}
            isInvalid={this.errors.name}
            autoFocus
            autoComplete='off'
            />
          <Form.Control.Feedback type="invalid">
            {this.errors.name ? this.errors.name : 'Please provide payment mode name'}
          </Form.Control.Feedback>
        </Form.Group>
        </Row>

        <Row className="mb-3">
        <Form.Group as={Col} md="6" controlId="id02">
          <Form.Label>Account Number</Form.Label>
          <Form.Control 
            type="number" 
            name="account_no"
            placeholder="Enter account number" 
            value={this.state.account_no} 
            onChange={this.validateInput}
            isInvalid={this.errors.account_no}
            autoComplete='off'
            />
          <Form.Control.Feedback type="invalid">
            {this.errors.account_no ? this.errors.account_no : 'Please provide valid account number'}
          </Form.Control.Feedback>
        </Form.Group>
        </Row>

        <Row className="mb-3">
        <Form.Group as={Col} md="6" controlId="id03">
          <Form.Label>IFSC</Form.Label>
          <Form.Control 
            type="text" 
            name="ifsc"
            placeholder="Enter IFSC" 
            value={this.state.ifsc} 
            onChange={this.validateInput}
            isInvalid={this.errors.ifsc}
            autoComplete='off'
            />
          <Form.Control.Feedback type="invalid">
            {this.errors.ifsc ? this.errors.ifsc : 'Please provide valid ifsc'}
          </Form.Control.Feedback>
        </Form.Group>
        </Row>

        
        <Row className="mb-3">
        <Form.Group as={Col} md="6" controlId="id04">
          <Form.Label>Branch</Form.Label>
          <Form.Control 
            type="text" 
            name="branch"
            placeholder="Enter Branch" 
            value={this.state.branch} 
            onChange={this.validateInput}
            isInvalid={this.errors.branch}
            autoComplete='off'
            />
          <Form.Control.Feedback type="invalid">
            {this.errors.branch ? this.errors.branch : 'Please provide valid branch'}
          </Form.Control.Feedback>
        </Form.Group>
        </Row>

      <Button type="submit">Save</Button> &nbsp;
      <Button variant="secondary" onClick={this.goBack}>Cancel</Button>
    </Form>
    </div>
  );
}
}

export default Paymode;