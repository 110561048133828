import React from "react";
import CommonFunctions from "../commonFunctions.js";
import { Form, Select, Button, FormGroup,Col,Row,InputGroup, FormControl, ControlLabel } from "react-bootstrap";
import { Link } from "react-router-dom";
import Breadcrumbs from "../Breadcrumbs";

class Tax extends CommonFunctions {
  constructor(props) {
    super(props)
    this.state = {
      validated: 0,
      breadcrum_params: [],
      name :'',
      percentage :'',
    }
    this.errors = [];
    this.handleSubmit  = this.handleSubmit.bind(this);
  }
  
  handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();    
    this.setState({ validated: this.state.validated + 1 })
    
    if(form.checkValidity() === true)
    {
      const url = "tax";
      const params = {name: this.state.name, percentage : this.state.percentage}
      this.apiCall(url, params, "../taxes", "POST", "Tax Details added")
    }
  }
  
  componentDidMount() {
    var newBreadcrum = this.state.breadcrum_params.slice();
    newBreadcrum.push([1,'../taxes', 'Tax']);
    newBreadcrum.push([0, 0, 'Add New Tax']);
    this.setState({breadcrum_params: newBreadcrum});  
  }

  render() {
    const {breadcrum_params} = this.state;
    const {taxes} = this.state;
    return (
      <div>
      <Breadcrumbs params = {breadcrum_params}/> 

    <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
      <h3>Add Tax Details</h3>
      <Row className="mb-3">
        <Form.Group as={Col} md="6" controlId="id01">
          <Form.Label>Tax Name<span className="text-danger"> *</span></Form.Label>
          <Form.Control 
            type="text" 
            name="name"
            placeholder="Enter tax name" 
            required
            value={this.state.name} 
            onChange={this.validateInput}
            isInvalid={this.errors.name}
            autoFocus
            autoComplete='off'
            />
          <Form.Control.Feedback type="invalid">
            {this.errors.name ? this.errors.name : 'Please provide tax name'}
          </Form.Control.Feedback>
        </Form.Group>
        </Row>

        <Row className="mb-3">
        <Form.Group as={Col} md="6" controlId="id02">
          <Form.Label>Tax Percentage<span className="text-danger"> *</span></Form.Label>
          <Form.Control 
            type="number" 
            name="percentage"
            placeholder="Enter tax percentage" 
            required
            value={this.state.percentage} 
            onChange={this.validateInput}
            isInvalid={this.errors.percentage}
            autoFocus
            autoComplete='off'
            min="1"
            />
          <Form.Control.Feedback type="invalid">
            {this.errors.percentage ? this.errors.percentage : 'Please provide tax percentage'}
          </Form.Control.Feedback>
        </Form.Group>
        </Row>

      <Button type="submit">Save</Button> &nbsp;
      <Button variant="secondary" onClick={this.goBack}>Cancel</Button>
    </Form>
    </div>
  );
}
}

export default Tax;