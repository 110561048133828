import React from "react";
import CommonFunctions from "../commonFunctions.js";
import Tablehead from "../TableheadWithDate";
import Breadcrumbs from "../Breadcrumbs";
import Pagination from "../Pagination";
import { Link } from "react-router-dom";
import {storeResult} from '../../redux';

class Sale extends CommonFunctions {
  constructor(props) {
    super(props)
    this.getData  = this.getData.bind(this);    
    this.state = {
      records: [],
      axios_responded:0,
      breadcrum_params: [],
      records_cnt: '',
      radioboxes: [],
      pageNum:'',
      model_url:'sales/sales_return',
  };
  this.toPrint  = this.toPrint.bind(this);
    storeResult.subscribe(() => {
        this.setState({
          records: storeResult.getState()[0].data.data,
          records_cnt: storeResult.getState()[0].data.total,
          radioboxes: storeResult.getState()[1],
          pageNum: storeResult.getState()[2],
          axios_responded : 1
        });
    });
  }

  toPrint(invoice_no){
    var myWindow=window.open('./sale/'+invoice_no,'','width=600,height=800');
    myWindow.focus();
  }

 componentDidMount() {

    var newBreadcrum = this.state.breadcrum_params.slice();
    // newBreadcrum.push([1,'../brands', 'Brands']);
    newBreadcrum.push([0, 0, 'Sales Return']);
    this.setState({breadcrum_params: newBreadcrum});  
    let url = "sales/sales_return";
    let params = {};
    this.getData(url, params)
  }

  render() {
    const { records } = this.state;
    const {axios_responded} = this.state;
    const {breadcrum_params} = this.state;
    const {records_cnt} = this.state;
    
    return (
      <div>
      <Breadcrumbs params = {breadcrum_params}/> 
        {localStorage.getItem("billing_app") && <Tablehead header1="Sales Return List" model_url={this.state.model_url} />}
        {!localStorage.getItem("billing_app") && <Tablehead header1="Sales Return List" model_url={this.state.model_url} />}
        
      {axios_responded == 1 && 
        <div className='table-responsive'>
        <table className='table styled-table'>
        <thead>
        <tr><th>S.No</th><th>Invoice No</th><th>Customer</th><th>Name</th><th>Returned Qty</th><th>Reason</th>
        <th>Status</th><th>Date</th></tr>
        </thead>
        <tbody>
        
        {records && records.length == 0 && <tr><td colSpan='12' className="text-center text-danger">
        <div className="text-center p-2 alert-warning">No Records Found !</div>
          </td></tr> }

        {records.length>0 && records.map((row,i) => (
        <tr key={i}>
            <td>{(this.state.pageNum + i)}</td>
            <td>{row.invoice_no}</td>
            <td>{row.cname} ({row.phone})</td>
            <td>{row.pname}</td>
            <td>{row.return_quantity}</td>
            <td>{row.comments}</td>
            <td>{row.status}</td>
            <td>{row.created_at}</td>
        </tr>
         ))}
        </tbody>
      </table>
      {records && records.length>0 && <Pagination page_cnt={records_cnt} model_url={this.state.model_url} />}
      </div>
      }
      </div>
  );
}
}

export default Sale;