import React, { Component } from "react";
import axios from "axios";
import { store, storeResult, userStore, storeTableHead } from "../redux";
import { NotificationManager } from "react-notifications";

export default class CommonFunctions extends Component {
  constructor(props) {
    super(props);
    if (document.location.host.indexOf("localhost") >= 0)
      this.API_URL = "http://localhost/billing-api/public/api/";
    else if (document.location.host.indexOf("bill") >= 0)
      this.API_URL =
        "https://bill.resizeimagepixel.com/billing-api/public/api/";
    else this.API_URL = "https://bill.resizeimagepixel.com/billing-api/public/api/";

    this.showLoading = this.showLoading.bind(this);
    this.handlePageLimit = this.handlePageLimit.bind(this);
    this.apiCall = this.apiCall.bind(this);
    this.page_limit = 10;
    this.page_num = 1;
    this.timeOutSeconds = "";
  }

  async getData(url, params) {
    await this.apiCall(url, params, "", "GET", "").then((res) => {
      //console.log(res)
      if (res != undefined && res.status != "error") {
        let status = [];
        if (res.data.data) {
          res.data.data.forEach(function (item, index) {
            // console.log(item.id);
            status[item.id] = item.status === "ACTIVE";
          });
        }
        // console.log(status)
        var PageStarts = (this.page_num - 1) * 10 + 1;
        storeResult.dispatch({ type: "add", value: [res, status, PageStarts] });
      }
    });
  }

  changeStatus(i, master, val, e) {
    let new_radioboxes = [...this.state.radioboxes];
    let new_radiobox = new_radioboxes[val];
    new_radiobox = e.target.checked;
    new_radioboxes[val] = new_radiobox;
    this.setState({ radioboxes: new_radioboxes });
    let status = e.target.checked == true ? "ACTIVE" : "INACTIVE";
    let params = { id: val, status: status, master: master };
    this.apiCall("common/status-update", params, "", "POST", "Status Changed");
  }

  handlePageSearch(model_url, event) {
    var sdate = this.state.sdate;
    var edate = this.state.edate;
    var val = this.state.search_txt;
    storeTableHead.dispatch({ type: "add", value: [sdate, edate, val] });

    let url =
      model_url +
      "?page=" +
      this.page_num +
      "&page_limit=" +
      this.page_limit +
      "&sdate=" +
      sdate +
      "&edate=" +
      edate +
      "&search_str=" +
      val;
    let params = {};
    let obj = this;
    // this.timeOutSeconds = setTimeout(function(){
    obj.getData(url, params);
    // },800)
  }

  handlePageSearch2(model_url, event) {
    var sdate = this.state.sdate;
    var edate = this.state.edate;
    var val = event.target.value;
    storeTableHead.dispatch({ type: "add", value: [sdate, edate, val] });
    clearTimeout(this.timeOutSeconds);

    let url =
      model_url +
      "?page=" +
      this.page_num +
      "&page_limit=" +
      this.page_limit +
      "&sdate=" +
      sdate +
      "&edate=" +
      edate +
      "&search_str=" +
      val;
    let params = {};
    let obj = this;
    this.timeOutSeconds = setTimeout(function () {
      obj.getData(url, params);
    }, 800);
  }

  handlePageChange(model_url, sdate, edate, search_txt, data) {
    this.page_num = data.selected + 1;

    let url =
      model_url +
      "?page=" +
      this.page_num +
      "&page_limit=" +
      this.page_limit +
      "&sdate=" +
      sdate +
      "&edate=" +
      edate +
      "&search_str=" +
      search_txt;
    let params = {};
    this.getData(url, params);
  }

  handlePageLimit(model_url, event) {
    this.page_num = 1;
    this.page_limit = event.target.value;
    let url =
      model_url + "?page=" + this.page_num + "&page_limit=" + this.page_limit;
    let params = {};
    this.getData(url, params);
  }

  showLoading = (param) => {
    store.dispatch({ type: "show", value: param });
  };

  validateInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    this.errors[e.target.name] = "";
  };

  validateInputArray = (i, e) => {
    let new_state_value = [...this.state[e.target.name]];
    new_state_value[i] = e.target.value;
    this.setState({ [e.target.name]: new_state_value });

    let new_state_value2 = [...this.state.total_purchases_added];
    new_state_value2[i][e.target.name] = e.target.value;
    // console.log(e.target.name+"===="+e.target.value)
    // console.log(new_state_value2);
    this.setState({ total_purchases_added: new_state_value2 });
    this.errors[e.target.name] = "";
  };

  validateInputArray2 = (i, name, e) => {
    let new_state_value = [...this.state[name]];
    new_state_value[i] = e.value;
    let new_state_value22 = [...this.state.product_names2];
    new_state_value22[i] = e.label;
    //value={this.state.product_names[i]}
    this.setState({
      [name]: new_state_value,
      product_names2: new_state_value22,
    });
    let new_state_value2 = [...this.state.total_purchases_added];
    new_state_value2[i][name] = e.value;
    // console.log(e.target.name+"===="+e.target.value)
    // console.log(new_state_value2);
    this.setState({ total_purchases_added: new_state_value2 });
    this.errors[name] = "";
  };

  goBack = (e) => {
    // this.context.router.goBack()
    window.history.go(-1);
  };

  handleApiResponse(api_response, redirect_url, method, success_msg) {
    this.showLoading(0);

    if (api_response.status == "error")
      NotificationManager.error(api_response.message);
    else if (success_msg) {
      NotificationManager.success(success_msg);
      if (redirect_url) this.props.history.push(redirect_url);
    }
  }

  async apiCall(
    url,
    params,
    redirect_url = "",
    method = "POST",
    success_msg,
    file_upload = ""
  ) {
    this.showLoading(1);
    var givenURL = url;
    url = this.API_URL + url;
    let headers = "";

    if (!file_upload) {
      headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
    } else {
      headers = {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      };
    }

    let token = localStorage.getItem("cid");
    headers["Authorization"] = `Bearer ${token}`;

    let response_data = [];
    return await axios({
      url: url,
      headers: headers,
      data: params,
      method: method,
    })
      .then((response) => {
        if (response.data.error == "Access Denied") {
          this.showLoading(0);
          NotificationManager.error(response.data.error);
          // this.props.history.push("/access-denied");
          this.props.history.push("/sale/add");
        } else if (givenURL == "user/login") {
          if (response.data.token) {
            localStorage.setItem("cid", response.data.token);
            localStorage.setItem("data", JSON.stringify(response.data.user));
            if (response.data.user.ecom_app) {
              localStorage.setItem("ecom_app", response.data.user.ecom_app);
            } else {
              localStorage.removeItem("ecom_app");
            }

            if (response.data.user.billing_app) {
              localStorage.setItem("billing_app", response.data.user.billing_app);
            } else {
              localStorage.removeItem("billing_app");
            }

            // ecom_app
            // window.location.href = "./sale/add";
            window.location.href = "./sales";
            // console.log(response.data.user)
          }
        }
        response_data["status"] = "success";
        this.listData = response_data["data"] = response;
        this.handleApiResponse(
          response_data,
          redirect_url,
          method,
          success_msg
        );
        return response;
      })
      .catch((error) => {
        if (error.message == "Network Error") {
          this.showLoading(0);
          NotificationManager.error("Network connection error occurred");
        } else {
          this.errors = error.response.data.errors;
          response_data["status"] = "error";

          if (error.response.data.message == "Token has expired") {
            //error.response.data.errors.login_error !== undefined ||
            localStorage.removeItem("cid");
            // if(error.response.data.errors.login_error)
            // NotificationManager.error(error.response.data.errors.login_error)
            // else
            NotificationManager.error("Session expired");
            window.location.href = window.location.href;
            return response_data;
          } else if (error.response.data.message)
            response_data["message"] = error.response.data.message;
          else if (error.response.statusText)
            response_data["message"] = error.response.statusText;

          this.handleApiResponse(
            response_data,
            redirect_url,
            method,
            success_msg
          );

          return response_data;
        }
      });
  }

  format = (date) => {
    date = new Date(date);

    var day = ("0" + date.getDate()).slice(-2);
    var month = ("0" + (date.getMonth() + 1)).slice(-2);
    var year = date.getFullYear();

    return year + "-" + month + "-" + day;
  };

  CurrencyFormatted(num) {
    // var p = num.toFixed(2).split(".");
    // return p[0].split("").reverse().reduce(function(acc, num, i, orig) {
    //     return num + (num != "-" && i && !(i % 3) ? "," : "") + acc;
    // }, "")
    // //+ "." + p[1];
    if (!num) {
      num = 0;
    }
    const curr = num.toLocaleString("en-IN");
    return curr;
  }
}
