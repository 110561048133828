import React from "react";
import CommonFunctions from "../commonFunctions.js";
import { Form, Select, Button, FormGroup,Col,Row,InputGroup, FormControl, ControlLabel } from "react-bootstrap";
import { Link } from "react-router-dom";
import Breadcrumbs from "../Breadcrumbs";

class Vendor extends CommonFunctions {
  constructor(props) {
    super(props)
    this.state = {
      validated: 0,
      breadcrum_params: [],
      full_name :'',
      phone :'',
      address :'',
      email :'',
    }
    this.errors = [];
    this.handleSubmit  = this.handleSubmit.bind(this);
  }
  
  handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();    
    this.setState({ validated: this.state.validated + 1 })
    
    if(form.checkValidity() === true)
    {
      const url = "vendor/"+this.props.match.params.slug;
      const params = {full_name: this.state.full_name, phone : this.state.phone, email : this.state.email, address : this.state.address}
      this.apiCall(url, params, "../vendors", "PUT", "Vendor updated")
    }
  }
  
  componentDidMount() {
    var newBreadcrum = this.state.breadcrum_params.slice();
    newBreadcrum.push([1,'../vendors', 'Vendor']);
    newBreadcrum.push([0, 0, 'Update Vendor']);
    this.setState({breadcrum_params: newBreadcrum});  

    let url = "vendor/"+this.props.match.params.slug;
    let params = {};
    this.apiCall(url, params, '', 'GET','').then(res => {
        this.setState({full_name: res.data[0].full_name, phone: res.data[0].phone, email: (res.data[0].email ? res.data[0].email : ''), address: res.data[0].address, 
          slug: res.data[0].slug}); 
    })
  }

  render() {
    const {breadcrum_params} = this.state;
    const {taxes} = this.state;
    return (
      <div>
      <Breadcrumbs params = {breadcrum_params}/> 

    <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
      <h3>Update Vendor</h3>
      <Row className="mb-3">
        <Form.Group as={Col} md="6" controlId="id01">
          <Form.Label>Vendor Full Name<span className="text-danger"> *</span></Form.Label>
          <Form.Control 
            type="text" 
            name="full_name"
            placeholder="Enter vendor full name" 
            required
            value={this.state.full_name} 
            onChange={this.validateInput}
            isInvalid={this.errors.full_name}
            autoFocus
            autoComplete='off'
            />
          <Form.Control.Feedback type="invalid">
            {this.errors.full_name ? this.errors.full_name : 'Please provide vendor full name'}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} md="6" controlId="id02">
          <Form.Label>Phone<span className="text-danger"> *</span></Form.Label>
          <Form.Control 
            type="number" 
            name="phone"
            placeholder="Enter Phone Number" 
            required
            value={this.state.phone} 
            onChange={this.validateInput}
            isInvalid={this.errors.phone}
            autoComplete='off'
            min="1"
            />
          <Form.Control.Feedback type="invalid">
            {this.errors.phone ? this.errors.phone : 'Please provide phone number'}
          </Form.Control.Feedback>
        </Form.Group>
        </Row>

        <Row className="mb-3">
        <Form.Group as={Col} md="6" controlId="id03">
          <Form.Label>Email Address</Form.Label>
          <Form.Control 
            type="email" 
            name="email"
            placeholder="Enter vendor email (Optional)" 
            value={this.state.email} 
            onChange={this.validateInput}
            isInvalid={this.errors.email}
            autoComplete='off'
            />
          <Form.Control.Feedback type="invalid">
            {this.errors.email ? this.errors.email : 'Please provide valid email ID'}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} md="6" controlId="id04">
          <Form.Label>Adderess<span className="text-danger"> *</span></Form.Label>
          <Form.Control as="textarea" rows={3} 
            name="address"
            placeholder="Enter Address" 
            required
            value={this.state.address} 
            onChange={this.validateInput}
            isInvalid={this.errors.address}
            autoComplete='off'
            min="1"
            />
          <Form.Control.Feedback type="invalid">
            {this.errors.address ? this.errors.address : 'Please provide address'}
          </Form.Control.Feedback>
        </Form.Group>
        </Row>
<br />
      <Button type="submit">Save</Button> &nbsp;
      <Button variant="secondary" onClick={this.goBack}>Cancel</Button>
    </Form>
    </div>
  );
}
}

export default Vendor;