import React from "react";
import CommonFunctions from "../commonFunctions.js";
import { Form, Select, Button, FormGroup,Col,Row,InputGroup, FormControl, ControlLabel } from "react-bootstrap";
import { Link } from "react-router-dom";
import Breadcrumbs from "../Breadcrumbs";

class Balance extends CommonFunctions {
  constructor(props) {
    super(props)
    this.state = {
      validated: 0,
      breadcrum_params: [],
      payment_mode:[],
      balances:[],
      currency:'',
      add_new_row_flag:0,
      invoice_id:0,
      customer_vendor_id:0,
      customer_vendor_type:0
    }
    this.errors = [];
    this.handleSubmit  = this.handleSubmit.bind(this);
    this.handleItemChange =     this.handleItemChange.bind(this);
    this.pendingAmount =     this.pendingAmount.bind(this);
    this.addRow  = this.addRow.bind(this);
  }

  deleteEntry(k, event)
  {
    let new_balances = [...this.state.balances]
    new_balances = [...this.state.balances]
    new_balances.splice(k, 1)

    this.setState({ balances: new_balances, add_new_row_flag : 1})

  }
  pendingAmount(e, i)
  {    
    let new_balances = [...this.state.balances]
    new_balances[i]['balance_amount'] = new_balances[i]['total_amount_to_pay'] - e.target.value
    if(!new_balances[i]['balance_amount'])
    {
      this.setState({ add_new_row_flag:0 });  
    }
    else
    {
      this.setState({ add_new_row_flag:1 });  
    }
    var len = this.state.balances.length; 
    var j = i+1;
    if(len>j)
    {
      for(var k=j;k<len;k++)
      {
        new_balances[k]['total_amount_to_pay'] = new_balances[i]['balance_amount']
        new_balances[k]['balance_amount'] = new_balances[k]['total_amount_to_pay'] - new_balances[k]['paid_amount']
        i++
      }
    }
    this.setState({ balances:new_balances });
  }
  addRow(){
    var len = this.state.balances.length; 
    let payment_mode_id = this.state.balances[len-1]['payment_mode_id']
    // let new_balances = [...this.state.balances]
    // new_balances[len] = []
    // new_balances[len]['total_amount_to_pay'] = this.state.balances[len-1]['balance_amount']
    // new_balances[len]['paid_amount'] = this.state.balances[len-1]['balance_amount']
    // new_balances[len]['id'] = -1
    // new_balances[len]['balance_amount'] = 0
    // new_balances[len]['created_on'] = this.format(new Date())
    // new_balances[len]['payment_mode_id'] = payment_mode_id
    // this.setState({ balances:new_balances });

    let data ={
    'total_amount_to_pay' : this.state.balances[len-1]['balance_amount'],
    'paid_amount' : this.state.balances[len-1]['balance_amount'],
    'id' : -1,
    'balance_amount': 0,
    'created_on' : this.format(new Date()),
    'payment_mode_id' : payment_mode_id
    }

    let new_balances = [...this.state.balances]
    new_balances.push(data)
    this.setState({ balances:new_balances })

    this.setState({ add_new_row_flag:0 }); 
  }
  handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();    
    this.setState({ validated: this.state.validated + 1 })
    
    if(form.checkValidity() === true)
    {
      const url = "balance";
      const params = {
        balances : this.state.balances,
        invoice_no: this.props.match.params.invoice_no, 
        invoice_id: this.state.invoice_id,
        customer_vendor_id:this.state.customer_vendor_id,
        customer_vendor_type:this.state.customer_vendor_type
      }
      // console.log(params)
      this.apiCall(url, params, "", "POST", "Balance updated for "+this.state.invoice).then(res => {
        this.props.history.goBack();
      })
    }
  }
  
  componentDidMount() {
    var newBreadcrum = this.state.breadcrum_params.slice();
    newBreadcrum.push([1,'../../purchases', 'Purchase']);
    newBreadcrum.push([0, 0, 'Invoice Balance - '+this.props.match.params.invoice_no]);
    this.setState({breadcrum_params: newBreadcrum});  
    let url = "balance/"+this.props.match.params.invoice_no;
    this.apiCall(url, '', '', 'GET','').then(res => {     
      var is_show = 0;
      if(res.data.balances.length>=1)
      {
        if(res.data.balances[res.data.balances.length-1]['balance_amount']>0)
        {
          is_show = 1;
        }
      }
      this.setState({ add_new_row_flag:is_show, balances :res.data.balances, payment_mode :res.data.pay_mode, currency : res.data.currency, invoice_id : res.data.balances[0].invoice_id,
        customer_vendor_id:res.data.balances[0].customer_vendor_id,
        customer_vendor_type:res.data.balances[0].customer_vendor_type,
      });
    })
  }

  handleItemChange(e, len, id){
    
    let new_balances = [...this.state.balances]
    new_balances[len][id] = e.target.value
    this.setState({ balances:new_balances });

  }

  render() {
    const {breadcrum_params} = this.state;
    const {balances} = this.state;
    const {payment_mode} = this.state;

    return (
      <div>
      <Breadcrumbs params = {breadcrum_params}/> 

    <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
      <h3>Balance details for Invoice {this.props.match.params.invoice_no}</h3>
      <Row className="mb-3">
      <Form.Group as={Col} md="2">
        <Form.Label>Total Amount<span className="text-danger"> *</span></Form.Label>
      </Form.Group>
      <Form.Group as={Col} md="2">
        <Form.Label>Paid Amount<span className="text-danger"> *</span></Form.Label>
      </Form.Group>
      <Form.Group as={Col} md="2">
        <Form.Label>Balance<span className="text-danger"> *</span></Form.Label>
      </Form.Group>
      <Form.Group as={Col} md="2">
        <Form.Label>Payment Mode<span className="text-danger"> *</span></Form.Label>
      </Form.Group>
      <Form.Group as={Col} md="2">
        <Form.Label>Payment Date<span className="text-danger"> *</span></Form.Label>
      </Form.Group>
        </Row>
      
      {
        balances.length>0 && balances.map((row,i) => (
        <Row className="mb-3" key={i}>
        <Form.Group as={Col} md="2">
        <InputGroup  className="mb-2">
              <Form.Control 
                key={i}
                type="number" 
                name={"total_amount" + i}
                placeholder="Enter total amount" 
                readOnly
                value={row.total_amount_to_pay} 
                onChange={e => {this.handleItemChange(e, i, 'total_amount_to_pay')} }
                minLength="10"
              />
         <InputGroup.Text>{this.state.currency}</InputGroup.Text>
            </InputGroup>
        </Form.Group>

        <Form.Group as={Col} md="2">
        <InputGroup  className="mb-2">
              <Form.Control 
                key={i}
                type="number" 
                name={"total_amount" + i}
                placeholder="Enter paid amount" 
                required
                readOnly = {row.created_on != this.format(new Date()) && row.id>-1 ? true : false}
                value={row.paid_amount} 
                min={0}
                step="0.01"
                max = {row.total_amount_to_pay}
                onChange={e => {this.handleItemChange(e, i, 'paid_amount'); this.pendingAmount(e, i)} }
              />
        <InputGroup.Text>{this.state.currency}</InputGroup.Text>
            </InputGroup>
        </Form.Group>

        <Form.Group as={Col} md="2">
        <InputGroup  className="mb-2">
              <Form.Control 
                key={i}
                type="number" 
                name={"total_amount" + i}
                placeholder="Enter balanced amount" 
                required
                readOnly
                value={row.balance_amount} 
                onChange={e => {this.handleItemChange(e, i, 'balance_amount')} }
                step="0.01"
              />
         <InputGroup.Text>{this.state.currency}</InputGroup.Text>
            </InputGroup>
        </Form.Group>

        <Form.Group as={Col} md="2">
        <select className="form-select" required  name={"payment_mode_val"+ i}  value={row.payment_mode_id}
          readOnly = {row.created_on != this.format(new Date()) && row.id>-1 ? true : false}
          onChange={e => {this.handleItemChange(e, i, 'payment_mode_id')} }
          >
            {
              payment_mode.length>0 && payment_mode.map((row2,i) => (
                <option key={i} value={row2.id}>{row2.name}</option>
                ))
            }
          </select>
        </Form.Group>

        <Form.Group as={Col} md="2">
        <Form.Control 
                key={i}
                type="date" 
                name={"created_on" + i} 
                required
                readOnly = {row.created_on != this.format(new Date()) && row.id>-1 ? true : false}
                value={row.created_on} 
                onChange={e => {this.handleItemChange(e, i, 'created_on')} }
                className="m-b-5"
                minLength="10"
              />

      </Form.Group>

      {
      balances.length == (i+1) && row.id == -1 &&
      <Form.Group as={Col} md="2">
      <Button type="button" variant="danger" className="btn-icon  btn-sm" onClick={this.deleteEntry.bind(this, i)}> <i className="fas fa-trash"></i> </Button>
      </Form.Group>
      }

        </Row>
          ))
      }


      <Row className="mb-3">
            <Form.Group as={Col} md="4">
            {
            balances.length>0 && 
            <Button type="submit">Save</Button>
            } &nbsp;
            <Button variant="secondary" onClick={this.goBack}>Go Back</Button>
            </Form.Group>

            <Form.Group as={Col} md="6">
            {
              this.state.add_new_row_flag == 1 && 
              <Button className="float-right" variant="success" type="button" onClick={this.addRow}>Add New Payment <i className="fas fa-plus"></i ></Button>
            }
            </Form.Group>

      </Row>

    </Form>
    </div>
  );
}
}

export default Balance;