import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import CommonFunctions from "./commonFunctions.js";
import { storeTableHead } from '../redux';

export default class Pagination extends CommonFunctions{
    
  constructor(props) {
    super(props)
    this.state = {
      records_cnt: this.props.page_cnt,
      sdate:'',
      edate:'',
      search_txt:''
    };

    storeTableHead.subscribe(() => {
      console.log(storeTableHead.getState())
      this.setState({
        sdate :  storeTableHead.getState()[0],
        edate :  storeTableHead.getState()[1],
        search_txt : storeTableHead.getState()[2]
      })
    });

  }

    render() {
      // let { records_cnt } = this.state;
      let records_cnt = Math.ceil(this.props.page_cnt / this.page_limit);
        return (
          
    <div className="row">
      <div className="col-6"></div>

      {this.state.records_cnt>10 && <div className="col-1">
      <select name='page-limit' className="form-select" onChange={this.handlePageLimit.bind(this, this.props.model_url)}>
        <option value="10">10</option>
        <option value="25">25</option>
        <option value="50">50</option>
        <option value="100">100</option>
      </select>
      </div>
      }

      {this.state.records_cnt>10 && <div className="col-5">
        <ReactPaginate
        itemsCountPerPage={2}
        totalItemsCount={4}
        pageCount={records_cnt}
        onPageChange={this.handlePageChange.bind(this, this.props.model_url, this.state.sdate, this.state.edate, this.state.search_txt)}
        pageRangeDisplayed={5}
        containerClassName={'pagination float-right'}
        pageClassName="page-item"
        pageLinkClassName="page-link"
        nextClassName = "page-item"
        nextLinkClassName = "page-link"
        previousClassName = "page-item"
        previousLinkClassName = "page-link"
        activeClassName = "active"
        activeLinkClass = "active"
        breakLabel={' ... '}
        previousLabel = "Previous"
        nextLabel = "Next"
        />
      </div>
      }
        
    </div>
  
    );
  }
}