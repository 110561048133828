import React from "react";
import CommonFunctions from "../commonFunctions.js";
import { Form, Select, Button, FormGroup,Col,Row,InputGroup, FormControl, ControlLabel } from "react-bootstrap";
import { Link } from "react-router-dom";
import Breadcrumbs from "../Breadcrumbs";

class Currency extends CommonFunctions {
  constructor(props) {
    super(props)
    this.state = {
      validated: 0,
      breadcrum_params: [],
      name :'',
      symbol :'',
      slug:''
    }
    this.errors = [];
    this.handleSubmit  = this.handleSubmit.bind(this);
  }
  
  handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();    
    this.setState({ validated: this.state.validated + 1 })
    
    if(form.checkValidity() === true)
    {
      const url = "currency/"+this.props.match.params.slug;
      const params = {name: this.state.name, symbol : this.state.symbol}
      this.apiCall(url, params, "../currencies", "PUT", "Currency Details updated")
    }
  }
  
  componentDidMount() {
    var newBreadcrum = this.state.breadcrum_params.slice();
    newBreadcrum.push([1,'../currencies', 'Currency']);
    newBreadcrum.push([0, 0, 'Update Currency']);
    this.setState({breadcrum_params: newBreadcrum});  

    let url = "currency/"+this.props.match.params.slug;
    let params = {};
    this.apiCall(url, params, '', 'GET','').then(res => {
        this.setState({name: res.data[0].name, symbol: res.data[0].symbol,slug: res.data[0].slug}); 
    })
  }

  render() {
    const {breadcrum_params} = this.state;
    const {taxes} = this.state;
    return (
      <div>
      <Breadcrumbs params = {breadcrum_params}/> 

    <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
      <h3>Update Currency Details</h3>
      <Row className="mb-3">
        <Form.Group as={Col} md="6" controlId="id01">
          <Form.Label>Currency Name<span className="text-danger"> *</span></Form.Label>
          <Form.Control 
            type="text" 
            name="name"
            placeholder="Enter currency name" 
            required
            value={this.state.name} 
            onChange={this.validateInput}
            isInvalid={this.errors.name}
            autoFocus
            autoComplete='off'
            />
          <Form.Control.Feedback type="invalid">
            {this.errors.name ? this.errors.name : 'Please provide currency name'}
          </Form.Control.Feedback>
        </Form.Group>
        </Row>

        <Row className="mb-3">
        <Form.Group as={Col} md="6" controlId="id02">
          <Form.Label>Currency symbol<span className="text-danger"> *</span></Form.Label>
          <Form.Control 
            type="text" 
            name="symbol"
            placeholder="Enter currency symbol" 
            required
            value={this.state.symbol} 
            onChange={this.validateInput}
            isInvalid={this.errors.symbol}
            autoComplete='off'
            min="1"
            />
          <Form.Control.Feedback type="invalid">
            {this.errors.symbol ? this.errors.symbol : 'Please provide currency symbol'}
          </Form.Control.Feedback>
        </Form.Group>
        </Row>

      <Button type="submit">Save</Button> &nbsp;
      <Button variant="secondary" onClick={this.goBack}>Cancel</Button>
    </Form>
    </div>
  );
}
}

export default Currency;