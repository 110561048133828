import React from "react";
import CommonFunctions from "../commonFunctions.js";
import Tablehead from "../Tablehead";
import Breadcrumbs from "../Breadcrumbs";
import Pagination from "../Pagination";
import { Link } from "react-router-dom";
import {storeResult} from '../../redux';

class Product extends CommonFunctions {
  constructor(props) {
    super(props)
    this.getData  = this.getData.bind(this);    
    this.state = {
      records: [],
      axios_responded:0,
      breadcrum_params: [],
      records_cnt: '',
      radioboxes: [],
      pageNum:'',
      model_url:'product'
  };

    storeResult.subscribe(() => {
        this.setState({
          records: storeResult.getState()[0].data.data,
          records_cnt: storeResult.getState()[0].data.total,
          radioboxes: storeResult.getState()[1],
          pageNum: storeResult.getState()[2],
          axios_responded : 1
        });
    });
  }

 componentDidMount() {

    var newBreadcrum = this.state.breadcrum_params.slice();
    // newBreadcrum.push([1,'../brands', 'Brands']);
    newBreadcrum.push([0, 0, 'product']);
    this.setState({breadcrum_params: newBreadcrum});  
    let url = "product";
    let params = {};
    this.getData(url, params)
  }

  render() {
    const { records } = this.state;
    const {axios_responded} = this.state;
    const {breadcrum_params} = this.state;
    const {records_cnt} = this.state;
    
    return (
      <div>
      <Breadcrumbs params = {breadcrum_params}/> 
      <Tablehead header1 = "Products List" link="/product/add" model_url={this.state.model_url} />

      {axios_responded == 1 && 
        <div className='table-responsive'>
        <table className='table styled-table'>
        <thead>
        <tr><th>S.No</th><th>Product Name</th><th>Main Category L1</th><th>Main Category L2</th><th>Category Name</th><th>Brand Name</th>
        <th>Quantity</th>
        <th>Status</th>
        {/* <th>BarCodes</th> */}
        <th>Actions</th></tr>
        </thead>
        <tbody>
        
        {records && records.length == 0 && <tr><td colSpan='6' className="text-center text-danger">No Records Available</td></tr> }

        {records.length>0 && records.map((row,i) => (
        <tr key={i}>
          <td>{(this.state.pageNum+i)}</td>
          <td>{row.name}</td>
          <td>{row.main_category_name1}</td>
          <td>{row.main_category_name2}</td>
          <td>{row.category}</td>
          <td>{row.brand}</td>
          <td>{row.quantity}</td>
          <td>          
            <div className="form-check form-switch">
            <input className="form-check-input" type="checkbox"  
            checked={this.state.radioboxes[row.id]}
            onChange={this.changeStatus.bind(this,i,'Product',row.id)} />
              {/* <label class="custom-control-label">Active</label> */}
            </div>
          </td>
          {/* <td>
          <Link to={"/product/barcodes/"+row.slug}>
          <button type="button" className="btn btn-sm btn-info active"><i class="fas fa-barcode fa-lg"></i> Show Barcodes </button>
          </Link>
            </td> */}
          <td>
          <Link to={"/product/"+row.slug}>
          <button type="button" className="btn btn-sm btn-info active"> <i className="fas fa-edit"></i > Edit</button>
          </Link>
          </td>
        </tr>
         ))}
        </tbody>
      </table>
      {records && records.length>0 && <Pagination page_cnt={records_cnt} model_url={this.state.model_url} />}
      </div>
      }
      </div>
  );
}
}

export default Product;