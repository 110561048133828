import React, { Component } from 'react';
import { storeMenu } from '../redux';
import logo from '../logo.png'
import { Link } from "react-router-dom";

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu_status:0
    };
    this.toggleMenu  = this.toggleMenu.bind(this);    
    this.logout  = this.logout.bind(this);    
  }
  toggleMenu(){
    var menu_status = this.state.menu_status == 1 ? 0 : 1;
    storeMenu.dispatch({type: 'add', value: menu_status});
    
    this.setState({menu_status: menu_status});

  }

  logout(){
    localStorage.removeItem("cid");
    window.location.href = window.location.href
  }
  render(){
    const {menu_status} = this.state;
  return (
    <nav className="sb-topnav navbar navbar-expand navbar-dark bg-dark menu5">
<div className={(menu_status == 1 ? 'space_for_sidebar minimize_width logo_text':'space_for_sidebar logo_text')}>
  {/* <img src={logo} /> */}
  <span style={{border:'2px solid #fff', padding:'2px 4px 3px 4px', borderRadius:'7px'}}>
    <i style={{color:'#fff', fontSize:'22px'}} className="fas fa-calculator"></i></span> &nbsp;<span className="hide_for_mobile">STOCKS2BILL</span>
            </div>

        {(JSON.parse(localStorage.getItem("data")).role_id) !=3 && 
        <button onClick={this.toggleMenu} className="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0" id="sidebarToggle"><i className="fas fa-bars"></i></button>
        }

            <div className="hide_for_mobile logo_text d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">
              <span>{ (JSON.parse(localStorage.getItem("data")).name)}</span>
            </div>
            <div className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">
            </div>

 
  
            <ul className="navbar-nav ms-auto ms-md-0 me-3 me-lg-4">
            {JSON.parse(localStorage.getItem("data")).role_id !=3 &&
                <li className="nav-item">
                    <Link className="nav-link" to={"/notifications"}>
                      <i className="fas fa-user fa-bell"></i>
                      {JSON.parse(localStorage.getItem("data")).notify_cnt>0 &&
                      <span className="badge badge-danger" style={{verticalAlign: 'top', fontSize: '9px', padding:'3px'}}>
                      {(JSON.parse(localStorage.getItem("data")).notify_cnt)}
                      </span>
                      }
                      </Link>
                </li>
            }
          
            {JSON.parse(localStorage.getItem("data")).role_id ==3 &&
                <li className="nav-item"><Link className="nav-link" to={"/sale/add"}>Sale</Link></li>
            }
            {JSON.parse(localStorage.getItem("data")).role_id ==3 &&
                <li className="nav-item"><Link className="nav-link" to={"/purchases"}>Purchase</Link></li>
            }
            {JSON.parse(localStorage.getItem("data")).role_id ==3 &&
                <li className="nav-item"><Link className="nav-link" to={"/expenses"}>Expenses</Link></li>
            }

                <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" id="navbarDropdown" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <i className="fas fa-user fa-fw"></i></a>
                    <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                        <li><a className="dropdown-item" href="#!">Settings</a></li>
                        <li><a className="dropdown-item" href="#!">Activity Log</a></li>
                        <li><hr className="dropdown-divider" /></li>
                        
                        {/* <li><a className="dropdown-item" onClick={this.logout}>Sign Out</a></li> */}
                        <Link className="dropdown-item" to={"/today-sale/closed"}>Sign Out</Link>
                    </ul>
                </li>
            </ul>
        </nav>
  );
}
}
