import React from "react";
import {Switch,Route, Redirect} from "react-router-dom";

import Login from './component/auth/login';
import PurchaseList from './component/purchase/list';
import PurchaseAdd from './component/purchase/add';
import BalanceAdd from './component/balance/add';
import PurchaseEdit from './component/purchase/edit';
import PurchaseReturn from './component/purchase/return';
import PurchaseReturnList from './component/purchase/return_list';

import SaleList from './component/sale/list';
import SaleAdd from './component/sale/add';
import SalePrint from './component/sale/print';
import SaleToday from './component/sale/today-sales';
import SaleReturn from './component/sale/return';
import SalesReturn from './component/sale/return_list';
import BarcodePrint from './component/product/print_bar_code';
import BarcodePrint2 from './component/product/print_bar_code_all';

import MainCategoryAdd from './component/main_category/add';
import MainCategoryEdit from './component/main_category/edit';
import MainCategoryList from './component/main_category/list';

import BrandAdd from './component/brand/add';
import BrandEdit from './component/brand/edit';
import BrandList from './component/brand/list';

import CategoryAdd from './component/category/add';
import CategoryEdit from './component/category/edit';
import CategoryList from './component/category/list';

import ProductAdd from './component/product/add';
import ProductEdit from './component/product/edit';
import ProductList from './component/product/list';
import ProductBarCode from './component/product/add_bar_code';

import TaxAdd from './component/tax/add';
import TaxEdit from './component/tax/edit';
import TaxList from './component/tax/list';

import CurrencyAdd from './component/currency/add';
import CurrencyEdit from './component/currency/edit';
import CurrencyList from './component/currency/list';

import CustomerAdd from './component/customer/add';
import CustomerEdit from './component/customer/edit';
import CustomerList from './component/customer/list';

import VendorAdd from './component/vendor/add';
import VendorEdit from './component/vendor/edit';
import VendorList from './component/vendor/list';

import UserList from './component/user/list';

import PayModeAdd from './component/payment_mode/add';
import PayModeEdit from './component/payment_mode/edit';
import PayModeList from './component/payment_mode/list';

import ExpenseAdd from './component/expense/add';
import ExpenseEdit from './component/expense/edit';
import ExpenseList from './component/expense/list';

import Dashboard from './component/dashboard/dashboard';
import StockReport from './component/report/stock-report';

import Notifications from './component/notification/list';
import Configuration from './component/configuration/add-edit';


export default function RoutesPath() {
  
  const loalstore = localStorage.getItem("cid");

  return (    
    !localStorage.getItem("cid") ? (
      <Switch>

        <Route path="/login" exact component = {Login}  />
        <Redirect to="/login" />

      </Switch>
    ) : 
    (<Switch>		

            <Route path="/purchases" exact component = {PurchaseList}  />
            <Route path="/purchase/add" exact component = {PurchaseAdd}  />
        <Route path="/purchase/:invoice_no" exact component={PurchaseEdit} />
        <Route path="/purchase-return/:invoice_no" exact component={PurchaseReturn} />
        <Route path="/purchases-return/" exact component={PurchaseReturnList} />
            <Route path="/sales" exact component = {SaleList}  />    
            <Route path="/sale/add" exact component = {SaleAdd}  />  
            <Route path="/" exact component = {SaleAdd}  />    
            
            <Route path="/sale/:invoice_no" exact component = {SalePrint}  />	
            <Route path="/today-sale/closed" exact component={SaleToday} />
        <Route path="/sale-return/:invoice_no" exact component={SaleReturn} />
        <Route path="/sales-return/" exact component={SalesReturn} />
        
            <Route path="/print-bar-code/:purchase_id/:quantity" exact component = {BarcodePrint}  />	
            <Route path="/print-bar-code-all/:invoice_no" exact component = {BarcodePrint2}  />	
            
            <Route path="/balances/:invoice_no" exact component = {BalanceAdd}  />	
            
            <Route path="/main-category/add" exact component = {MainCategoryAdd}  />
            <Route path="/main-category/:slug" exact component = {MainCategoryEdit}  />
            <Route path="/main-categories" exact component = {MainCategoryList}  />  

            <Route path="/brand/add" exact component = {BrandAdd}  />
            <Route path="/brand/:slug" exact component = {BrandEdit}  />
            <Route path="/brands" exact component = {BrandList}  />  

            <Route path="/category/add" exact component = {CategoryAdd}  />   
            <Route path="/category/:slug" exact component = {CategoryEdit}  />   
            <Route path="/categories" exact component = {CategoryList}  />    

            <Route path="/product/add" exact component = {ProductAdd}  /> 
            <Route path="/product/barcodes/:slug" exact component = {ProductBarCode}  />          
            <Route path="/product/:slug" exact component = {ProductEdit}  />
            <Route path="/products" exact component = {ProductList}  />   

            <Route path="/tax/add" exact component = {TaxAdd}  /> 
            <Route path="/tax/:slug" exact component = {TaxEdit}  /> 
            <Route path="/taxes" exact component = {TaxList}  />   
            
            <Route path="/currency/add" exact component = {CurrencyAdd}  /> 
            <Route path="/currency/:slug" exact component = {CurrencyEdit}  /> 
            <Route path="/currencies" exact component = {CurrencyList}  />   

            <Route path="/customer/add" exact component = {CustomerAdd}  /> 
            <Route path="/customer/:slug" exact component = {CustomerEdit}  /> 
            <Route path="/customers" exact component = {CustomerList}  /> 

            <Route path="/vendor/add" exact component = {VendorAdd}  /> 
            <Route path="/vendor/:slug" exact component = {VendorEdit}  /> 
            <Route path="/vendors" exact component = {VendorList}  />   

            <Route path="/paymentmode/add" exact component = {PayModeAdd}  /> 
            <Route path="/paymentmode/:slug" exact component = {PayModeEdit}  /> 
            <Route path="/paymentmodes" exact component = {PayModeList}  />   


            <Route path="/expense/add" exact component = {ExpenseAdd}  /> 
            <Route path="/expense/:slug" exact component = {ExpenseEdit}  /> 
            <Route path="/expenses" exact component = {ExpenseList}  />  

            <Route path="/users" exact component = {UserList}  />   

            <Route path="/dashboard" exact component = {Dashboard}  />   
            <Route path="/report/stocks" exact component = {StockReport}  />   
            <Route path="/notifications" exact component = {Notifications}  />   
            <Route path="/configurations" exact component = {Configuration}  />   

    </Switch>)
  );

}