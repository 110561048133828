import React from "react";
import CommonFunctions from "../commonFunctions.js";
import { Form, Select, Button, FormGroup,Col,Row,InputGroup, FormControl, ControlLabel } from "react-bootstrap";
import { Link } from "react-router-dom";
import Breadcrumbs from "../Breadcrumbs";
import {NotificationManager} from 'react-notifications';

class Sale extends CommonFunctions {
  constructor(props) {
    super(props)
    this.state = {
      validated: 0,
      enable_to_keep_pending_amount:0,
      breadcrum_params: [],
      sales:[],
      total_sales_quantity:0,
      total_sales_amount:0,
      total_paid_amount:0,
      paying_amount:0,
      balance_amount:0,
      payment_mode_id:'',
      bill_state:0,
      user_mobile:'',
      user_name:'',
      payment_mode:[],
      brands:[],
      category:[],
      products:[],
      currency:'',
      is_print:'',
      customers_list:[],
      customers_list1:[],
      discount_amount:0,
      model_url:'product/list',
      charges:[],
      charges_selected:[],
      charge_amount:[],
    }
    this.errors = [];
    this.handleSubmit  = this.handleSubmit.bind(this);
    this.handleItemAdd  = this.handleItemAdd.bind(this);
    this.handleItemChange  = this.handleItemChange.bind(this);
    this.handleItemsTotal  = this.handleItemsTotal.bind(this);
    this.toPrint  = this.toPrint.bind(this);
    this.setCustomerName  = this.setCustomerName.bind(this);
    this.addMoreCharges = this.addMoreCharges.bind(this)
  }

  addMoreCharges()
  {
    var charges_selected = [...this.state.charges_selected]
    charges_selected.push(0)
    this.setState({ charges_selected: charges_selected}) 
    
  }
  removeCharges(j, e)
  {
    var charges_selected = [...this.state.charges_selected]
    charges_selected.splice(j, 1)

    var charge_amount = [...this.state.charge_amount]
    charge_amount.splice(j, 1)

    this.setState({ charges_selected: charges_selected, charge_amount : charge_amount})
    
    var len = this.state.sales.length;
    var overall_total_price = 0;
    for(var k=0;k<len;k++)
    {
      overall_total_price+= parseFloat(this.state.sales[k]['amount'])
    }

    charge_amount.map((amount,i) => (
      overall_total_price+= parseFloat(amount|| 0)
    ));
    this.setState({total_sales_amount: overall_total_price, paying_amount:overall_total_price});

  }

  toPrint(val){
    this.setState({ is_print:val })
  }
  setCustomerName(e){
    var val = e.target.value || ''
    // console.log(val)
    this.setState({ user_name: this.state.customers_list1[val] })
  }
  handleItemsTotal(){
    
    var len = this.state.sales.length;
    var amount_total = 0;
    var quantity_total = 0;
    for(var k=0;k<len;k++)
    {
      quantity_total+= parseFloat(this.state.sales[k]['quantity'])
      amount_total+= parseFloat(this.state.sales[k]['amount'])
    }
    
    quantity_total = quantity_total.toString()
    amount_total = amount_total.toString()

    this.setState({ total_sales_quantity:quantity_total, total_sales_amount:amount_total, paying_amount: amount_total, balance_amount: 0})
  }
  
  handleItemChange(i, e, type, type2){
  var sales_data = [...this.state.sales];  

  if(type2 == 'increase')
  {
    sales_data[i].quantity = parseFloat(sales_data[i].quantity) + 1
  }
  else if(type2 == 'decrease')
  {
    sales_data[i].quantity = parseFloat(sales_data[i].quantity) - 1 
  }
  else
  {
    sales_data[i].quantity = e.target.value 
  }

  if((!sales_data[i].quantity || sales_data[i].quantity <= 0) && type == 'quantity')
  {
    // sales_data.splice(i, 1)
    sales_data[i].quantity = 0
  }
  sales_data[i].amount = (sales_data[i].rate * sales_data[i].quantity).toString()
  this.setState({ sales:sales_data })
  this.handleItemsTotal()  
  }

  // handleItemAdd(id, stock_id, product_name, rate){
    handleItemAdd(id,stock_id,name, price, dicounted_price){
    var amt = dicounted_price>0 ? dicounted_price : price;
    var len = this.state.sales.length;   
    let data ={
    'name' : name,
    'id' : id,
    'stock_id' : stock_id,
    'quantity' : 1,
    'rate': amt,
    'amount': amt
    }

    let new_sales = [...this.state.sales]
    new_sales[len] = data
    // new_sales.push(data)
    this.setState({ sales:new_sales })
  
    // this.handleItemsTotal()

    var amount_total = parseFloat(amt);
    var quantity_total = 1;
    
    if(len)
    {
      for(var k=0;k<len;k++)
      {
        quantity_total+= parseFloat(this.state.sales[k]['quantity'])
        amount_total+=parseFloat(this.state.sales[k]['amount'])
      }
    }
    this.setState({ total_sales_quantity:quantity_total, total_sales_amount:amount_total, paying_amount: amount_total, balance_amount: 0, discount_amount : 0 })

    NotificationManager.success(name+" added to billing list", "", 1500);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.discount_amount !== this.state.discount_amount) {
      var paying_amount = this.state.total_sales_amount - this.state.discount_amount
      this.setState({ paying_amount: paying_amount })
    }
    if (prevState.paying_amount !== this.state.paying_amount) {
      var balance_amount = this.state.total_sales_amount - this.state.discount_amount - this.state.paying_amount
      this.setState({ balance_amount: balance_amount })
    }
  }

  handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();    
    this.setState({ validated: this.state.validated + 1})
    var l = this.state.charges.length
    var error_msg = "";
    for(var j=0;j<l;j++)
    {
      var amt = this.state.charge_amount[j]
      var id = this.state.charges_selected[j]
      if(amt>0 && id==0)
      {
        error_msg = 1;
        NotificationManager.error("Please select the Charges name for the amount "+amt, "", 2500);
        break;
      }
    }

    if(form.checkValidity() === true && !error_msg)
    {
      const url = "sales";
      const params = {sales_data: this.state.sales, paying_amount:this.state.paying_amount, discount_amount:this.state.discount_amount, 
        payment_mode_val: this.state.payment_mode_id,
        user_mobile:this.state.user_mobile, 
        user_name:this.state.user_name,
        charge_amount: this.state.charge_amount,
        charges_selected: this.state.charges_selected
      }
        // console.log(params)
     this.apiCall(url, params, "../sales", "POST", "Bill Created").then(res => {
      // console.log(res.data.invoice_num)
      if(this.state.is_print)
      {
        var myWindow=window.open('./sale/'+res.data.invoice_num,'','width=600,height=800');
        myWindow.focus();
      }
     });
    }
  }
  deleteEntry(i, event)
  {
    let new_sales = [...this.state.sales]
    var amount_total = this.state.total_sales_amount - new_sales[i].amount;
    var quantity_total = parseFloat(this.state.total_sales_quantity) - parseFloat(new_sales[i].quantity);

    new_sales.splice(i, 1)

    this.setState({ sales: new_sales, total_sales_quantity:quantity_total, total_sales_amount:amount_total })

  }
  
  handlePageSearch3(model_url, event){
    var val = event.target.value
    clearTimeout(this.timeOutSeconds);

    let url = model_url+"?create_for_dropdown_list=yes&search_str="+val;
    let params = {}
    let obj = this
    this.timeOutSeconds = setTimeout(function(){
      obj.apiCall(url, '', '', 'GET','').then(res => {
        obj.setState({products: res.data.data, currency : res.data.currency, payment_mode :res.data.pay_mode, payment_mode_id:res.data.pay_mode[0].id}); 
        document.getElementById("search_txt").focus()
        if(event.keyCode == 13 && res.data.data.length == 1)
        {
          console.log(res.data.data[0])
          obj.handleItemAdd(res.data.data[0].id, res.data.data[0].stock_id, res.data.data[0].name, res.data.data[0].price, res.data.data[0].discounted_price)
          document.getElementById("search_txt").value = ''
        }
      })
      
    },500)
    
  }

  componentDidMount() {
    var newBreadcrum = this.state.breadcrum_params.slice();
    newBreadcrum.push([1,'../sales', 'Sale List']);
    newBreadcrum.push([0, 0, 'Sell']);
    this.setState({breadcrum_params: newBreadcrum});  
    this.apiCall('product/list?create_for_dropdown_list=sales', '', '', 'GET', '').then(res => {
      if (res.status != "error"){
      if (res.data.data.length) {
          this.setState({
            products: res.data.data, currency: res.data.currency, payment_mode: res.data.pay_mode, payment_mode_id: res.data.pay_mode[0].id,
            charges: res.data.charges,
            charges_selected: [0],
            charge_amount: [0, 0, 0, 0, 0]
          });
          var customers_list1 = []
          res.data.customers.map(function (data, i) {
            // console.log(data.full_name+"==="+data.phone)     
            // customers_list1.push({name: data.phone+( data.full_name ? " ("+data.full_name+")":''), value: data.phone});
            customers_list1[data.phone] = data.full_name;
          })
          this.setState({ customers_list: res.data.customers, customers_list1: customers_list1 })
        }
    }
    })
  }

  
  chargesChange = (i, e) => {
    let new_state_value = [...this.state[e.target.name]]
    new_state_value[i] = e.target.value || 0;
    this.setState({ [e.target.name]: new_state_value})

    var len = this.state.sales.length;

    var overall_total_price = 0;

    for(var k=0;k<len;k++)
    {
      overall_total_price+= parseFloat(this.state.sales[k]['amount'])
    }

    new_state_value.map((amount,i) => {
      var charge_type = ''; //+ or --
      if(this.state.charges_selected[i])
      {

        this.state.charges.length>0 && this.state.charges.map((row,k) => {
          if(row.id == this.state.charges_selected[i])
          {
            charge_type = row.charge_type
          }
        })

        if( charge_type == '-')
        {
            overall_total_price-= parseFloat(amount|| 0);
        }
        else
        {
            overall_total_price+= parseFloat(amount|| 0);
        }
      }

    });

    this.setState({total_sales_amount: overall_total_price, paying_amount:overall_total_price});
  }

    chargeTypesSelectboxChange = (i, e) => {
      let new_state_value = [...this.state[e.target.name]]
      new_state_value[i] = e.target.value || 0;
      this.setState({ [e.target.name]: new_state_value})
      
      var len = this.state.sales.length;

    var overall_total_price = 0;

    for(var k=0;k<len;k++)
    {
      overall_total_price+= parseFloat(this.state.sales[k]['amount'])
    }

    this.state.charge_amount.map((amount,i) => {
      var charge_type = ''; //+ or --
      if(new_state_value[i])
      {

        this.state.charges.length>0 && this.state.charges.map((row,k) => {
          if(row.id == new_state_value[i])
          {
            charge_type = row.charge_type
          }
        })

        if( charge_type == '-')
        {
            overall_total_price-= parseFloat(amount|| 0);
        }
        else
        {
            overall_total_price+= parseFloat(amount|| 0);
        }
      }

    });

    this.setState({total_sales_amount: overall_total_price, paying_amount:overall_total_price});
  }
  render() {
    const {breadcrum_params} = this.state;
    const {brands} = this.state;
    const {category} = this.state;
    const {products} = this.state;
    const {sales} = this.state;
    const {payment_mode} = this.state;

    return (
      <div>
      <Breadcrumbs params = {breadcrum_params}/> 


{/* {products.length>0 &&  */}
    <div>
      <Row className="mb-3">

        <Form.Group as={Col} md="12">

        <div className="auto_complete_list w-50-percent">
        <div className="input-group">
                <input autoComplete='off' type="search" id="search_txt" name="search_txt" 
                autoFocus={true}
                onChange={e => { this.validateInput(e);}}
                onKeyUp={e => { this.handlePageSearch3(this.state.model_url, e)}}
                className="search_box form-control" placeholder='Type product details to search here...' />
                <button type="button" className="active btn-icon menu5 search_btn"><i className="fas fa-search"></i ></button>
            </div>

          <ul>

            {
               products.length>0 && products.map((row,i) => (
                <li key={i} onClick={e => {this.handleItemAdd(row.id, row.stock_id, row.name, row.price, row.discounted_price)}}>
                  {row.name +' '+ ' (In Stock-'+row.quantity +') '} 
                  {this.state.currency} 
                  {row.discounted_price>0 && <span><strike> {row.price} </strike> &nbsp;{row.discounted_price}</span>}
                  {row.discounted_price == 0 && <span>{row.price}</span>}
                  <input type='button' className='btn btn-sm btn-primary add_to_bill' value="Add>>" /></li>
                ))
            }
          </ul>
        </div>

        <div className="bill_list w-50-percent">
        <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
        {
        sales.length>0 && 
        <Row className="mb-1 p-1">
          <div style={{textAlign:'center', marginBottom:'-10px'}} className="col-12 price_heading">Products added to Bill
          </div>

          <div className="col-12 price_heading"># Product Name &nbsp;
          <span style={{float:'right'}} >
          Quantity &nbsp;
          <span style={{width:'112px', display:'inline-block', textAlign:'right'}}>Amount</span> &nbsp;
          </span>
          </div>
        </Row>
        }
        {
        sales.length>0 && sales.map((row,i) => (
          <Row  key={i} className="mb-1 p-1" style={{borderBottom:'1px solid #ccc'}} >
          <div className="col-12">{1+i}. {row.name} &nbsp;
          <span style={{float:'right'}} >
          <input type='number' className="form-control-sm" style={{maxWidth:'80px'}} 
                value={row.quantity} 
                onChange={e => { this.handleItemChange(i, e, 'quantity', 'change') }}
                /> &nbsp;
          <span style={{width:'80px', display:'inline-block', textAlign:'right'}}>{row.amount}</span> &nbsp;
          <Button title={'Remove '+row.name} type="button" variant="danger" className="btn-icon  btn-sm" onClick={this.deleteEntry.bind(this, i)}> <i className="fas fa-trash"></i> </Button>
          </span>
          </div>
        </Row>
        ))}

        {sales.length>0 &&
        <Row>
          <div className='col-12 text-end mb-2'> Total Items: <b>{this.state.total_sales_quantity}</b> & Total Amount:  <b>{this.state.currency} {this.state.total_sales_amount}</b> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
        </Row>
        }

        {
          sales.length<=0 && <Row className="mb-1 p-1 text-danger">
            <div className="text-center p-2 alert-warning">Select/Click the product from left side to create bill</div>
            </Row>
        }

        {sales.length>0 && <div className="text-end"><Button type="button" variant="primary" className="btn-icon  btn" onClick={() => this.setState({bill_state: 1})}> <i className="fas fa-save"></i> Submit </Button></div>}
        {sales.length>0 && this.state.bill_state>0 && 
        <div>

{
              this.state.charges_selected.length>0 && this.state.charges_selected.map((row1,j) => (
        <Row className="mb-2" key={j}>
      <Form.Group as={Col} md="5">
      <select key={'a'+j} className="form-select font-13 charges_option"  name="charges_selected"  value={this.state.charges_selected[j]}
      onChange={this.chargeTypesSelectboxChange.bind(this, j)} >
            <option value=''>Select Charges</option>
            {
              this.state.charges.length>0 && this.state.charges.map((row,i) => (
                <option charge_type={row.charge_type} key={i} value={row.id}>{row.name}</option>
                ))
            }
          </select>
        </Form.Group>

        <Form.Group as={Col} md="5">
        <Form.Control 
            key={'b'+j}
            type="number" 
            name="charge_amount"
            step="any"
            min="0"
            value={this.state.charge_amount[j]} 
            onChange={e => { this.chargesChange(j, e);}}
            />      
      </Form.Group>
      <Form.Group as={Col} md="1">
        {j == 0 && this.state.charges.length > this.state.charges_selected.length && <span onClick={this.addMoreCharges}><i className="text-success fas fa-plus"></i ></span>}
        {j > 0 && <span onClick={this.removeCharges.bind(this, j)}><i className="text-danger fas fa-trash"></i ></span>}
        </Form.Group>

        </Row>
        ))}

        <Row>
        <Form.Group as={Col} md="3">
      <Form.Label>Pay Mode</Form.Label>
          <select className="form-select"  name="payment_mode_id" 
          
          onChange={this.validateInput}>
            {
              payment_mode.length>0 && payment_mode.map((row,i) => (
                <option key={i} value={row.id}>{row.name}</option>
                ))
            }
          </select>
          {/* <Form.Control.Feedback type="invalid">
            {this.errors.payment_mode_id ? this.errors.payment_mode_id : 'Please provide payment mode'}
          </Form.Control.Feedback> */}
        </Form.Group>
        <Form.Group as={Col} md="3" className="mb-2">
        <Form.Label>Discount Amount</Form.Label>
          <Form.Control 
            type="number" 
            name="discount_amount"
            value={this.state.discount_amount} 
            onChange={this.validateInput}
            />
        </Form.Group>

      <Form.Group as={Col} md="3">
      <Form.Label>Paying Amount</Form.Label>
          <Form.Control 
            type="number" 
            name="paying_amount"
            required
            readOnly = {this.state.enable_to_keep_pending_amount ? true : false}
            value={this.state.paying_amount} 
            onChange={this.validateInput}
            />
        </Form.Group>

        <Form.Group as={Col} md="3">
        <Form.Label>Balance Amount</Form.Label>
          {/* <InputGroup className="mb-2"> */}
          <Form.Control 
            type="text" 
            name="balance_amount"
            readOnly
            value={this.state.balance_amount} 
            onChange={this.validateInput}
            />
            {/* <InputGroup.Text>{this.state.currency}</InputGroup.Text> */}
            {/* </InputGroup> */}
        </Form.Group>
        </Row>

        
        <Row>
        <Form.Group as={Col} md="3"></Form.Group>
        <Form.Group as={Col} md="5">
        <Form.Label>Customer Mobile</Form.Label>

          <Form.Control 
            type="text" 
            name="user_mobile"
            required
            value={this.state.mobile_number} 
            onChange={e => { this.validateInput(e); this.setCustomerName(e)}}
            minLength={10}
            list="phone_list"
            autoComplete="off"
            />

          <datalist id="phone_list">
            {
               this.state.customers_list.length>0 && this.state.customers_list.map((row,i) => (
                <option key={i} value={row.phone}>{row.phone+( row.full_name ? " ("+row.full_name+")":'')}</option>
                ))
            }
          </datalist>

        </Form.Group>
        <Form.Group as={Col} md="4">
        <Form.Label>Customer Name</Form.Label>
          <Form.Control 
            type="name" 
            placeholder="(Optional)"
            name="user_name"
            value={this.state.user_name} 
            onChange={this.validateInput}
            min="1"
            />
        </Form.Group>
        </Row>

        <Row className="mt-2">
        <Form.Group as={Col} md="5"></Form.Group>
        <Form.Group as={Col} md="3">
          <Button type="submit" variant="success" onClick={e =>{this.toPrint(0)} } className="btn-icon  btn"> <i className="fas fa-save"></i> Save </Button>
        </Form.Group>
        <Form.Group as={Col} md="4">
          <Button type="submit" variant="primary" onClick={e =>{this.toPrint(1)} } className="btn-icon  btn"> <i className="fas fa-print"></i> Save & Print</Button>
        </Form.Group>
        </Row>

        </div>
        }
        </Form>
        </div>
        </Form.Group>
        </Row>

    </div>
    </div>
  );
}
}

export default Sale;