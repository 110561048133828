import React from "react";
import CommonFunctions from "../commonFunctions.js";
import Tablehead from "../Tablehead";
import Pagination from "../Pagination";
import { Link } from "react-router-dom";
import {storeResult} from '../../redux';

class Notification extends CommonFunctions {
  constructor(props) {
    super(props)
    this.getData  = this.getData.bind(this);    
    this.state = {
      records: [],
      axios_responded:0,
      records_cnt: '',
      radioboxes: [],
      pageNum:'',
      model_url:'report/notification'
  };

    storeResult.subscribe(() => {
        this.setState({
          records: storeResult.getState()[0].data.data,
          records_cnt: storeResult.getState()[0].data.total,
          radioboxes: storeResult.getState()[1],
          pageNum: storeResult.getState()[2],
          axios_responded : 1
        });
    });
  }

 componentDidMount() {

    let url = this.state.model_url;
    let params = {};
    this.getData(url, params)
  }

  render() {
    const { records } = this.state;
    const {axios_responded} = this.state;
    const {records_cnt} = this.state;
    
    return (
      <div>
      <Tablehead header1 = "Notifications" model_url={this.state.model_url} />

      {axios_responded == 1 && 
        <div className='table-responsive'>
        <table className='table styled-table'>
        <thead>
        <tr><th>S.No</th><th>Notification</th><th>User</th><th>Created On</th></tr>
        </thead>
        <tbody>
        
        {records && records.length == 0 && <tr><td colSpan='4' className="text-center text-danger"><div className="text-center p-2 alert-warning">No Records Found !</div></td></tr> }

        {records.length>0 && records.map((row,i) => (
        <tr key={i}>
          <td>{(this.state.pageNum+i)}</td>
          <td>{row.detail}</td>
          <td>{row.first_name} {row.last_name}</td>
          <td>{row.created_at}</td>
        </tr>
         ))}
        </tbody>
      </table>
      {records && records.length>0 && <Pagination page_cnt={records_cnt} model_url={this.state.model_url} />}
      </div>
      }
      </div>
  );
}
}

export default Notification;