import React,{Component} from "react";
import RoutesPath from "../routes"
import {NotificationContainer, NotificationManager} from 'react-notifications';

import { store } from '../redux';

export default class Container extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: '',
    };

    store.subscribe(() => {
      // When state will be updated(in our case, when items will be fetched), 
      // we will update local component state and force component to rerender 
      // with new data.

      this.setState({
        loading: store.getState()
      });
    });
  }

  render() {
    return (
      <main>
      <div className="row no_mr">
          <div className="col-sm">

          { this.state.loading>0 &&
(
<div className="overlay">
<div className="text-center">
<div className="spinner-border text-primary" role="status">
<span className="sr-only">Loading...</span>
</div>
<p>Loading data..</p>
</div>
</div>
)}    
          <div className="container-fluid px-4" >

<div className="card-body center_margin">
      <RoutesPath />
</div>

<NotificationContainer/>
</div>

          </div>
          <div className="col-sm">
            <div className="login_container login_background"></div>
          </div>
      </div>
      </main>
    );
  }
}