import React from "react";
import CommonFunctions from "../commonFunctions.js";
import { Form, Button, FormGroup,Col,Row,InputGroup, FormControl, ControlLabel } from "react-bootstrap";
import { Link } from "react-router-dom";
import Breadcrumbs from "../Breadcrumbs";
import Select from 'react-select';
import {NotificationManager} from 'react-notifications';

class Purchase extends CommonFunctions {
  constructor(props) {
    super(props)
    this.state = {
      validated: 0,
      breadcrum_params: [],
      products : [],
      vendors:[],
      active_taxes:[],
      added_products : [],
      total_purchases_added: [],
      current_index:-1,
      quantity :[],
      purchase_price :[],
      selling_price :[],
      discount_price:[],
      margin_price :'',
      tax_amount :'',
      total_amount :'',
      currency:'',
      vname:'',
      product_names:[],
      product_names2:[],
      payment_mode:[],
      company_branches:[],
      location_details:[],
      payment_mode_val:'',
      total_tax:'',
      total_price:[],
      taxs:[],
      overall_total_price:0,
      paying_amount:'',
      balance_id:'',
      company_data:[],
      products_list1:[],
      charges:[],
      charges_selected:[],
      charge_amount:[],
      amount_error:[]
    }
    this.errors = [];
    this.handleSubmit  = this.handleSubmit.bind(this);
    this.updateTaxes  = this.updateTaxes.bind(this);
    this.calculateTotal  = this.calculateTotal.bind(this);
    this.addRow  = this.addRow.bind(this);
    this.toPrint  = this.toPrint.bind(this);
    this.addMoreCharges = this.addMoreCharges.bind(this)
  }
  
  addMoreCharges()
  {
    var charges_selected = [...this.state.charges_selected]
    charges_selected.push(0)
    this.setState({ charges_selected: charges_selected}) 
    
  }
  removeCharges(j, e)
  {
    var charges_selected = [...this.state.charges_selected]
    charges_selected.splice(j, 1)

    var charge_amount = [...this.state.charge_amount]
    charge_amount.splice(j, 1)
    console.log(j)

    this.setState({ charges_selected: charges_selected, charge_amount : charge_amount})
    
    
    var overall_total_price = 0;
    this.state.total_purchases_added.map((row,i) => (
      overall_total_price+= parseFloat(row.total_price || 0)
    ));

    charge_amount.map((amount,i) => (
      overall_total_price+= parseFloat(amount|| 0)
    ));
    this.setState({overall_total_price: overall_total_price, paying_amount:overall_total_price});

  }

  toPrint(purchase_id, quantity){
    var myWindow=window.open('../print-bar-code/'+purchase_id+'/'+quantity,'','width=600,height=800');
    myWindow.focus();
  }


  editEntry(i, row, event)
  {
      this.setState({ current_index:i, vname: row.vendor, product_names: row.product, quantity: row.quantity, purchase_price: row.purchase_price, selling_price: row.selling_price, 
        discount_price: row.discount_price, 
        margin_price: row.margin_price, total_tax: row.total_tax, location_details:row.location_details})  
  }
  deleteEntry(i, event)
  {
    let new_location = [...this.state.location_details]
    new_location.splice(i, 1)
    this.setState({ location_details: new_location})

    let new_product = [...this.state.product_names]
    new_product.splice(i, 1)
    this.setState({ product_names: new_product})
    
    let quantity = [...this.state.quantity]
    quantity.splice(i, 1)
    this.setState({ quantity: quantity})

    let purchase_price = [...this.state.purchase_price]
    purchase_price.splice(i, 1)
    this.setState({ purchase_price: purchase_price})

    let selling_price = [...this.state.selling_price]
    selling_price.splice(i, 1)
    this.setState({ selling_price: selling_price})

    let discount_price = [...this.state.discount_price]
    discount_price.splice(i, 1)
    this.setState({ discount_price: discount_price})

    let total_price = [...this.state.total_price]
    total_price.splice(i, 1)
    this.setState({ total_price: total_price})

    let taxs = [...this.state.taxs]
    taxs.splice(i, 1) 
    this.setState({ taxs: taxs})

    let new_purchases = [...this.state.total_purchases_added]
    new_purchases[i].total_price = 0
    new_purchases[i].total_tax = 0
    this.setState({total_purchases_added:new_purchases})

    new_purchases = [...this.state.total_purchases_added]
    new_purchases.splice(i, 1)
    this.setState({total_purchases_added: new_purchases})

    this.updateTaxes(event, i, 'delete')
  }

  addRow()
  {
    var len = this.state.location_details.length;   
    let new_location = [...this.state.location_details]
    
    if(len>0)
    {
      new_location[len] = this.state.location_details[len-1]
    }
    else
    {
      new_location[len] = this.state.company_branches[0].id
    }

    this.setState({ location_details: new_location})

    let new_product = [...this.state.product_names]
    new_product[len] = this.state.products[0].id
    this.setState({ product_names: new_product})
    
    let quantity = [...this.state.quantity]
    quantity[len] = ''
    this.setState({ quantity: quantity})

    let purchase_price = [...this.state.purchase_price]
    purchase_price[len] = ''
    this.setState({ purchase_price: purchase_price})

    let selling_price = [...this.state.selling_price]
    selling_price[len] = ''
    this.setState({ selling_price: selling_price})

    let discount_price = [...this.state.discount_price]
    discount_price[len] = 0
    this.setState({ discount_price: discount_price})

    let total_price = [...this.state.total_price]
    total_price[len] = ''
    this.setState({ total_price: total_price})

    let taxs = [...this.state.taxs]
    taxs[len] = []
    for(var j=0;j<this.state.active_taxes.length;j++)
    {
      var row = this.state.active_taxes[j]
      taxs[len][row.id] = row.percentage
    }
    this.setState({ taxs: taxs})
    
    var data = {
      product_names: this.state.products[0].id, 
      quantity: '', 
      purchase_price: '', 
      selling_price: '', 
      margin_price:0, 
      location_details:this.state.company_branches[0].id,
      total_tax: '',
      total_price: ''
    }
    let new_purchases = [...this.state.total_purchases_added]
    new_purchases.push(data)
    this.setState({ total_purchases_added:new_purchases })
  }
  updateEntry(event)
  {
      var data = {product_names: this.state.product_names, quantity:this.state.quantity, purchase_price: this.state.purchase_price, 
        selling_price:this.state.selling_price, 
        discount_price:this.state.discount_price, 
        margin_price:this.state.margin_price, location_details:this.state.location_details, total_tax: this.state.total_tax, total_price: this.state.total_price}

    let new_purchases = [...this.state.total_purchases_added]
    new_purchases[this.state.current_index] = data
    this.setState({ total_purchases_added:new_purchases, current_index: -1, product_names: '', vname:'', quantity:'', purchase_price:'', selling_price:'', margin_price : ''});
    this.calculateTotal()
  }

  handleSubmit = (event) => {
    
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();    
    this.setState({ validated: this.state.validated + 1 })
    
    var l = this.state.total_purchases_added.length
    var error_msg = "";
    var amount_error = [...this.state.amount_error]
    for(var j=0;j<l;j++)
    {
      var data = this.state.total_purchases_added[j]
      amount_error[j] = 0
      if(parseFloat(data.discount_price)>0 && parseFloat(data.discount_price)<parseFloat(data.purchase_price))
      {
        amount_error[j] = 1
        error_msg = "Discount price should be greater than purchase price for row #"+(j+1)
      }
      if(parseFloat(data.selling_price) <= parseFloat(data.purchase_price))
      {
        amount_error[j] = 1
        error_msg = "Selling Price should be greater than purchase price for row #"+(j+1)
      }
      if(parseFloat(data.discount_price)>0 && parseFloat(data.discount_price)>parseFloat(data.selling_price))
      {
        amount_error[j] = 1
        error_msg = "Discount price should be lesser than selling price for row #"+(j+1)
      }
      if(error_msg)
      {
        NotificationManager.error(error_msg, "", 3500);
        break;
      }
    }
    this.setState({ amount_error: amount_error})

    if(form.checkValidity() === true && !error_msg)
    {
      var invoice_no = this.props.match.params.invoice_no
      const url = 'purchase/'+invoice_no;
      const params = {
        total_purchases: this.state.total_purchases_added,
        overall_total_price:this.state.overall_total_price,
        paying_amount:this.state.paying_amount,
        payment_mode_val:this.state.payment_mode_val,
        taxs: this.state.taxs,
        vname: this.state.vname,
        balance_id:this.state.balance_id,
        charge_amount: this.state.charge_amount,
        charges_selected: this.state.charges_selected,
      }
      // console.log(params)
      this.apiCall(url, params, "../purchases", "PUT", "Purchase Details Updated")
    }
  }
  
  componentDidMount() {
    var newBreadcrum = this.state.breadcrum_params.slice();
    newBreadcrum.push([1,'../purchases', 'Purchase']);
    newBreadcrum.push([0, 0, 'Update Purchase']);
    this.setState({breadcrum_params: newBreadcrum});  
    var invoice_no = this.props.match.params.invoice_no
    this.apiCall('purchase/'+invoice_no, '', '', 'GET','').then(res => {

      var balances = res.data.balances[0];
      var charge_amount = res.data.charge_amount
      var charges_selected = res.data.charge_mapped_id
      this.setState({products: res.data.products, vendors: res.data.vendors, currency: res.data.currency, 
        payment_mode: res.data.payment_mode, company_branches:res.data.company_branches, company_data:res.data.company,
        charges:res.data.charges,
        charges_selected: res.data.charge_mapped_id,
        charge_amount:res.data.charge_amount
      }); 
        
        var products_list1 = []
      res.data.products.map(function(data, i){   
        products_list1.push({label: data.name, value: data.id});
      })
      // console.log(products_list1)
      this.setState({products_list1: products_list1})

        let taxs1 = []
        for(var j=0;j<res.data.tax.length;j++)
        {
          var row = res.data.tax[j]
          taxs1[row.tax_id] = []
          taxs1[row.tax_id]['name'] = row.name
        }
        this.setState({ active_taxes:taxs1 })

        let new_purchases = [...this.state.total_purchases_added]
        var len = res.data.records.length

        let taxs = [...this.state.taxs]
        let new_location = [...this.state.location_details]
        let new_product = [...this.state.product_names]
        let new_product2 = [...this.state.product_names2]
        let quantity = [...this.state.quantity]
        let purchase_price = [...this.state.purchase_price]
        let selling_price = [...this.state.selling_price]
        let discount_price = [...this.state.discount_price]
        let total_price = [...this.state.total_price]
        var vname = ''
        for(var i=0;i<len;i++)
        {
          var item = res.data.records[i]
          vname = item.vendor_id
          var data = {id: item.id,product_names: item.product_id, quantity:item.quantity, purchase_price: item.purchase_price, 
            selling_price:item.selling_price, 
            discount_price:item.discount_price, 
            margin_price:item.margin_price, location_details:item.branch_id, total_tax: item.tax_amount, total_price: item.total_amount}
    
          new_purchases[i] = data
          taxs[i] = []
          for(var j=0;j<res.data.tax.length;j++)
          {
            var row = res.data.tax[j]
            // console.log(row)
            if(item.id == row.order_id)
            taxs[i][row.tax_id] = row.percentage
          }

          new_location[i] = item.branch_id
          new_product[i] = item.product_id
          new_product2[i] = item.product_name
          quantity[i] = item.quantity
          purchase_price[i] = item.purchase_price
          selling_price[i] = item.selling_price
          discount_price[i] = item.discount_price
          total_price[i] = item.total_amount

        }
        this.setState({ taxs: taxs})
        this.setState({ total_purchases_added:new_purchases, current_index: -1, product_names: new_product,product_names2: new_product2, vname:vname, 
          location_details:new_location,
        quantity:quantity, purchase_price:purchase_price, selling_price:selling_price,
        discount_price:discount_price,
        margin_price : '', total_price:total_price});
        this.calculateTotal()
        this.setState({ payment_mode_val:balances.payment_mode_id, paying_amount:balances.total_balance_paid, balance_id:balances.id })

        var overall_total_price = 0;
        this.state.total_purchases_added.map((row,i) => (
          overall_total_price+= parseFloat(row.total_price || 0)
        ));

        charges_selected.map((charge_id,i) => {
          var charge_type = ''; //+ or --
          var amount = charge_amount[i]
          if( charge_id )
          {
    
            this.state.charges.length>0 && this.state.charges.map((row,k) => {
              if(row.id == charge_id)
              {
                charge_type = row.charge_type
              }
            })
            if( charge_type == '-')
            {
                overall_total_price-= parseFloat(amount|| 0);
            }
            else
            {
                overall_total_price+= parseFloat(amount|| 0);
            }
          }
    
        });
        this.setState({overall_total_price: overall_total_price});//, paying_amount:overall_total_price
        
    })

  }
  
    updateTaxes(e, i, name_value){
      var total_tax_val = 0;
      if(name_value == "tax" || name_value == "delete")
      {
        var quantity = this.state.quantity[i];
        var purchase_price = this.state.purchase_price[i];
      }
      else if(name_value == "quantity")
      {
        var quantity = e.target.value;
        var purchase_price = this.state.purchase_price[i];
      }
      else
      {
        var quantity = this.state.quantity[i];
        var purchase_price = e.target.value;
      }


      this.state.taxs[i].map((row,j) => (
        total_tax_val+=row * quantity * (purchase_price/100)
      ));//percentage
      
      let total_price = [...this.state.total_price]
      let total_price_val = (purchase_price * quantity + total_tax_val).toFixed(2)
       total_price[i] = total_price_val

      if(name_value != "delete")
      {
        let new_purchases = [...this.state.total_purchases_added]
        new_purchases[i].total_price = total_price_val
        new_purchases[i].total_tax = total_tax_val
        this.setState({total_purchases_added:new_purchases})
      }
      else
      {
        total_price[i] = 0;
      }

      this.setState({total_tax: total_tax_val, total_price:total_price });

      this.calculateTotal()
    }
    
    calculateTotal(){
      var overall_total_price = 0;
      this.state.total_purchases_added.map((row,i) => (
        overall_total_price+= parseFloat(row.total_price || 0)
      ));
      
      this.state.charge_amount.map((amount,i) => {
        var charge_type = ''; //+ or --
        if(this.state.charges_selected[i])
        {
  
          this.state.charges.length>0 && this.state.charges.map((row,k) => {
            if(row.id == this.state.charges_selected[i])
            {
              charge_type = row.charge_type
            }
          })
  
          if( charge_type == '-')
          {
              overall_total_price-= parseFloat(amount|| 0);
          }
          else
          {
              overall_total_price+= parseFloat(amount|| 0);
          }
        }
      })

      this.setState({overall_total_price: overall_total_price, paying_amount:overall_total_price});
    }
 

    validateInputTaxArray = (i, id, e) => {
      let new_state_value = [...this.state.taxs]
      new_state_value[i][id] = e.target.value;
      this.setState({ taxs: new_state_value})
      this.updateTaxes(e, i, 'tax')
    }

    chargesChange = (i, e) => {
      let new_state_value = [...this.state[e.target.name]]
      new_state_value[i] = e.target.value || 0;
      this.setState({ [e.target.name]: new_state_value})

      var overall_total_price = 0;
      this.state.total_purchases_added.map((row,i) => (
        overall_total_price+= parseFloat(row.total_price || 0)
      ));

      new_state_value.map((amount,i) => {
        var charge_type = ''; //+ or --
        if(this.state.charges_selected[i])
        {
  
          this.state.charges.length>0 && this.state.charges.map((row,k) => {
            if(row.id == this.state.charges_selected[i])
            {
              charge_type = row.charge_type
            }
          })
  
          if( charge_type == '-')
          {
              overall_total_price-= parseFloat(amount|| 0);
          }
          else
          {
              overall_total_price+= parseFloat(amount|| 0);
          }
        }
  
      });
      this.setState({overall_total_price: overall_total_price, paying_amount:overall_total_price});
    }

      chargeTypesSelectboxChange = (i, e) => {
        let new_state_value = [...this.state[e.target.name]]
        new_state_value[i] = e.target.value || 0;
        this.setState({ [e.target.name]: new_state_value})

        var overall_total_price = 0;
        this.state.total_purchases_added.map((row,i) => (
          overall_total_price+= parseFloat(row.total_price || 0)
        ));

        this.state.charge_amount.map((amount,i) => {
        var charge_type = ''; //+ or --
        if(new_state_value[i])
        {
  
          this.state.charges.length>0 && this.state.charges.map((row,k) => {
            if(row.id == new_state_value[i])
            {
              charge_type = row.charge_type
            }
          })
  
          if( charge_type == '-')
          {
              overall_total_price-= parseFloat(amount|| 0);
          }
          else
          {
              overall_total_price+= parseFloat(amount|| 0);
          }
        }
  
      });
      this.setState({overall_total_price: overall_total_price, paying_amount:overall_total_price});
    }

  render() {
    const {breadcrum_params} = this.state;
    const {products} = this.state;
    const {vendors} = this.state;
    const {active_taxes} = this.state;
    const {total_purchases_added} = this.state;
    const {payment_mode} = this.state;
    const {company_branches} = this.state;
    
    return (
      <div>
      <Breadcrumbs params = {breadcrum_params}/> 

    <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
      <h3>Update Purchase Details</h3>
      {
        this.state.current_index > -1 && <Button type="button" className="btn-icon"  onClick={this.updateEntry.bind(this)}>Save <i className="fas fa-save"></i ></Button>
      }
 
      <table className='table w-100'><tbody><tr><td>
      <Form.Group as={Col} md="4" controlId="id001">
          <Form.Label>Vendor Name<span className="text-danger"> *</span></Form.Label>
          <select className="form-select" required  name="vname" value={this.state.vname}
          
          onChange={this.validateInput}>
            <option value="">Select Vendor Name</option>
            {
              vendors.length>0 && vendors.map((row,i) => (
                <option key={i} value={row.id}>{row.full_name}</option>
                ))
            }
          </select>
          <Form.Control.Feedback type="invalid">
            {this.errors.vname ? this.errors.vname : 'Please provide vendor name'}
          </Form.Control.Feedback>
        </Form.Group>
        </td>
        <td className="text-end">
        <Form.Group>
        {/* <Button type="button" onClick={this.addRow} className="btn-icon">Add New Item <i className="fas fa-plus"></i ></Button> */}
        </Form.Group>
        </td></tr></tbody></table>

{/* Items list stats */}

{
      total_purchases_added.length>0 && 
      <table className='table table-line-height styled-table'>
        <thead><tr><th>S.No</th>
        {company_branches.length>1 && 
        <th width='12%'>Branch</th>
        }
        <th width='20%'>Product Name</th><th>Quantity</th>
        
        <th>Purchase {this.state.currency}</th>
        <th>Selling {this.state.currency}</th>
        <th>Discount {this.state.currency}</th>
        <th>Margin</th>
        {
          active_taxes.length>0 && active_taxes.map((row,i) => (
            
          <th key={i}>{row.name}<small><b>(%)</b></small>
         </th> 
            ))
        }
        <th>Total</th> 
        <th>Barcode</th> 
        {/* <th>Action</th> */}
        </tr></thead>
        <tbody>
      {
          total_purchases_added.map((row,i) => (
          <tr key={i}>
            <td>{i+1}</td>
{company_branches.length>1 && 
            <td>
      <Form.Group as={Col}>
          <select className="form-select" required  name="location_details" value={this.state.location_details[i]}
          onChange={this.validateInputArray.bind(this, i)}>
            {
              company_branches.length>0 && company_branches.map((row,i) => (
                <option key={i} value={row.id}>{row.location_name}</option>
                ))
            }
          </select>
          <Form.Control.Feedback type="invalid">
            {this.errors.name ? this.errors.name : 'Please provide branch name'}
          </Form.Control.Feedback>
        </Form.Group>
            </td>
}
            <td>
              
        <Form.Group as={Col}>
        <Select 
        required  
        name="product_names"  
        value={{value: this.state.product_names[i], label: this.state.product_names2[i]}}
        // value={this.state.product_names[i]}
        onChange={this.validateInputArray2.bind(this, i, 'product_names')} 
        placeholder="Search Product" 
        options={this.state.products_list1} />

          {/* <Form.Control.Feedback type="invalid">
            {this.errors.product_names ? this.errors.product_names : 'Please provide product name'}
          </Form.Control.Feedback> */}
        </Form.Group>
            </td>
            <td>
            <Form.Group as={Col}>
          <Form.Control 
            type="number" 
            name="quantity"
            required
            value={this.state.quantity[i]} 
            onChange={e => { this.validateInputArray(i, e); this.updateTaxes(e, i, 'quantity') }}
            isInvalid={this.errors.quantity}
            autoComplete='off'
            min="1"
            />
        </Form.Group>
            </td>
            <td>
        <Form.Group as={Col}>
          <Form.Control 
            type="number" 
            name="purchase_price"
            step="any"
            required
            value={this.state.purchase_price[i]} 
            onChange={e => { this.validateInputArray(i, e); this.updateTaxes(e, i, 'purchase_price') }}
            isInvalid={this.state.amount_error[i]}
            autoComplete='off'
            min="1"
            />
        </Form.Group>
            </td>
            <td>
        <Form.Group as={Col}>
          <Form.Control 
            type="number" 
            name="selling_price"
            step="any"
            required
            value={this.state.selling_price[i]} 
            onChange={this.validateInputArray.bind(this, i)}
            isInvalid={this.state.amount_error[i]}
            autoComplete='off'
            min="1"
            />
        </Form.Group>
            </td>
            <td>
        <Form.Group as={Col}>
          <Form.Control 
            type="number" 
            name="discount_price"
            step="any"
            placeholder="Optional"
            required
            value={this.state.discount_price[i]} 
            onChange={this.validateInputArray.bind(this, i)}
            isInvalid={this.state.amount_error[i]}
            autoComplete='off'
            />
        </Form.Group>
            </td>
            <td className='text-end'>{((this.state.discount_price[i]>0 ? this.state.discount_price[i] : this.state.selling_price[i]) - this.state.purchase_price[i]).toFixed(0)}</td>
            {
          active_taxes.length>0 && active_taxes.map((row2,i2) => (
          <td key={i2}>

          <Form.Control 
            type="number" 
            name="tax"
            step="any"
            value={this.state.taxs[i][i2] || 0} 
            onChange={this.validateInputTaxArray.bind(this, i, i2)}
            min="0"
            />
            </td> 
            ))
          }
          <td>
          {this.state.total_price[i]} 
          </td>
            {/* <td> */}
              {/* <Button type="button" variant="danger" className="btn-icon  btn-sm" onClick={this.deleteEntry.bind(this, i)}> <i className="fas fa-trash"></i> </Button> */}
            {/* </td> */}
            <td>
<button type="button"  variant="success" onClick={e =>{this.toPrint(row.id, this.state.quantity[i])} }  className="btn btn-sm btn-info active"> <i className="fas fa-barcode fa-lg"></i ></button>
            </td>
            </tr>
          
          ))
      }
      <tr className="bg-white"><td colSpan='5'></td>
      <td colSpan='5'>

      {
              this.state.charges_selected.length>0 && this.state.charges_selected.map((row1,j) => (
        <Row className="mb-2" key={j}>
      <Form.Group as={Col} md="5">
      <select key={'a'+j} className="form-select font-13 charges_option"   name="charges_selected"  value={this.state.charges_selected[j]}
      onChange={this.chargeTypesSelectboxChange.bind(this, j)} >
            <option value=''>Select Charges</option>
            {
              this.state.charges.length>0 && this.state.charges.map((row,i) => (
                <option charge_type={row.charge_type} key={i} value={row.id}>{row.name}</option>
                ))
            }
          </select>
        </Form.Group>

        <Form.Group as={Col} md="5">
        <Form.Control 
            key={'b'+j}
            type="number" 
            name="charge_amount"
            step="any"
            min="0"
            value={this.state.charge_amount[j]} 
            onChange={e => { this.chargesChange(j, e);}}
            />      
      </Form.Group>
      <Form.Group as={Col} md="1">
        {j == 0 && this.state.charges.length > this.state.charges_selected.length && <span onClick={this.addMoreCharges}><i className="text-success fas fa-plus"></i ></span>}
        {j > 0 && <span onClick={this.removeCharges.bind(this, j)}><i className="text-danger fas fa-trash"></i ></span>}
        </Form.Group>

        </Row>
        ))}

      <Row>

      <Form.Group as={Col} md="5">
      <Form.Label>Total Amount to pay</Form.Label>
      </Form.Group>
      <Form.Group as={Col} md="6">
          <InputGroup className="mb-2">
          <Form.Control 
            type="number" 
            name="total_payment_amount"
            readOnly
            value={this.state.overall_total_price} 
            onChange={this.validateInput}
            min="1"
            />
            <InputGroup.Text>{this.state.currency}</InputGroup.Text>
            </InputGroup>
        </Form.Group>
        </Row>
        {
        this.state.company_data.partial_purchase_amount == 1  && 
        <Row>
        {/* <Form.Group as={Col} md="2"></Form.Group> */}
      <Form.Group as={Col} md="5">
      <Form.Label>Paying Amount</Form.Label>
      </Form.Group>
      <Form.Group as={Col} md="6">
          <InputGroup className="mb-2">
          <Form.Control 
            type="number" 
            name="paying_amount"
            value={this.state.paying_amount} 
            onChange={this.validateInput}
            />
            <InputGroup.Text>{this.state.currency}</InputGroup.Text>
            </InputGroup>
        </Form.Group>
        </Row>
        }

        <Row>
      {/* <Form.Group as={Col} md="2"></Form.Group> */}
      <Form.Group as={Col} md="5">
      <Form.Label>Payment Mode</Form.Label>
      </Form.Group>
      <Form.Group as={Col} md="6">
          <select className="form-select" required  name="payment_mode_val"  value={this.state.payment_mode_val}
          
          onChange={this.validateInput}>
            {
              payment_mode.length>0 && payment_mode.map((row,i) => (
                <option key={i} value={row.id}>{row.name}</option>
                ))
            }
          </select>
          <Form.Control.Feedback type="invalid">
            {this.errors.payment_mode ? this.errors.payment_mode : 'Please provide product name'}
          </Form.Control.Feedback>
        </Form.Group>
        </Row>

        <Row>
        <Form.Group as={Col} md="5">
          </Form.Group>
        <Form.Group as={Col} md="7">
        <Button type="submit" className="btn-icon"> <i className="fas fa-save"></i > Update Purchase</Button>
        </Form.Group>
        </Row>
      </td></tr>
      </tbody>
      </table>
}

</Form>
    </div>
  );
}
}

export default Purchase;