import React from "react";
import CommonFunctions from "../commonFunctions.js";
import Tablehead from "../TableheadWithDate";
import Breadcrumbs from "../Breadcrumbs";
import Pagination from "../Pagination";
import { Link } from "react-router-dom";
import {storeResult} from '../../redux';

class Purchase extends CommonFunctions {
  constructor(props) {
    super(props)
    this.getData  = this.getData.bind(this);    
    this.state = {
      records: [],
      axios_responded:0,
      breadcrum_params: [],
      records_cnt: '',
      radioboxes: [],
      pageNum:'',
      model_url:'purchase'
  };
  this.toPrint  = this.toPrint.bind(this);

    storeResult.subscribe(() => {
        this.setState({
          records: storeResult.getState()[0].data.data,
          records_cnt: storeResult.getState()[0].data.total,
          radioboxes: storeResult.getState()[1],
          pageNum: storeResult.getState()[2],
          axios_responded : 1
        });
    });
  }

  
  toPrint(invoice_no){
    var myWindow=window.open('../print-bar-code-all/'+invoice_no,'','width=600,height=800');
    myWindow.focus();
  }

 componentDidMount() {

    var newBreadcrum = this.state.breadcrum_params.slice();
    // newBreadcrum.push([1,'../brands', 'Brands']);
    newBreadcrum.push([0, 0, 'Purchases']);
    this.setState({breadcrum_params: newBreadcrum});  
    let url = "purchase";
    let params = {};
    this.getData(url, params)
  }

  render() {
    const { records } = this.state;
    const {axios_responded} = this.state;
    const {breadcrum_params} = this.state;
    const {records_cnt} = this.state;
    
    return (
      <div>
      <Breadcrumbs params = {breadcrum_params}/> 
      <Tablehead header1 = "Purchase List" link="/purchase/add" model_url={this.state.model_url} />

      {axios_responded == 1 && 
        <div>
        <table className='table styled-table'>
        <thead>
        <tr><th>S.No</th><th>Invoice No</th><th>Products</th><th>Vendor</th><th className="text-end">Total</th><th className="text-end">Paid</th>
        <th className="text-end">Balance</th>
        <th>Status</th><th>Date</th><th width='12%'>Actions</th></tr>
        </thead>
        <tbody>
        
        {records && records.length == 0 && <tr><td colSpan='15' className="text-center text-danger"><div className="text-center p-2 alert-warning">No Records Found !</div></td></tr> }

        {records.length>0 && records.map((row,i) => (
        <tr key={i}>
          <td>{(this.state.pageNum+i)}</td>
          <td>{row.invoice_no}</td>
          <td>{row.products.substring(0, 50)}
          {row.products.length>=50 &&
              <a className="tooltip1" href="#"> ...Show More
                  <span>{row.products}
                  </span>
              </a>
          }
          </td>
          <td>{row.vendor}</td>
          <td className="text-end">{row.total_amount}</td>
          <td className="text-end">{row.total_paid}</td>
          <td className="text-end">
            <Link to={"/balances/"+row.invoice_no}>
            {row.pending_amount}
            </Link>
          
          </td>
          <td>
            {row.pending_amount<=0 ? <span className="badge badge-success">Paid</span> : <span className="badge badge-warning">Pending</span>}
          </td>
            <td>{row.created_at}</td>
          <td>
          <Link to={"/purchase/"+row.invoice_no}>
          <button type="button" className="btn btn-sm btn-info active"> <i className="fas fa-edit"></i > Edit</button>
              </Link>
            
              &nbsp;<Link  title='Return' to={"/purchase-return/"+row.invoice_no}>
              <button type="button" variant="success" className="btn btn-sm btn-primary"><i className="fas fa-undo"></i></button>
              </Link>
              
          {/* <button type="button"  variant="success" onClick={e =>{this.toPrint(row.invoice_no)} }  
          className="btn btn-sm btn-info active"> <i className="fas fa-barcode fa-lg"></i ></button> */}
          
          </td>
        </tr>
         ))}
        </tbody>

        <tfoot>
          <tr>
          <th colSpan={4} className="text-end">TOTAL : </th>
          <th className="text-end">
          {this.CurrencyFormatted(records.reduce((total, record) => total + parseFloat(record.total_amount), 0))}
          </th>
          <th className="text-end">
          {this.CurrencyFormatted(records.reduce((total, record) => total + parseFloat(record.total_paid), 0))}
          </th>
          <th className="text-end">
          {this.CurrencyFormatted(records.reduce((total, record) => total + parseFloat(record.pending_amount), 0))}
          </th>
          <th colSpan={3}></th>
          </tr>
        </tfoot>

      </table>
      {records && records.length>0 && <Pagination page_cnt={records_cnt} model_url={this.state.model_url} />}
      </div>
      }
      </div>
  );
}
}

export default Purchase;