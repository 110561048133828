import React from "react";
import CommonFunctions from "../commonFunctions.js";
import { Form, Button, FormGroup,Col,Row,InputGroup, FormControl, ControlLabel } from "react-bootstrap";
import { Link } from "react-router-dom";
import Breadcrumbs from "../Breadcrumbs";
import Select from 'react-select';
import {NotificationManager} from 'react-notifications';

class Purchase extends CommonFunctions {
  constructor(props) {
    super(props)
    this.state = {
      validated: 0,
      breadcrum_params: [],
      products : [],
      vendors:[],
      active_taxes:[],
      added_products : [],
      total_purchases_added: [],
      current_index:-1,
      quantity :[],
      purchase_price :[],
      selling_price :[],
      discount_price:[],
      margin_price :'',
      tax_amount :'',
      total_amount :'',
      currency:'',
      vname:'',
      product_names:[],
      product_names2:[],
      payment_mode:[],
      company_branches:[],
      location_details:[],
      payment_mode_val:'',
      total_tax:'',
      total_price:[],
      taxs:[],
      overall_total_price:0,
      paying_amount:'',
      balance_id:'',
      company_data:[],
      products_list1:[],
      charges:[],
      charges_selected:[],
      charge_amount:[],
        amount_error: [],
        return_reason: [],
        return_qty:[],
    }
    this.errors = [];
    this.handleSubmit  = this.handleSubmit.bind(this);
    // this.toPrint  = this.toPrint.bind(this);
  }
  

  toPrint(purchase_id, quantity){
    var myWindow=window.open('../print-bar-code/'+purchase_id+'/'+quantity,'','width=600,height=800');
    myWindow.focus();
  }

  componentDidMount() {
    var newBreadcrum = this.state.breadcrum_params.slice();
    newBreadcrum.push([1,'../purchases', 'Purchase']);
    newBreadcrum.push([0, 0, 'Purchase Return']);
    this.setState({breadcrum_params: newBreadcrum});  
    var invoice_no = this.props.match.params.invoice_no
    this.apiCall('purchase/'+invoice_no, '', '', 'GET','').then(res => {

      var balances = res.data.balances[0];
      var charge_amount = res.data.charge_amount
      var charges_selected = res.data.charge_mapped_id
      this.setState({products: res.data.products, vendors: res.data.vendors, currency: res.data.currency, 
        payment_mode: res.data.payment_mode, company_branches:res.data.company_branches, company_data:res.data.company,
        charges:res.data.charges,
        charges_selected: res.data.charge_mapped_id,
        charge_amount:res.data.charge_amount
      }); 
        
        var products_list1 = []
      res.data.products.map(function(data, i){   
        products_list1.push({label: data.name, value: data.id});
      })
      // console.log(products_list1)
      this.setState({products_list1: products_list1})

        let taxs1 = []
        for(var j=0;j<res.data.tax.length;j++)
        {
          var row = res.data.tax[j]
          taxs1[row.tax_id] = []
          taxs1[row.tax_id]['name'] = row.name
        }
        this.setState({ active_taxes:taxs1 })

        let new_purchases = [...this.state.total_purchases_added]
        var len = res.data.records.length

        let taxs = [...this.state.taxs]
        let new_location = [...this.state.location_details]
        let new_product = [...this.state.product_names]
        let new_product2 = [...this.state.product_names2]
        let quantity = [...this.state.quantity]
        let purchase_price = [...this.state.purchase_price]
        let selling_price = [...this.state.selling_price]
        let discount_price = [...this.state.discount_price]
        let total_price = [...this.state.total_price]
        var vname = ''
        for(var i=0;i<len;i++)
        {
          var item = res.data.records[i]
          vname = item.vendor_id
          var data = {id: item.id,product_names: item.product_id, quantity:item.quantity, purchase_price: item.purchase_price, 
            selling_price:item.selling_price, stock_id: item.stock_id,
            discount_price:item.discount_price, 
            margin_price:item.margin_price, location_details:item.branch_id, total_tax: item.tax_amount, total_price: item.total_amount}
    
          new_purchases[i] = data
          taxs[i] = []
          for(var j=0;j<res.data.tax.length;j++)
          {
            var row = res.data.tax[j]
            // console.log(row)
            if(item.id == row.order_id)
            taxs[i][row.tax_id] = row.percentage
          }

          new_location[i] = item.branch_id
          new_product[i] = item.product_id
          new_product2[i] = item.product_name
          quantity[i] = item.quantity
          purchase_price[i] = item.purchase_price
          selling_price[i] = item.selling_price
          discount_price[i] = item.discount_price
          total_price[i] = item.total_amount

        }
        this.setState({ taxs: taxs})
        this.setState({ total_purchases_added:new_purchases, current_index: -1, product_names: new_product,product_names2: new_product2, vname:vname, 
          location_details:new_location,
        quantity:quantity, purchase_price:purchase_price, selling_price:selling_price,
        discount_price:discount_price,
        margin_price : '', total_price:total_price});
        this.setState({ payment_mode_val:balances.payment_mode_id, paying_amount:balances.total_balance_paid, balance_id:balances.id })

        var overall_total_price = 0;
        this.state.total_purchases_added.map((row,i) => (
          overall_total_price+= parseFloat(row.total_price || 0)
        ));

        charges_selected.map((charge_id,i) => {
          var charge_type = ''; //+ or --
          var amount = charge_amount[i]
          if( charge_id )
          {
    
            this.state.charges.length>0 && this.state.charges.map((row,k) => {
              if(row.id == charge_id)
              {
                charge_type = row.charge_type
              }
            })
            if( charge_type == '-')
            {
                overall_total_price-= parseFloat(amount|| 0);
            }
            else
            {
                overall_total_price+= parseFloat(amount|| 0);
            }
          }
    
        });
        this.setState({overall_total_price: overall_total_price});//, paying_amount:overall_total_price
        
    })

  }

  handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();    
    this.setState({ validated: this.state.validated + 1 })

    if(form.checkValidity() === true)
    {
       const params = {
        return_qty: this.state.return_qty,
        return_reason:this.state.return_reason,
         records: this.state.total_purchases_added,
        invoice_id:this.props.match.params.invoice_no,
      }
      this.apiCall("./purchase/return", params, "../purchases-return", "POST", "Purchase return updated")
    }
}
    
    validateInputArray4 = (i, id, name, e) => {
        let new_state_value = [...this.state[name]];
        new_state_value[i] = []
        new_state_value[i] = e.target.value;
        this.setState({
          [name]: new_state_value
        });
        console.log(new_state_value)
        this.errors[name] = "";
    };
    
  render() {
    const {breadcrum_params} = this.state;
    const {products} = this.state;
    const {vendors} = this.state;
    const {active_taxes} = this.state;
    const {total_purchases_added} = this.state;
    const {payment_mode} = this.state;
    const {company_branches} = this.state;
    
    return (
      <div>
      <Breadcrumbs params = {breadcrum_params}/> 

      <div style={{fontSize:'13px'}}>
        <span>Bill No : <b>{this.props.match.params.invoice_no}</b></span> &nbsp;
        {/* <span>Date : <b>{this.state.records.created_at}</b></span> */}
            </div>
            
    <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
      {
        this.state.current_index > -1 && <Button type="button" className="btn-icon"  onClick={this.updateEntry.bind(this)}>Save <i className="fas fa-save"></i ></Button>
      }
 
{/* Items list stats */}

{
      total_purchases_added.length>0 && 
      <table className='table table-line-height styled-table'>
        <thead><tr><th>S.No</th>
        <th width='20%'>Product Name</th><th>Quantity</th>
        
                                <th>Purchase Rate</th>
                                <th>Return Qty</th>
                                <th>Return Reason</th>
        </tr></thead>
        <tbody>
      {
          total_purchases_added.map((row,i) => (
          <tr key={i}>
            <td>{i+1}</td>
            <td>{this.state.product_names2[i]}
            </td>
            <td>
           {this.state.quantity[i]}
            </td>
            <td>
            {this.state.purchase_price[i]} 
                  </td>
                  <td className="text-center">
              <select name='return_qty'  className='form-select' onChange={e => { this.validateInputArray4(i, row.id, "return_qty", e); }} >
              <option value='0'>Select</option>
              {this.state.quantity[i] > 0 && Array(this.state.quantity[i]).fill().map((row2, k) => (
                <option value={k+1} key={k+1}>{k+1}</option>
              ))
                }
                </select>
            </td>
            <td className="text-end">
              
              <Form.Group>
          <Form.Control 
                    key={'k_' + i}
                    type="text"
                    name="return_reason"
                    onChange={e => { this.validateInputArray4(i, row.id, "return_reason", e); }}
              />      
                </Form.Group>
                  </td>
                 
            </tr>
          
          ))
      }
      <tr className="bg-white"><td colSpan='5'></td>
      <td colSpan='5'>
        <Row>
        <Form.Group as={Col} md="5">
          </Form.Group>
        <Form.Group as={Col} md="7">
        <Button type="submit" className="btn-icon"> <i className="fas fa-save"></i > Save Return</Button>
        </Form.Group>
        </Row>
      </td></tr>
      </tbody>
      </table>
}

</Form>
    </div>
  );
}
}

export default Purchase;