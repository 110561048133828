import React from "react";
import CommonFunctions from "../commonFunctions.js";
import Tablehead from "../Tablehead";
import Breadcrumbs from "../Breadcrumbs";
import Pagination from "../Pagination";
import { Link } from "react-router-dom";
import {storeResult} from '../../redux';

class Expense extends CommonFunctions {
  constructor(props) {
    super(props)
    this.getData  = this.getData.bind(this);    
    this.state = {
      records: [],
      axios_responded:0,
      breadcrum_params: [],
      records_cnt: '',
      radioboxes: [],
      pageNum:'',
      model_url:'report/stocks_report'
  };

    storeResult.subscribe(() => {
        this.setState({
          records: storeResult.getState()[0].data.data,
          records_cnt: storeResult.getState()[0].data.total,
          radioboxes: storeResult.getState()[1],
          pageNum: storeResult.getState()[2],
          axios_responded : 1
        });
    });
  }

 componentDidMount() {

    var newBreadcrum = this.state.breadcrum_params.slice();
    // newBreadcrum.push([1,'../brands', 'Brands']);
    newBreadcrum.push([0, 0, 'Stocks Report']);
    this.setState({breadcrum_params: newBreadcrum});  
    let url = "report/stocks_report";
    let params = {};
    this.getData(url, params)
  }

  render() {
    const { records } = this.state;
    const {axios_responded} = this.state;
    const {breadcrum_params} = this.state;
    const {records_cnt} = this.state;
    
    return (
      <div>
      <Breadcrumbs params = {breadcrum_params}/> 
      <Tablehead header1 = "Stocks Report" link="/expense/add" model_url={this.state.model_url} />

      {axios_responded == 1 && 
        <div className='table-responsive'>
        <table className='table styled-table'>
        <thead>
        <tr><th>S.No</th><th>Brand</th><th>Category</th><th>Product Name</th><th className="text-end">Purchased Items</th><th className="text-end">Sold Items</th>
        <th className="text-end">Available Items</th><th className="text-end">Price</th><th>Created On</th></tr>
        </thead>
        <tbody>
        
        {records && records.length == 0 && <tr><td colSpan='9' className="text-center text-danger"><div className="text-center p-2 alert-warning">No Records Found !</div></td></tr> }

        {records.length>0 && records.map((row,i) => (
        <tr key={i}>
          <td>{(this.state.pageNum+i)}</td>
          <td>{row.bname}</td>
          <td>{row.cname}</td>
          <td>{row.name}</td>
          <td className="text-end">{row.added_quantity}</td>
          <td className="text-end">{row.added_quantity - row.current_quantity}</td>
          <td className="text-end">{row.current_quantity}</td>
          <td className="text-end">{row.price}</td>
          <td>{row.created_at}</td>
        </tr>
         ))}
        </tbody>

        <tfoot>
          <tr>
          <th colSpan={4} className="text-end">TOTAL : </th>
          <th className="text-end">
          {this.CurrencyFormatted(records.reduce((total, record) => total + parseFloat(record.added_quantity), 0))}
          </th>
          <th className="text-end">
          {
          this.CurrencyFormatted(records.reduce((total, record) => total + parseFloat(record.added_quantity), 0)) - 
          this.CurrencyFormatted(records.reduce((total, record) => total + parseFloat(record.current_quantity), 0))
          }
          </th>

          <th className="text-end">
          {this.CurrencyFormatted(records.reduce((total, record) => total + parseFloat(record.current_quantity), 0))}
          </th>
          <th className="text-end">
          {this.CurrencyFormatted(records.reduce((total, record) => total + parseFloat(record.price), 0))}
          </th>
          <th></th>
          </tr>
        </tfoot>

      </table>
      {records && records.length>0 && <Pagination page_cnt={records_cnt} model_url={this.state.model_url} />}
      </div>
      }
      </div>
  );
}
}

export default Expense;