import React from "react";
import CommonFunctions from "../commonFunctions.js";
import Barcode from 'react-barcode';

class Print extends CommonFunctions {
  constructor(props) {
    super(props)
    this.state = {
      records: [],
      products: [],
      currency: '',
      company_name:'',
  };
  }

 componentDidMount() {

  var obj = this
  this.apiCall('barcode/'+this.props.match.params.invoice_no+'?type=invoice', '', '', 'GET','').then(res => {

    this.setState({products: res.data.product, currency : res.data.currency, company_name : res.data.company_name}); 

    const url = "barcode/1?type=invoice";
    const params = {invoice_no: this.props.match.params.invoice_no}
    obj.apiCall(url, params, "", "PUT", "").then(res => {
          var content = document.getElementById("card-body").innerHTML
          var print_data = content
          document.getElementById("root").innerHTML = print_data
          window.print();
          window.close();
    })

    })

  }

  render() {
    const { records } = this.state;    

    return (
      <div className="bar_code_container">   
        {
            this.state.products.length>0 && this.state.products.map((row,j) => (
              Array(row.quantity).fill(1).map((el, i) =>
                <div className="print_barcode_label" key={i+1}>
                  <div className="barcode_company_name">{this.state.company_name}</div>
                <Barcode 
                  value={row.id+"P"+(i+1)} 
                  width='1' 
                  format="CODE128"
                  displayValue = {false}
                  marginTop = '-2'
                  height='25'
                />
                <div className="barcode_texts" dangerouslySetInnerHTML={{ __html: row.id+"P"+(i+1) +"<br />"+ row.name+"<br />"+row.selling_price}} />
                </div>
            )
            )
            )
      }
      </div>
  );
}
}

export default Print;