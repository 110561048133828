import React from "react";
import CommonFunctions from "../commonFunctions.js";
import { Form, Button, FormGroup,Col,Row,InputGroup, FormControl, ControlLabel } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from '../../logo.png'
import { right } from "@popperjs/core";

class Brand extends CommonFunctions {
  constructor(props) {
    super(props)
    this.state = {
      validated: 0,
      breadcrum_params: [],
      email :'',
      password: '',
      password_type:'password',
      password_icon:'fa fa-eye'
    }
    this.errors = [];
    this.handleSubmit  = this.handleSubmit.bind(this);
    this.handlePassword  = this.handlePassword.bind(this);
    
  }
  
  handlePassword =() => {
    var password_type = (this.state.password_type == "text") ? "password" : "text";
    var password_icon = (this.state.password_icon == "fa fa-eye") ? "fa fa-eye-slash" : "fa fa-eye";
    this.setState({ password_type: password_type, password_icon: password_icon })
  }
  handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();    
    this.setState({ validated: this.state.validated + 1 })
    
    if(form.checkValidity() === true)
    {
      const url = "user/login";
      const params = {email: this.state.email, password: this.state.password}
      this.apiCall(url, params, "", "POST", "Login Success")
    }
  }

  render() {
    return (
      <div className="login_elements">

    <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>

      <h4  style={{textAlign: 'center', fontWeight:'bold'}}>Sign In</h4>
      <Row className="mb-3">
        <Form.Group as={Col} md="12" controlId="id01">
          <Form.Label>Email Address<span className="text-danger"> *</span></Form.Label>
          <Form.Control 
            type="email" 
            name="email"
            placeholder="Enter email" 
            required
            value={this.state.email} 
            onChange={this.validateInput}
            isInvalid={this.errors.email}
            autoFocus
            />
          <Form.Control.Feedback type="invalid">
            {this.errors.email ? this.errors.email : 'Please provide valid email address'}
          </Form.Control.Feedback>
        </Form.Group>
        </Row>

        <Row className="mb-3">
        <Form.Group as={Col} md="12" controlId="id02">
          <Form.Label>Password<span className="text-danger"> *</span></Form.Label>
          <Form.Control 
            type={this.state.password_type} 
            name="password"
            placeholder="Enter password" 
            required
            value={this.state.password} 
            onChange={this.validateInput}
            isInvalid={this.errors.password}
            />
            {this.state.password_icon == "fa fa-eye" && 
            <span className="password-eye" onClick={this.handlePassword}><i className={this.state.password_icon}></i></span>
            }
            {this.state.password_icon == "fa fa-eye-slash" && 
            <span className="password-eye" onClick={this.handlePassword}><i className={this.state.password_icon}></i></span>
            }
          <Form.Control.Feedback type="invalid">
            {this.errors.password ? this.errors.password : 'Please provide password'}
          </Form.Control.Feedback>
        </Form.Group>
        </Row>

        <div className="row">
          <div className="col-6">            
            {/* <input type='checkbox' className="form-check-input" name='remember_me' />&nbsp;
            <label>Remember Me</label> */}
          </div>

          <div className="col-6 text_align_right">            
          <Link to={"/forgotpassword"} className="text_align_right">
                <span>Forgot Password</span>
              </Link>
          </div>
        </div><br />

      <div  style={{textAlign: 'center'}}>
      <Button type="submit">Submit</Button>
      </div>

    </Form>
    </div>
  );
}
}

export default Brand;