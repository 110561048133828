import React from "react";
import { Form, Button, FormGroup,Col,Row,InputGroup, FormControl, ControlLabel } from "react-bootstrap";
import CommonFunctions from "../commonFunctions.js";
import Breadcrumbs from "../Breadcrumbs";

class Return extends CommonFunctions {
  constructor(props) {
    super(props)
    this.state = {
      records: [],
      currency: '',
      breadcrum_params: [],
      return_reason: [],
      return_qty:[],
      details: '',
    };
    this.errors = [];
    this.handleSubmit  = this.handleSubmit.bind(this);
  }

  handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();    
    this.setState({ validated: this.state.validated + 1 })

    if(form.checkValidity() === true)
    {
       const params = {
        return_qty: this.state.return_qty,
        return_reason:this.state.return_reason,
         records: this.state.records,
        invoice_id:this.props.match.params.invoice_no,
      }
      this.apiCall("./sales/return", params, "../sales", "POST", "Sale return updated")
    }
  }

 componentDidMount() {

  var newBreadcrum = this.state.breadcrum_params.slice();
  // newBreadcrum.push([1,'../brands', 'Brands']);
   newBreadcrum.push([1,'../sales', 'Sales']);
   newBreadcrum.push([0, 0, 'Sales Return']);
   this.setState({ breadcrum_params: newBreadcrum });  
   
    let url = "sales/"+this.props.match.params.invoice_no;
    let params = {};
    this.apiCall(url, '', '', 'GET','').then(res => {
        // console.log(res.data.data.length)
        this.setState({records: res.data.data, currency: res.data.currency, details: res.data.details}); 
      })
  }

  
  validateInputArray4 = (i, id, name, e) => {
    let new_state_value = [...this.state[name]];
    new_state_value[i] = []
    new_state_value[i] = e.target.value;
    this.setState({
      [name]: new_state_value
    });
    console.log(new_state_value)
    this.errors[name] = "";
  };

  render() {
    const { records } = this.state; 
    const {breadcrum_params} = this.state;
    return (
      <div>
        <Breadcrumbs params = {breadcrum_params}/> 
        {records.length>0 && 
          <div>
            <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
        <div style={{fontSize:'13px'}}>
        <span>Bill No : <b>{this.props.match.params.invoice_no}</b></span> &nbsp;
        <span>Date : <b>{this.state.details.created_at}</b></span>
        </div>

        <div style={{fontSize:'13px', borderBottom:'1px solid #ccc'}}>
        <span>Customer Name : <b>{this.state.details.customer_name}</b></span>
        </div>

        <table className='table styled-table'>
        <thead>
        <tr><th>S.No</th><th>ITEM</th>
        {records.reduce((total, record) => total + parseFloat(record.price), 0) - records.reduce((total, record) => total + parseFloat(record.our_price), 0) !=0 && 
        <th className="text-end">MRP</th>
        }
        
                  <th className="text-end">RATE</th><th className="text-center">QTY</th><th className="text-end">AMOUNT</th><th className="text-center">RETURN QTY</th>
                  <th className="text-center">REASON</th></tr>
        </thead>
        <tbody>
        {records.map((row,i) => (
        <tr key={i}>
          {/* <td>{(this.state.pageNum+i)}</td> */}
          <td>{i+1}</td>
          <td  width='35%'>{row.name}</td>
          <td className="text-end">{(row.price)}</td>
          
          {records.reduce((total, record) => total + parseFloat(record.price), 0) - records.reduce((total, record) => total + parseFloat(record.our_price), 0) != 0 && 
            <td className="text-end">{(row.our_price)}</td>
            }

            <td className="text-center">{row.quantity}</td>
            <td className="text-end">{row.total}</td>
            <td className="text-center">
              <select name='return_qty'  className='form-select' onChange={e => { this.validateInputArray4(i, row.id, "return_qty", e); }} >
              <option value='0'>Select</option>
              {row.quantity > 0 && Array(row.quantity).fill().map((row2, k) => (
                <option value={k+1} key={k+1}>{k+1}</option>
              ))
                }
                </select>
            </td>
            <td className="text-end">
              
            <Form.Group>
        <Form.Control 
                  key={'k_' + i}
                  type="text"
                  name="return_reason"
                  onChange={e => { this.validateInputArray4(i, row.id, "return_reason", e); }}
            />      
              </Form.Group>
              </td>
        </tr>
        ))}

      <tr style={{fontSize:'13px', borderBottom:'1px solid #ccc'}}>
       <td></td><td> <b>TOTAL</b> </td>
       <td className="text-end"></td>
       {records.reduce((total, record) => total + parseFloat(record.price), 0) - records.reduce((total, record) => total + parseFloat(record.our_price), 0) != 0 && 
        <td></td>}
       {/* {records.reduce((total, record) => total + parseFloat(record.price), 0) - records.reduce((total, record) => total + parseFloat(record.our_price), 0) != 0 && 
        <td className="text-end"><b>{records.reduce((total, record) => total + parseFloat(record.our_price), 0)}</b></td>
        } */}

       <td className="text-center"><b>{records.reduce((total, record) => total + parseFloat(record.quantity), 0)}</b></td>
                    <td className="text-end"><b> {records.reduce((total, record) => total + parseFloat(record.total), 0).toFixed(2)}</b></td>
                    <td colSpan={2}></td>
       </tr>

       {this.state.details.charges_selected.length>0 && this.state.details.charges_selected.map((row1,j) => (
        <tr key={j}>
      {records.reduce((total, record) => total + parseFloat(record.price), 0) - records.reduce((total, record) => total + parseFloat(record.our_price), 0) != 0 && 
      <td></td>}
      <td colSpan={4}  className="text-end">
        {
          this.state.details.charges.length>0 && this.state.details.charges.map((row,i) => {
              
                if(row1 == row.id)
                  return (<span key={i}>{row.name}</span>)
              
              })
        }
      </td>

           <td className="text-end"> {this.state.details.charge_amount[j].toFixed(2)}</td>
           <td colSpan={2}></td>
       </tr>
      ))}

      
     <tr>
     {records.reduce((total, record) => total + parseFloat(record.price), 0) - records.reduce((total, record) => total + parseFloat(record.our_price), 0) != 0 && 
      <td></td>}
     {this.state.details.discount_amount>0 && 
     <td  colSpan={4}  className="text-end"><b>Discount Amount</b></td>
     }
     {this.state.details.discount_amount>0 && 
     <td className="text-end"><b>{parseFloat(this.state.details.discount_amount).toFixed(2)}</b></td>
      }
                    <td colSpan={2}></td>
     </tr>

     <tr>
     {records.reduce((total, record) => total + parseFloat(record.price), 0) - records.reduce((total, record) => total + parseFloat(record.our_price), 0) != 0 && 
        <td></td>
     }

       <td  colSpan={4}  className="text-end"><b>Amount to pay</b></td><td className="text-end">
       <b> {this.state.currency}
       {/* {(records.reduce((total, record) => total + parseFloat(record.total), 0) - this.state.discount_amount).toFixed(2)} */}
       {parseFloat(this.state.details.invoice_amount - this.state.details.discount_amount).toFixed(2)}
                      </b></td><td colSpan={2}></td></tr>
                  <tr><td colSpan={8} align='right'><Form.Group as={Col} md="7">
        <Button type="submit" className="btn-icon"> <i className="fas fa-save"></i > Save Return</Button>
        </Form.Group></td></tr>
        </tbody>
        </table>
            
      <div style={{fontSize:'13px', clear:'both', marginTop:'-14px', marginBottom:'10px'}}>
        <span><b style={{color:'red'}}>Amount can not be refundable(Invoice can not be modified). You can sale alternate product to the user.</b> </span>
      </div>
      </Form>
      </div>
      }
      </div>
  );
}
}

export default Return;