import React, { Component } from "react";
import ContentArea from "./contentarea";
import { storeMenu } from "../redux";
import { Link } from "react-router-dom";
export default class Aside extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu_status: 0,
    };

    storeMenu.subscribe(() => {
      this.setState({
        menu_status: storeMenu.getState(),
      });
    });
  }

  render() {
    return (
      <div id="layoutSidenav">
        {JSON.parse(localStorage.getItem("data")).role_id != 3 && (
          <div id="layoutSidenav_nav">
            <nav
              className="sb-sidenav accordion sb-sidenav-dark"
              id="sidenavAccordion"
            >
              <div className="sb-sidenav-menu">
                <div className="nav">
                  {/* <div className="sb-sidenav-menu-heading">Core</div> */}
                  <br />
                  <Link className="nav-link" to={"/dashboard"}>
                    <div className="sb-nav-link-icon">
                      <i className="menu_icons menu5 fa fa-home"></i>
                    </div>
                    Dashboard
                  </Link>
                  <a
                    className="nav-link collapsed"
                    href="#"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseLayouts"
                    aria-expanded="false"
                    aria-controls="collapseLayouts"
                  >
                    <div className="sb-nav-link-icon">
                      <i className="menu_icons menu5 fas fa-columns"></i>
                    </div>
                    Masters
                    <div className="sb-sidenav-collapse-arrow">
                      <i className="fas fa-angle-down"></i>
                    </div>
                  </a>
                  <div
                    className="collapse"
                    id="collapseLayouts"
                    aria-labelledby="headingOne"
                    data-bs-parent="#sidenavAccordion"
                  >
                    <nav className="sb-sidenav-menu-nested nav">
                      <Link className="nav-link" to={"/main-categories"}>
                        <div className="sb-nav-link-icon">
                          <i className="menu_icons menu5 fas fa-copyright fa-sm"></i>
                        </div>
                        Main Categories
                      </Link>
                      <Link className="nav-link" to={"/categories"}>
                        <div className="sb-nav-link-icon">
                          <i className="menu_icons menu5 fas fa-copyright fa-sm"></i>
                        </div>
                        Categories
                      </Link>
                      <Link className="nav-link" to={"/brands"}>
                        <div className="sb-nav-link-icon">
                          <i className="menu_icons menu5 fas fa-bold"></i>
                        </div>
                        Brands
                      </Link>
                      <Link className="nav-link" to={"/products"}>
                        <div className="sb-nav-link-icon">
                          <i className="menu_icons menu5 fab fa-product-hunt fa-sm"></i>
                        </div>
                        Products
                      </Link>
                    </nav>
                  </div>
                  <a
                    className="nav-link collapsed"
                    href="#"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapsePages"
                    aria-expanded="false"
                    aria-controls="collapsePages"
                  >
                    <div className="sb-nav-link-icon">
                      <i className="menu_icons menu5 fas fa-cog"></i>
                    </div>
                    Settings
                    <div className="sb-sidenav-collapse-arrow">
                      <i className="fas fa-angle-down"></i>
                    </div>
                  </a>
                  <div
                    className="collapse"
                    id="collapsePages"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#sidenavAccordion"
                  >
                    <nav className="sb-sidenav-menu-nested nav">
                      <Link className="nav-link" to={"/configurations"}>
                        <div className="sb-nav-link-icon">
                          <i className="menu_icons menu5 fas fa-cog"></i>
                        </div>
                        Config
                      </Link>
                      <Link className="nav-link" to={"/currencies"}>
                        <div className="sb-nav-link-icon">
                          <i className="menu_icons menu5 fas fa-dollar-sign"></i>
                        </div>
                        Currencies
                      </Link>
                      <Link className="nav-link" to={"/paymentmodes"}>
                        <div className="sb-nav-link-icon">
                          <i className="menu_icons menu5 fas fa-money-check-alt"></i>
                        </div>
                        Payment Modes
                      </Link>
                      <Link className="nav-link" to={"/taxes"}>
                        <div className="sb-nav-link-icon">
                          <i className="menu_icons menu5 fas fa-text-width fa-sm"></i>
                        </div>
                        Taxes
                      </Link>
                    </nav>
                  </div>

                  <Link className="nav-link" to={"/customers"}>
                    <div className="sb-nav-link-icon">
                      <i className="menu_icons menu5 fas fa-users"></i>
                    </div>
                    Customers
                  </Link>
                  <Link className="nav-link" to={"/vendors"}>
                    <div className="sb-nav-link-icon">
                      <i className="menu_icons menu5 fas fa-users-cog"></i>
                    </div>
                    Vendors
                  </Link>
                  <Link className="nav-link" to={"/users"}>
                    <div className="sb-nav-link-icon">
                      <i className="menu_icons menu5 fas fa-user-friends"></i>
                    </div>
                    Users
                  </Link>
                  <Link className="nav-link" to={"/expenses"}>
                    <div className="sb-nav-link-icon">
                      <i className="menu_icons menu5 fas fa-money-bill"></i>
                    </div>
                    Expenses
                  </Link>

                  <Link className="nav-link" to={"/report/stocks"}>
                    <div className="sb-nav-link-icon">
                      <i className="menu_icons fas menu5 fa-layer-group"></i>
                    </div>
                    Stocks
                  </Link>

                  
                  <a className="nav-link collapsed"
                  href="#"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsePurchase"
                  aria-expanded="false"
                  aria-controls="collapsePurchase"
                  >
                  <div className="sb-nav-link-icon">
                    <i className="menu_icons menu5 fas fa-list"></i>
                  </div>
                  Purchase
                  <div className="sb-sidenav-collapse-arrow">
                    <i className="fas fa-angle-down"></i>
                  </div>
                  </a>
                  <div
                  className="collapse"
                  id="collapsePurchase"
                  aria-labelledby="headingOne"
                  data-bs-parent="#sidenavAccordion"
                  >
                  <nav className="sb-sidenav-menu-nested nav">
                    <Link className="nav-link" to={"/purchases"}>
                      {/* <div className="sb-nav-link-icon">
                        <i className="menu_icons fas menu5 fa-list"></i>
                      </div> */}
                      Purchases
                    </Link>
                    <Link className="nav-link" to={"/purchases-return"}>
                      {/* <div className="sb-nav-link-icon">
                        <i className="menu_icons fas menu5 fa-list"></i>
                      </div> */}
                      Purchases Return
                    </Link>
                  </nav>
                  </div>

                  <a className="nav-link collapsed"
                  href="#"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSales"
                  aria-expanded="false"
                  aria-controls="collapseSales"
                  >
                  <div className="sb-nav-link-icon">
                    <i className="menu_icons menu5 fas fa-list"></i>
                  </div>
                  Sales
                  <div className="sb-sidenav-collapse-arrow">
                    <i className="fas fa-angle-down"></i>
                  </div>
                  </a>
                  <div
                  className="collapse"
                  id="collapseSales"
                  aria-labelledby="headingOne"
                  data-bs-parent="#sidenavAccordion"
                  >
                  <nav className="sb-sidenav-menu-nested nav">
                    <Link className="nav-link" to={"/sales"}>
                      {/* <div className="sb-nav-link-icon">
                        <i className="menu_icons fas menu5 fa-list"></i>
                      </div> */}
                      Sales
                    </Link>
                    <Link className="nav-link" to={"/sales-return"}>
                      {/* <div className="sb-nav-link-icon">
                        <i className="menu_icons fas menu5 fa-list"></i>
                      </div> */}
                      Sales Return
                    </Link>
                  </nav>
                  </div>
                  
                  {/* <Link className="nav-link" to={"/sale/add"}><div className="sb-nav-link-icon"><i className="menu_icons fas menu5 fa-balance-scale"></i></div>Create Bill</Link> */}
                </div>
              </div>
              <div className="sb-sidenav-footer menu5">
                <div className="small">
                  Logged in as:{" "}
                  {JSON.parse(localStorage.getItem("data")).first_name +
                    " " +
                    JSON.parse(localStorage.getItem("data")).last_name}
                </div>
              </div>
            </nav>
          </div>
        )}

        <ContentArea />
      </div>
    );
  }
}
