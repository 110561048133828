import { createStore } from 'redux';

const reducer = (loading_show = 0, action) => {
    loading_show = action.value
    return loading_show;
};
export const store = createStore(reducer);

// --------------------------------------------
const reducerA = (records = [], action) => {
    records = action.value;
    return records;
};
export const storeResult = createStore(reducerA);

// --------------------------------------------
const reducerB = (records = [], action) => {
    records = action.value;
    return records;
};
export const storeMenu = createStore(reducerB);

// --------------------------------------------
const reducerC = (records = [], action) => {
    records = action.value;
    return records;
};
export const userStore = createStore(reducerC);

// --------------------------------------------
const reducerD = (records = [], action) => {
    records = action.value;
    return records;
};
export const storeTableHead = createStore(reducerD);


