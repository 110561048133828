import React from "react";
import CommonFunctions from "../commonFunctions.js";
import { Form, Select, Button, FormGroup,Col,Row,InputGroup, FormControl, ControlLabel } from "react-bootstrap";
import { Link } from "react-router-dom";
import Breadcrumbs from "../Breadcrumbs";

class Expense extends CommonFunctions {
  constructor(props) {
    super(props)
    this.state = {
      validated: 0,
      breadcrum_params: [],
      amount :'',
      expense_date : new Date().toISOString().slice(0,10),
      expense_type :'Amount Spent',
      expense_person :'',
      description :'',
      payment_mode:[],
      payment_mode_id:'',
      users:[]
    }
    this.errors = [];
    this.handleSubmit  = this.handleSubmit.bind(this);
  }
  
  handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();    
    this.setState({ validated: this.state.validated + 1 })
    
    if(form.checkValidity() === true)
    {
      const url = "expense";
      const params = {amount: this.state.amount, expense_date : this.state.expense_date, expense_type : this.state.expense_type, expense_person : this.state.expense_person,
        description:this.state.description, payment_mode_id:this.state.payment_mode_id}
      this.apiCall(url, params, "../expenses", "POST", "Expense added")
    }
  }
  
  componentDidMount() {
    var newBreadcrum = this.state.breadcrum_params.slice();
    newBreadcrum.push([1,'../expenses', 'Expense']);
    newBreadcrum.push([0, 0, 'Add New Expense']);
    this.setState({breadcrum_params: newBreadcrum}); 
    
    this.apiCall('users/list?create_for_dropdown_list=yes', '', '', 'GET','').then(res => {

      this.setState({users: res.data})
        
  })

  this.apiCall('paymentmode?create_for_dropdown_list=yes', '', '', 'GET','').then(res => {
    this.setState({payment_mode: res.data, payment_mode_id:res.data[0].id})    
  })

  }

  render() {
    const {breadcrum_params} = this.state;
    const {payment_mode} = this.state;
    
    return (
      <div>
      <Breadcrumbs params = {breadcrum_params}/> 

    <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
      <h3>Add New Expense</h3>
      <Row className="mb-3">
        <Form.Group as={Col} md="4" controlId="id01">
          <Form.Label>Amount<span className="text-danger"> *</span></Form.Label>
          <Form.Control 
            type="number" 
            name="amount"
            placeholder="Enter amount" 
            required
            value={this.state.amount} 
            onChange={this.validateInput}
            isInvalid={this.errors.amount}
            autoFocus
            autoComplete='off'
            />
          <Form.Control.Feedback type="invalid">
            {this.errors.amount ? this.errors.amount : 'Please provide amount'}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} md="4" controlId="id02">
          <Form.Label>Expense Date (DD/MM/YYY)<span className="text-danger"> *</span></Form.Label>
          <Form.Control 
            type="date" 
            name="expense_date"
            placeholder="Enter expense date" 
            required
            value={this.state.expense_date} 
            onChange={this.validateInput}
            isInvalid={this.errors.expense_date}
            autoComplete='off'
            />
          <Form.Control.Feedback type="invalid">
            {this.errors.expense_date ? this.errors.expense_date : 'Please provide expense date'}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} md="4" controlId="id03">
          <Form.Label>Expense Type</Form.Label>
          <select className="form-select" required value={this.state.expense_type} name="expense_type" 
        
        onChange={this.validateInput}>
          <option value='Amount Received'>Amount Received</option>
          <option value='Amount Spent'>Amount Spent</option>
        </select>
          <Form.Control.Feedback type="invalid">
            {this.errors.expense_type ? this.errors.expense_type : 'Please provide expense type'}
          </Form.Control.Feedback>
        </Form.Group>

        </Row>

        <Row className="mb-3">
        <Form.Group as={Col} md="4" controlId="id04">
          <Form.Label>Expense Spent By<span className="text-danger"> *</span></Form.Label>
          <Form.Control 
            type="text" 
            name="expense_person"
            placeholder="Enter Expense Person" 
            required
            value={this.state.expense_person} 
            onChange={this.validateInput}
            list = "users_list"
            />

          <datalist id="users_list">
            {
               this.state.users.length>0 && this.state.users.map((row,i) => (
                <option key={i} value={row.first_name+' '+row.last_name}>{row.first_name+' '+row.last_name}</option>
                ))
            }
          </datalist>

          <Form.Control.Feedback type="invalid">
            {this.errors.expense_person ? this.errors.expense_person : 'Please provide expense person'}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} md="4" controlId="id05">
        <Form.Label>Payment Mode<span className="text-danger"> *</span></Form.Label>
        <select className="form-select" required  name="payment_mode_id"  value={this.state.payment_mode_id}
          onChange={this.validateInput}
          >
            {
              payment_mode.length>0 && payment_mode.map((row2,i) => (
                <option key={i} value={row2.id}>{row2.name}</option>
                ))
            }
          </select>
          <Form.Control.Feedback type="invalid">
            {this.errors.payment_mode_id ? this.errors.payment_mode_id : 'Please provide Payment mode'}
          </Form.Control.Feedback>

          </Form.Group>

        <Form.Group as={Col} md="4" controlId="id06">
          <Form.Label>Expense Details<span className="text-danger"> *</span></Form.Label>

          <Form.Control 
            type="text" 
            name="description"
            placeholder="Enter Expense details" 
            required
            value={this.state.description} 
            onChange={this.validateInput}
            isInvalid={this.errors.description}
            />
          <Form.Control.Feedback type="invalid">
            {this.errors.description ? this.errors.description : 'Please provide details'}
          </Form.Control.Feedback>
        </Form.Group>
        </Row>
<br />
      <Button type="submit">Save</Button> &nbsp;
      <Button variant="secondary" onClick={this.goBack}>Cancel</Button>
    </Form>
    </div>
  );
}
}

export default Expense;