import React from "react";
import CommonFunctions from "../commonFunctions.js";
import {
  Form,
  Button,
  FormGroup,
  Col,
  Row,
  InputGroup,
  FormControl,
  ControlLabel,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Breadcrumbs from "../Breadcrumbs";

class MainCategory extends CommonFunctions {
  constructor(props) {
    super(props);
    this.state = {
      validated: 0,
      breadcrum_params: [],
      parent_id: "0",
      mcategories: [],
      name: "",
    };
    this.errors = [];
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    this.setState({ validated: this.state.validated + 1 });

    if (form.checkValidity() === true) {
      const url = "main_categories";
      const params = { name: this.state.name, parent_id: this.state.parent_id };
      this.apiCall(
        url,
        params,
        "../main-categories",
        "POST",
        "Main Categories Name added"
      );
    }
  };

  componentDidMount() {
    var newBreadcrum = this.state.breadcrum_params.slice();
    newBreadcrum.push([1, "../main-categories", "Main Categories"]);
    newBreadcrum.push([0, 0, "Add Main Categories"]);
    this.setState({ breadcrum_params: newBreadcrum });

    this.apiCall("brands?create_for_dropdown_list=yes", "", "", "GET", "").then(
      (res) => {
        this.setState({ mcategories: res.data.mcategories1 });
      }
    );
  }

  render() {
    const { breadcrum_params } = this.state;
    return (
      <div>
        <Breadcrumbs params={breadcrum_params} />

        <Form
          noValidate
          validated={this.state.validated}
          onSubmit={this.handleSubmit}
        >
          <h3>Add Main Category</h3>
          <Row className="mb-3">
            <Form.Group as={Col} md="6" controlId="id01">
              <Form.Label>
                Main Category Name<span className="text-danger"> *</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="name"
                placeholder="Enter Main Category name"
                required
                value={this.state.name}
                onChange={this.validateInput}
                isInvalid={this.errors.name}
                autoFocus
                autoComplete="off"
              />
              <Form.Control.Feedback type="invalid">
                {this.errors.name
                  ? this.errors.name
                  : "Please provide Main Category name"}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} md="6" controlId="id02">
              <Form.Label>Parent Category</Form.Label>
              <select
                className="form-select"
                value={this.state.parent_id}
                name="parent_id"
                onChange={(e) => {
                  this.validateInput(e);
                }}
              >
                <option value="">Select Parent Category</option>
                {this.state.mcategories.length > 0 &&
                  this.state.mcategories.map((row, i) => (
                    <option key={i} value={row.id}>
                      {row.name}
                    </option>
                  ))}
              </select>
              <Form.Control.Feedback type="invalid">
                {this.errors.bname
                  ? this.errors.bname
                  : "Please select parent category"}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Button type="submit">Save</Button> &nbsp;
          <Button variant="secondary" onClick={this.goBack}>
            Cancel
          </Button>
        </Form>
      </div>
    );
  }
}

export default MainCategory;
