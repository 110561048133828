import React from "react";
import CommonFunctions from "../commonFunctions.js";
import Tablehead from "../Tablehead";
import Breadcrumbs from "../Breadcrumbs";
import Pagination from "../Pagination";
import { Link } from "react-router-dom";
import {storeResult} from '../../redux';

class Vendor extends CommonFunctions {
  constructor(props) {
    super(props)
    this.getData  = this.getData.bind(this);    
    this.state = {
      records: [],
      axios_responded:0,
      breadcrum_params: [],
      records_cnt: '',
      radioboxes: [],
      pageNum:'',
      model_url:'vendor'
  };

    storeResult.subscribe(() => {
        this.setState({
          records: storeResult.getState()[0].data.data,
          records_cnt: storeResult.getState()[0].data.total,
          radioboxes: storeResult.getState()[1],
          pageNum: storeResult.getState()[2],
          axios_responded : 1
        });
    });
  }

 componentDidMount() {

    var newBreadcrum = this.state.breadcrum_params.slice();
    // newBreadcrum.push([1,'../brands', 'Brands']);
    newBreadcrum.push([0, 0, 'Vendors']);
    this.setState({breadcrum_params: newBreadcrum});  
    let url = "vendor";
    let params = {};
    this.getData(url, params)
  }

  render() {
    const { records } = this.state;
    const {axios_responded} = this.state;
    const {breadcrum_params} = this.state;
    const {records_cnt} = this.state;
    
    return (
      <div>
      <Breadcrumbs params = {breadcrum_params}/> 
      <Tablehead header1 = "Vendors List" link="/vendor/add" model_url={this.state.model_url} />

      {axios_responded == 1 && 
        <div className='table-responsive'>
        <table className='table styled-table'>
        <thead>
        <tr><th>S.No</th><th>Vendor Name</th><th>Phone</th><th>Email ID</th><th>Address</th><th className="text-end">Out Balance</th><th>Status</th><th>Actions</th></tr>
        </thead>
        <tbody>
        
        {records && records.length == 0 && <tr><td colSpan='8' className="text-center text-danger"><div className="text-center p-2 alert-warning">No Records Found !</div></td></tr> }

        {records.length>0 && records.map((row,i) => (
        <tr key={i}>
          <td>{(this.state.pageNum+i)}</td>
          <td>{row.full_name}</td>
          <td>{row.phone}</td>
          <td>{row.email}</td>
          <td>{row.address}</td>
          <td className="text-end">{this.CurrencyFormatted(row.out_balance)}</td>
          <td>          
            <div className="form-check form-switch">
            <input className="form-check-input" type="checkbox"  
            checked={this.state.radioboxes[row.id]}
            onChange={this.changeStatus.bind(this,i,'Vendor',row.id)} />
              {/* <label class="custom-control-label">Active</label> */}
            </div>
          </td>
          <td>
          <Link to={"/vendor/"+row.slug}>
          <button type="button" className="btn btn-sm btn-info active"> <i className="fas fa-edit"></i > Edit</button>
          </Link>
          </td>
        </tr>
         ))}
        </tbody>
        
        <tfoot>
          <tr>
          <th colSpan={5} className="text-end">TOTAL : </th>
          <th className="text-end">
          {this.CurrencyFormatted(records.reduce((total, record) => total + parseFloat(record.out_balance), 0))}
          </th>
          <th colSpan={2}></th>
          </tr>
        </tfoot>

      </table>
      {records && records.length>0 && <Pagination page_cnt={records_cnt} model_url={this.state.model_url} />}
      </div>
      }
      </div>
  );
}
}

export default Vendor;