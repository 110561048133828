import React from "react";
import CommonFunctions from "../commonFunctions.js";

class Print extends CommonFunctions {
  constructor(props) {
    super(props)
    this.state = {
      records: [],
      currency:'',
      details:'',
  };
  }

 componentDidMount() {

    let url = "sales/"+this.props.match.params.invoice_no;
    let params = {};
    this.apiCall(url, '', '', 'GET','').then(res => {
        // console.log(res.data.data.length)
        this.setState({records: res.data.data, currency: res.data.currency, details: res.data.details}); 
        var content = document.getElementById("card-body").innerHTML
        var print_data = res.data.details.print_data.replace("##DATA##", content)
        document.getElementById("root").innerHTML = print_data
        window.print();
      window.close();
      // window.history.go(-1);
      window.location.href = '../sales'
      })
  }

  render() {
    const { records } = this.state; 

    return (
      <div>
        {records.length>0 && 
      <div>
        <div style={{fontSize:'13px'}}>
        <span>Bill No : <b>{this.props.match.params.invoice_no}</b></span> &nbsp;
        <span>Date : <b>{this.state.details.created_at}</b></span>
        </div>

        <div style={{fontSize:'13px', borderBottom:'1px solid #ccc'}}>
        <span>Customer Name : <b>{this.state.details.customer_name}</b></span>
        </div>

        <table className='table printable'>
        <thead>
        <tr><th>S.No</th><th>ITEM</th>
        {records.reduce((total, record) => total + parseFloat(record.price), 0) - records.reduce((total, record) => total + parseFloat(record.our_price), 0) !=0 && 
        <th className="text-end">MRP</th>
        }
        
        <th className="text-end">RATE</th><th className="text-center">QTY</th><th className="text-end">AMOUNT</th></tr>
        </thead>
        <tbody>
        {records.map((row,i) => (
        <tr key={i}>
          {/* <td>{(this.state.pageNum+i)}</td> */}
          <td>{i+1}</td>
          <td  width='45%'>{row.name}</td>
          <td className="text-end">{(row.price)}</td>
          
          {records.reduce((total, record) => total + parseFloat(record.price), 0) - records.reduce((total, record) => total + parseFloat(record.our_price), 0) != 0 && 
            <td className="text-end">{(row.our_price)}</td>
            }

          <td className="text-center">{row.quantity}</td>
          <td className="text-end">{row.total}</td>
        </tr>
        ))}

      <tr style={{fontSize:'13px', borderBottom:'1px solid #ccc'}}>
       <td><b>TOTAL</b></td><td></td>
       <td className="text-end"></td>
       {records.reduce((total, record) => total + parseFloat(record.price), 0) - records.reduce((total, record) => total + parseFloat(record.our_price), 0) != 0 && 
        <td></td>}
       {/* {records.reduce((total, record) => total + parseFloat(record.price), 0) - records.reduce((total, record) => total + parseFloat(record.our_price), 0) != 0 && 
        <td className="text-end"><b>{records.reduce((total, record) => total + parseFloat(record.our_price), 0)}</b></td>
        } */}

       <td className="text-center"><b>{records.reduce((total, record) => total + parseFloat(record.quantity), 0)}</b></td>
       <td className="text-end"><b> {records.reduce((total, record) => total + parseFloat(record.total), 0).toFixed(2)}</b></td>
       </tr>

       {this.state.details.charges_selected.length>0 && this.state.details.charges_selected.map((row1,j) => (
        <tr key={j}>
      {records.reduce((total, record) => total + parseFloat(record.price), 0) - records.reduce((total, record) => total + parseFloat(record.our_price), 0) != 0 && 
      <td></td>}
      <td colSpan={4}  className="text-end">
        {
          this.state.details.charges.length>0 && this.state.details.charges.map((row,i) => {
              
                if(row1 == row.id)
                  return (<span key={i}>{row.name}</span>)
              
              })
        }
      </td>

      <td  className="text-end"> {this.state.details.charge_amount[j].toFixed(2)}</td>
       </tr>
      ))}

      
     <tr>
     {records.reduce((total, record) => total + parseFloat(record.price), 0) - records.reduce((total, record) => total + parseFloat(record.our_price), 0) != 0 && 
      <td></td>}
     {this.state.details.discount_amount>0 && 
     <td  colSpan={4}  className="text-end"><b>Discount Amount</b></td>
     }
     {this.state.details.discount_amount>0 && 
     <td className="text-end"><b>{parseFloat(this.state.details.discount_amount).toFixed(2)}</b></td>
     }
     </tr>

     <tr>
     {records.reduce((total, record) => total + parseFloat(record.price), 0) - records.reduce((total, record) => total + parseFloat(record.our_price), 0) != 0 && 
        <td></td>
     }

       <td  colSpan={4}  className="text-end"><b>Pay</b></td><td className="text-end">
       <b> {this.state.currency}
       {/* {(records.reduce((total, record) => total + parseFloat(record.total), 0) - this.state.discount_amount).toFixed(2)} */}
       {parseFloat(this.state.details.invoice_amount - this.state.details.discount_amount).toFixed(2)}
       </b></td></tr>
        </tbody>
        </table>
            
      <div style={{fontSize:'13px', clear:'both', marginTop:'-14px', marginBottom:'10px'}}>
        <span><b>Amount (in words):</b><br />{this.state.details.amount_in_words}</span>
        <br /><span><b>Payment mode</b> {this.state.details.payment_mode} </span>
      </div>

      <div style={{fontSize:'13px', float:'right'}}>
        <span>Bill Created By : <b>{this.state.details.biller_name}</b></span>
      </div><div style={{clear:'both'}}></div>

        {
         this.state.details.total_savings>0 && 
         <div className="text-center">
           You have saved <b>{this.state.currency}{this.state.details.total_savings}</b> 
           </div> 
        }
      </div>
      }
      </div>
  );
}
}

export default Print;