import React,{Component} from "react";
// import ReactDOM from "react-dom";

import {
  BrowserRouter as Router
} from "react-router-dom";


import './App.css';
import ContentAreab4Login from './layout/before-login';
import Header from './layout/header';
import SideNavigation from './layout/sidenavigation';

import Footer from './layout/footer';

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.js';
import './css/style.css';
// import './css/sidebar.css';
import 'react-notifications/lib/notifications.css';

export default class App extends Component {
  render() {
    return (
    <div className="App" ref={el => (this.div = el)}>
      <Router>
        
        {!localStorage.getItem("cid") && 
          <ContentAreab4Login />
        }

      {localStorage.getItem("cid") && 
      <div>
        <Header />
        <SideNavigation />    
        
        <Footer />
      </div>
    }

    </Router>
    </div>
    );
  }
}
