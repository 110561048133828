import React from 'react';
import CommonFunctions from "../commonFunctions.js";
import { Form, Button, FormGroup,Col,Row,InputGroup, FormControl, ControlLabel } from "react-bootstrap";
import { Link } from "react-router-dom";
import Breadcrumbs from "../Breadcrumbs";

class Brand extends CommonFunctions {
  constructor(props) {
    super(props)
    this.state = {
      validated: 0,
      breadcrum_params: [],
      company_name :'',
      gst_no:'',
      short_code:'',
      address1:'',
      print_template:'',
      partial_sale_amount:'',
      partial_purchase_amount:'',
      customer_percentage:''
    }
    this.errors = [];
    this.handleSubmit  = this.handleSubmit.bind(this);
    this.generateShortCode = this.generateShortCode.bind(this);
    this.updateCheckBox = this.updateCheckBox.bind(this);
    }

    updateCheckBox(e){
      this.setState({[e.target.name]: e.target.checked});
      this.errors[e.target.name] = ''
   }

   generateShortCode(e)
   {
     let cname = e.target.value
     var short_code = cname.substring(0,3)
     this.setState({short_code: short_code});
   }

    handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();    
    this.setState({ validated: this.state.validated + 1 })
    
    if(form.checkValidity() === true)
    {
      const url = "company/"+this.state.company_name;
      const params = {
        name :this.state.company_name,
        gst_no:this.state.gst_no,
        short_code:this.state.short_code,
        address1:this.state.address1,
        print_data:this.state.print_template,
        partial_sale_amount:this.state.partial_sale_amount,
        partial_purchase_amount:this.state.partial_purchase_amount,
        points_percentage:this.state.customer_percentage
      }
      this.apiCall(url, params, "./configurations", "PUT", "Configurations updated")
    }
  }
  
  componentDidMount() {
    var newBreadcrum = this.state.breadcrum_params.slice();
    newBreadcrum.push([0, 0, 'Configuration']);
    this.setState({breadcrum_params: newBreadcrum});

    let url = "company/company_id";
    let params = {};
    this.apiCall(url, params, '', 'GET','').then(res => {
      // console.log(res)
        this.setState({
          company_name :res.data.name,
          gst_no:res.data.gst_no,
          short_code:res.data.short_code,
          address1:res.data.address1,
          print_template:res.data.print_data,
          partial_sale_amount: (res.data.partial_sale_amount == 1 ? true : false),
          partial_purchase_amount:res.data.partial_purchase_amount,
          customer_percentage:res.data.points_percentage
        }); 
    })    
  }
  
  render() {
    const {breadcrum_params} = this.state;
  
    return (
      <div>
      <Breadcrumbs params = {breadcrum_params}/> 

    <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
      <h3>Configure settings for your company</h3><br />

      <Row className="mb-3">
        <Form.Group as={Col} md="3" controlId="id01">
          <Form.Label>Company Name<span className="text-danger"> *</span></Form.Label>
          </Form.Group>  
        <Form.Group as={Col} md="5" controlId="id02">
          <Form.Control 
            type="text" 
            name="company_name"
            placeholder="Enter Company name" 
            required
            value={this.state.company_name} 
            onChange={this.validateInput}
            onKeyUp={this.generateShortCode}
            isInvalid={this.errors.company_name}
            autoFocus
            autoComplete='off'
            />
          <Form.Control.Feedback type="invalid">
            {this.errors.company_name ? this.errors.company_name : 'Please provide company name'}
          </Form.Control.Feedback>
        </Form.Group>
        </Row>

        <Row className="mb-3">
        <Form.Group as={Col} md="3" controlId="id03">
          <Form.Label>Company Short Code<span className="text-danger"> *</span></Form.Label>
          </Form.Group>  
        <Form.Group as={Col} md="5" controlId="id04">
          <Form.Control 
            style={{textTransform: 'uppercase'}}
            type="text" 
            name="short_code"
            placeholder="3 letter code will be used for invoice number format" 
            value={this.state.short_code} 
            onChange={this.validateInput}
            isInvalid={this.errors.short_code}
            autoFocus
            autoComplete='off'
            minLength={3}
            maxLength={3}
            />
        </Form.Group>
        </Row>

        <Row className="mb-3">
        <Form.Group as={Col} md="3" controlId="id05">
          <Form.Label>GSTIN<span className="text-danger"> *</span></Form.Label>
          </Form.Group>  
        <Form.Group as={Col} md="5" controlId="id06">
          <Form.Control 
            type="text" 
            name="gst_no"
            placeholder="Enter GST number" 
            required
            value={this.state.gst_no} 
            onChange={this.validateInput}
            isInvalid={this.errors.gst_no}
            autoFocus
            autoComplete='off'
            />
          <Form.Control.Feedback type="invalid">
            {this.errors.gst_no ? this.errors.gst_no : 'Please provide company name'}
          </Form.Control.Feedback>
        </Form.Group>
        </Row>

        <Row className="mb-3">
        <Form.Group as={Col} md="3" controlId="id07">
          <Form.Label>Address<span className="text-danger"> *</span></Form.Label>
          </Form.Group>  
        <Form.Group as={Col} md="5" controlId="id08">
        <Form.Control as="textarea" rows={3} 
            name="address1"
            placeholder="Enter Address" 
            required
            value={this.state.address1} 
            onChange={this.validateInput}
            isInvalid={this.errors.address1}
            autoFocus
            autoComplete='off'
            />
          <Form.Control.Feedback type="invalid">
            {this.errors.address1 ? this.errors.address1 : 'Please provide address'}
          </Form.Control.Feedback>
        </Form.Group>
        </Row>

        <Row className="mb-3">
        <Form.Group as={Col} md="3" controlId="id09">
          <Form.Label>Printer Template<span className="text-danger"> *</span></Form.Label>
          </Form.Group>  
        <Form.Group as={Col} md="5" controlId="id10">

        <Form.Control as="textarea" rows={3} 
            name="print_template"
            value={this.state.print_template}
            onChange={this.validateInput}
            autoComplete='off'
        />

          <Form.Control.Feedback type="invalid">
            {this.errors.print_template ? this.errors.print_template : 'Please provide print template'}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} md="4" controlId="id10a">
        <span dangerouslySetInnerHTML={{ __html: this.state.print_template}} />
      </Form.Group>

        </Row>

        <Row className="mb-3">
        <Form.Group as={Col} md="3" controlId="id11">
          <Form.Label>Accept partial amount for Purchase?<span className="text-danger"> *</span></Form.Label>
          </Form.Group>  
        <Form.Group as={Col} md="5" controlId="id12">
        <div className="form-check form-switch">
        <input className="form-check-input" type="checkbox"  name="partial_purchase_amount"
            checked={this.state.partial_purchase_amount} onChange={this.updateCheckBox} />
            </div>
        </Form.Group>
        </Row>

        <Row className="mb-3">
        <Form.Group as={Col} md="3" controlId="id13">
          <Form.Label>Accept partial amount for Sales?<span className="text-danger"> *</span></Form.Label>
          </Form.Group>  
        <Form.Group as={Col} md="5" controlId="id14">
        <div className="form-check form-switch">
        <input className="form-check-input" type="checkbox"  name="partial_sale_amount"
            checked={this.state.partial_sale_amount} 
            onChange={this.updateCheckBox} />
            </div>
        </Form.Group>
        </Row>

        <Row className="mb-3">
        <Form.Group as={Col} md="3" controlId="id15">
          <Form.Label>How much percentage of points you want to provide for your customer, when a bill created?<span className="text-danger"> *</span></Form.Label>
          </Form.Group>  
        <Form.Group as={Col} md="5" controlId="id16">
            <Form.Control 
            type="number" 
          name="customer_percentage"
            placeholder="Enter Percentage" 
            required
            value={this.state.customer_percentage} 
            onChange={this.validateInput}
            isInvalid={this.errors.customer_percentage}
            autoComplete='off'
            />
        <Form.Control.Feedback type="invalid">
            {this.errors.customer_percentage ? this.errors.customer_percentage : 'Please provide valid percentage(0 to 99)'}
          </Form.Control.Feedback>
        </Form.Group>
        </Row>

      <Button type="submit">Save</Button> &nbsp;
      <Button variant="secondary" onClick={this.goBack}>Cancel</Button>
    </Form>
    </div>
  );
}
}

export default Brand;