import React,{Component} from "react";
import RoutesPath from "../routes"
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { store } from '../redux';

export default class Container extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: '',
    };

    store.subscribe(() => {
      this.setState({
        loading: store.getState()
      });
    });
  }

  render() {
    return (

      <div id="layoutSidenav_content">
                <main>
                    <div className="container-fluid px-4">
                        <br />
                        <div className="row">
                            <div className="col-lg-12 grid-margin stretch-card">
                                <div className="card">
                                  <div className="card-body" id="card-body">
                                  <RoutesPath />
                                  </div>
                                </div>
                              </div>
                              
    { this.state.loading>0 &&
          (
            <div className="overlay">
            <div className="text-center">
              <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
              </div>
              <p>Loading data..</p>
            </div>
          </div>
      )}    
      
      <NotificationContainer/>
                        </div>
                    </div>
                    </main>
                    </div>
    );
  }
}