import React, { Component } from "react";
import CommonFunctions from "./commonFunctions.js";
import { NotificationManager } from "react-notifications";

export default class CommonAttributes extends CommonFunctions {
  constructor(props) {
    super(props);
    this.state = {
      attributes: [],
      params_list: [1],
      // params_list: [],
      file: "",
    };
    this.errors = [];
    this.addMoreRows = this.addMoreRows.bind(this);
    this.removeRows = this.removeRows.bind(this);
    this.saveCommonAttributes = this.saveCommonAttributes.bind(this);
  }

  componentDidMount() {
    let url =
      "common/fetch-attributes?master_id=" +
      this.props.master_id +
      "&master_name=" +
      this.props.master_name;
    this.apiCall(url, "", "", "GET", "").then((res) => {
      this.setState({
        attributes: res.data.attributes,
        params_list: res.data.selected_attributes,
      });
    });
    this.props.setClick(this.saveCommonAttributes);
  }

  validateInputArrayAttributes(i, type, e) {
    let new_state_value = [...this.state.params_list];
    if (type == "file") {
      let file_size = e.target.files[0].size;
      let file_type = e.target.files[0].type;

      if (file_type.indexOf("image") < 0) {
        NotificationManager.error("Uploaded is not a valid image");
        return 0;
      }
      if (file_size > 500000) {
        NotificationManager.error("Uploaded size can not be 500KB");
        return 0;
      }

      new_state_value[i].attribute_value = URL.createObjectURL(
        e.target.files[0]
      );
      new_state_value[i].attribute_file_value = e.target.files[0];
    } else if (type == "attributes_list") {
      var field_type = "";
      this.state.attributes.length > 0 &&
        this.state.attributes.map((row, k) => {
          if (row.id == e.target.value) {
            field_type = row.field_type;
          }
        });
      new_state_value[i].field_type = field_type;
      new_state_value[i].attribute_id = e.target.value;
    } else {
      new_state_value[i].attribute_value = e.target.value;
      let new_state_value2 = [...this.state[e.target.name]];
      this.setState({ [e.target.name]: new_state_value2 });
    }
    this.setState({ params_list: new_state_value });
  }

  addMoreRows() {
    var params_list = [...this.state.params_list];
    var len = this.state.params_list.length;
    var obj = {
      attribute_id: 0,
      attribute_value: "",
      class_name: "form-control",
      field_type: "text",
      id: 0,
    };
    // if (len) {
    //   var new_entry = params_list[len - 1];
    //   new_entry.attribute_value = "";
    //   params_list.push(new_entry);
    // } else {
    params_list.push(obj);
    // }
    this.setState({ params_list: params_list });
  }
  removeRows(j, e) {
    var params_list = [...this.state.params_list];
    params_list.splice(j, 1);
    this.setState({ params_list: params_list });
  }

  saveCommonAttributes(
    master_id,
    item_name,
    master_name,
    redirect_url,
    success_message
  ) {
    // console.log(this.state.params_list);
    // return 0;
    if (!localStorage.getItem("ecom_app")) {
      return 0;
    }
    let url = "common/save-attributes";
    var params = {
      params_list: this.state.params_list,
      selected_values: this.state.params_list,
      file: this.state.file,
      master_name: master_name,
      master_id: master_id,
    };

    const data = new FormData();
    let new_state_value = [...this.state.params_list];
    this.state.params_list.map((row, i) => {
      if (row.attribute_value.indexOf("blob:") >= 0) {
        data.append("images_" + i, row.attribute_file_value);
        new_state_value[i] = "file";
      }
    });

    data.append("master_name", master_name);
    data.append("master_id", master_id);
    data.append("params_list", JSON.stringify(this.state.params_list));
    data.append("item_name", item_name);

    this.apiCall(url, data, redirect_url, "POST", "", "file-upload").then(
      (res) => {
        console.log(res);
      }
    );
  }

  render() {
    return (
      <div>
        {localStorage.getItem("ecom_app") && (
          <div>
            <p>
              <b>
                Add Addional parameters for
                {this.props.master_name.toUpperCase()} to show in e-commerce
                application 
               <span style={{
                              color:"red"
                            }}> Resize or crop your product images at <a target='blank' href='https://www.resizeimagepixel.com/crop-image'>Resizeimagepixel.com</a></span></b>
            </p>
            {this.state.params_list.length > 0 &&
              this.state.params_list.map((row1, j) => (
                <div className="row mb-3" key={"L1" + j}>
                  <div className="col-3">
                    <select
                      key={"a" + j}
                      className="form-select font-13"
                      required
                      name="params_list"
                      value={row1.attribute_id}
                      onChange={this.validateInputArrayAttributes.bind(
                        this,
                        j,
                        "attributes_list"
                      )}
                    >
                      <option value="">Select Attribute Type</option>
                      {this.state.attributes.length > 0 &&
                        this.state.attributes.map((row, i) => (
                          <option
                            key={"L2" + i}
                            value={row.id}
                            field_type={row.field_type}
                          >
                            Product {row.display_name}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className="col-3">
                    {row1.field_type == "textarea" && (
                      <textarea
                        key={"L3" + j}
                        required
                        className={row1.class_name}
                        name="params_list"
                        onChange={this.validateInputArrayAttributes.bind(
                          this,
                          j,
                          ""
                        )}
                        value={row1.attribute_value}
                      />
                    )}
                    {row1.field_type != "textarea" &&
                      row1.field_type != "file" && (
                        <input
                          key={"L4" + j}
                          required
                          className={row1.class_name}
                          type={row1.field_type}
                          name="params_list"
                          onChange={this.validateInputArrayAttributes.bind(
                            this,
                            j,
                            row1.field_type
                          )}
                          value={row1.attribute_value || ""}
                        />
                      )}
                    {row1.field_type == "file" && (
                      <span>
                        <input
                          key={"L5" + j}
                          required={row1.attribute_value ? false : true}
                          className={row1.class_name}
                          type={row1.field_type}
                          name="params_list"
                          accept="image/*"
                          onChange={this.validateInputArrayAttributes.bind(
                            this,
                            j,
                            "file"
                          )}
                        />
                        {row1.attribute_value != "" && (
                          <img
                            key={"L6" + j}
                            src={
                              row1.attribute_value.indexOf("blob:") >= 0
                                ? row1.attribute_value
                                : this.API_URL +
                                  "../../storage/app/" +
                                  row1.attribute_value
                            }
                            style={{
                              height: "250px",
                              width: "350px",
                              border: "5px solid #0c8e8e",
                              borderRadius: "10px",
                            }}
                          />
                        )}
                      </span>
                    )}
                  </div>

                  <div className="col-3">
                    {j == 0 && this.state.params_list.length && (
                      <span onClick={this.addMoreRows}>
                        <i className="text-success fas fa-plus"></i>
                      </span>
                    )}
                    {j > 0 && (
                      <span onClick={this.removeRows.bind(this, j)}>
                        <i className="text-danger fas fa-trash"></i>
                      </span>
                    )}
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
    );
  }
}
