import React from "react";
import CommonFunctions from "../commonFunctions.js";
import { Form, Select, Button, FormGroup,Col,Row,InputGroup, FormControl, ControlLabel } from "react-bootstrap";
import { Link } from "react-router-dom";
import Breadcrumbs from "../Breadcrumbs";

class BarCode extends CommonFunctions {
  constructor(props) {
    super(props)
    this.state = {
      validated: 0,
      breadcrum_params: [],
      name:'',
      pid:'',
      bar_codes:[]
    }
    this.errors = [];
    this.handleSubmit  = this.handleSubmit.bind(this);
    this.addNewEntry  = this.addNewEntry.bind(this);
  }
  UpdateBarcodeText(val, e)
  {
    let new_bar_codes = [...this.state.bar_codes]
    let new_bar = new_bar_codes[val]
    new_bar = e.target.value.trim()
    new_bar_codes[val] = {bar_code:new_bar}
    this.setState({ bar_codes:new_bar_codes });
  }
  addNewEntry(){

    let new_bar_codes = [...this.state.bar_codes]
    new_bar_codes.push({bar_code: ""})
    this.setState({ bar_codes:new_bar_codes });

    this.state.bar_codes.push()
    console.log(this.state.bar_codes)
  }
  handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();    
    this.setState({ validated: this.state.validated + 1 })
    
    if(form.checkValidity() === true)
    {
      const url = "barcode";
      const params = {id: this.state.pid, barcodes : this.state.bar_codes}
      this.apiCall(url, params, "/products", "POST", "Barcodes updated for "+this.state.name)
    }
  }
  
  componentDidMount() {
    var newBreadcrum = this.state.breadcrum_params.slice();
    newBreadcrum.push([1,'../../products', 'Product']);
    newBreadcrum.push([0, 0, 'BarCodes']);
    this.setState({breadcrum_params: newBreadcrum});  
    let url = "product/"+this.props.match.params.slug;
    this.apiCall(url, '', '', 'GET','').then(res => {      
      this.setState({ name :res.data[0].name, pid :res.data[0].id}); 
      this.handleProductChange(res.data[0].id)
    })
  }

  handleProductChange(id){
    
    this.apiCall('barcode?product_id='+id, '', '', 'GET','').then(res => {
      this.setState({bar_codes: res.data}); 
    })

  }

  render() {
    const {breadcrum_params} = this.state;
    const {bar_codes} = this.state;
    
    return (
      <div>
      <Breadcrumbs params = {breadcrum_params}/> 

    <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
      <h3>BarCodes for  {this.state.name}</h3>
      <Row className="mb-3">
 
        <Form.Group as={Col} md="8">
          {
            bar_codes.length>0 && bar_codes.map((row,i) => (
              <Form.Control 
                key={i}
                type="text" 
                name={"bar_code" + i}
                placeholder="Enter Barcode" 
                required
                value={row.bar_code} 
                onChange={this.UpdateBarcodeText.bind(this, i)}
                className="w-50 m-b-5"
                required
                minLength="10"
              />
              ))
          }
          {
            bar_codes.length == 0 && <div className="link-danger">There are no Barcodes added for this product. Click Add New Button to create a new one</div>
          }
        </Form.Group>
        </Row>

            <Row className="mb-3">
            <Form.Group as={Col} md="8" controlId="id04">
            <Button type="button" variant="success" onClick={this.addNewEntry}>Add New</Button> &nbsp;
            {
            bar_codes.length>0 && 
            <Button type="submit">Save</Button>
            } &nbsp;
            <Button variant="secondary" onClick={this.goBack}>Cancel</Button>
            </Form.Group>
            </Row>

    </Form>
    </div>
  );
}
}

export default BarCode;